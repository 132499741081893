/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable n/handle-callback-err */
import BlurImage from '@/components/BlurImage'
import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { blockUser, verifyRealtor } from '../../api'
import ApproveImage from '../../assets/approve.svg'

function ConfirmVerifyModal({
  confirmVerifyModal,
  setConfirmVerifyModal,
  user,
  refetch,
}: any): JSX.Element {
  const showMessage = useMessage()

  const { mutate: mutateVerifyRealtor, isLoading: loaderVerifying } =
    useMutation({
      mutationFn: verifyRealtor,
      onSuccess: () => {
        showMessage('success', 'Риэльтор успешно подтвержден')
        setConfirmVerifyModal(false)
        refetch()
      },
      onError: () => {
        showMessage('error', 'Ошибка')
      },
    })

  return (
    <Modal
      open={confirmVerifyModal}
      onOk={() => {
        setConfirmVerifyModal(false)
      }}
      onCancel={() => {
        setConfirmVerifyModal(false)
      }}
      footer={null}
      width={500}
      height="max-content"
      closeIcon={
        <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
      }
    >
      <Flex vertical className="w-full overflow-auto">
        <Flex
          vertical
          gap={24}
          className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid p-[24px]"
        >
          <Flex vertical className="items-center relative">
            <BlurImage src={ApproveImage} preview={false} />

            <Typography.Title className="!text-2xl !font-medium m-0 ml-[16px] text-[#1E1E1E] text-center">
              Вы уверены, что хотите подтвердить эту информацию о риэлторе?
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setConfirmVerifyModal(false)
              }}
              className="absolute right-1 top-1 w-[24px] h-[24px] p-0"
            >
              <CloseCircleOutlined
                style={{
                  color: '#1E1E1E',
                  fontSize: 24,
                }}
              />
            </Button>
          </Flex>

          <Flex className="w-full items-center justify-center" gap={24}>
            <Button
              onClick={() => {
                setConfirmVerifyModal(false)
              }}
              className="w-[130px] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#A9A9A9] font-medium text-base"
            >
              Отменить
            </Button>

            <Button
              loading={loaderVerifying}
              onClick={() => {
                mutateVerifyRealtor({
                  is_verified_realtor: true,
                  user: user?.id,
                })
              }}
              className="w-[130px] bg-[#635BFF] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#FFFFFF] font-medium text-base"
            >
              Потвердить
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ConfirmVerifyModal
