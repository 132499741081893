import {
  Empty,
  Flex,
  Pagination,
  Typography
} from 'antd'
import TableRow from './TableRow'

function Table({ data, refetch }: any): JSX.Element {
  if (data?.results?.length === 0) {
    return (
      <Flex vertical className="w-full items-center justify-center p-[24px]">
        <Empty description="Нет данных" />
      </Flex>
    )
  }
  return (
    <Flex vertical className="w-full p-[0px_24px]">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Фото преимущества
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Название преимущества
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Тип преимущества
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Подробная ссылка
          </Typography.Text>

          <div />
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow key={item.id} item={item} refetch={refetch}/>
        ))}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.count}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
