import { Flex, Pagination, Typography } from 'antd'
import { format } from 'date-fns'

function Table({ setPage, data }: any): JSX.Element {
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя заявителя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Телефон номер
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Дата
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Продукт
          </Typography.Text>

          {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Апартамент
          </Typography.Text> */}

          {/* <div /> */}
        </Flex>

        {data?.results?.map((item: any) => (
          <Flex
            key={item?.id}
            className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
          >
            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.full_name}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.phone_number}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.last_call ? format(new Date(item?.last_call), 'dd.MM.yyyy') : 'Не указано'}
            </Typography.Text>

            <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
              {item?.product?.title ?? 'Нет'}
            </Typography.Text>

            {/* <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
              {item?.apartment?.title ?? 'Нет'}
            </Typography.Text> */}

            {/* <Flex>
              <Typography.Text className="text-[#1E1E1E] font-normal text-base">
                Подлинность многих нотариальных документов сейчас можно легко
                проверить по QR-коду. Его ставят на нотариальных...
                <Typography.Link className="!text-[#635BFF] font-normal text-base underline">
                  Показать больще
                </Typography.Link>
              </Typography.Text>
            </Flex> */}

            {/* <Popover
              content={
                <Flex vertical className="min-w-[170px]">
                  <Button
                    type="link"
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Изменить
                  </Button>
                  <Button
                    type="link"
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Архивировать
                  </Button>
                </Flex>
              }
              placement="bottomRight"
              trigger="click"
            >
              <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
                <MoreOutlined />
              </Button>
            </Popover> */}
          </Flex>
        ))}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={1}
            pageSize={10}
            total={data?.count}
            onChange={(page) => {
              setPage(page)
            }}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
