import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.3522 9.44104L10.1841 1.95526C10.6402 1.36981 11.4951 1.7342 11.4951 2.51406V8.30812C11.4951 8.77528 11.8303 9.15398 12.2439 9.15398H15.0804C15.7248 9.15398 16.0683 10.0122 15.6426 10.5586L9.81072 18.0444C9.35462 18.6299 8.49973 18.2655 8.49973 17.4856V11.6916C8.49973 11.2244 8.16446 10.8457 7.75089 10.8457H4.91436C4.26999 10.8457 3.92651 9.98745 4.3522 9.44104Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8307 1.67272C10.6043 1.64173 10.3634 1.72512 10.1841 1.95537L4.3522 9.44394C3.92651 9.99055 4.26999 10.8491 4.91436 10.8491H7.75089C8.16446 10.8491 8.49973 11.228 8.49973 11.6953V17.4915C8.49973 17.965 8.81457 18.2853 9.16406 18.3332"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function FlashIcon2(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
