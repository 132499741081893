import FiltersIcon from '@/components/icons/filters'
import NoteIcon from '@/components/icons/note-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import filterObject from '@/helpers/removeKeysWithoutValues'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import getListPosts from '../api'
import FilterDrawer from '../components/FilterDrawer'
import Table from '../components/Table'

function NewsPage(): JSX.Element {
  const [activeTab, setActiveTab] = useState('active')
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
  const { watch, control, reset, setValue } = useForm({
    defaultValues: {
      page: 1,
    },
  })
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['news', watch(), activeTab],
    queryFn: () =>
      getListPosts({
        filters: {
          status: activeTab === 'active' ? 'true' : 'false',
          ...filterObject(watch()),
        },
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Новости
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Новости ({data?.count ?? 0})
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                navigate('/news/create')
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить новый
            </Button>

            <Button
              onClick={() => {
                setOpenFilterDrawer(true)
              }}
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px]"
            >
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              {Object.keys(filterObject(watch())).length !== 0 && (
                <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                  {Object.keys(filterObject(watch())).length}
                </Typography.Text>
              )}
            </Button>
          </Flex>
        </Flex>

        <Flex vertical className="w-full h-full">
          <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-max mt-[26px] ml-[26px]">
            <Button
              style={{
                backgroundColor: activeTab === 'active' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setActiveTab('active')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Опубликовано
            </Button>

            <Button
              onClick={() => {
                setActiveTab('archive')
              }}
              style={{
                backgroundColor: activeTab === 'archive' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Черновики
            </Button>
          </Flex>

          <Flex className="w-full h-full p-[0px_24px]">
            {isLoading ? (
              <Skeleton active />
            ) : (
              <Table data={data} setValue={setValue} refetch={refetch}/>
            )}
          </Flex>
        </Flex>
      </Flex>

      <FilterDrawer
        openFilterDrawer={openFilterDrawer}
        setOpenFilterDrawer={setOpenFilterDrawer}
        control={control}
        reset={reset}
      />
    </Flex>
  )
}

export default NewsPage
