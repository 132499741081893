import AnalyticsDownIcon from '@/components/icons/analytics-down'
import AnalyticsUpIcon from '@/components/icons/analytics-up'
import HouseIcon from '@/components/icons/house-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import RefreshIcon from '@/components/icons/refresh-icon'
import UserIcon from '@/components/icons/user'
import formatAmount from '@/helpers/formatAmount'
import { MoreOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Flex,
  Image,
  Pagination,
  Popover,
  Typography,
} from 'antd'
import NbuLogo from '../assets/image 83.png'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'

function BanksTable(): JSX.Element {
  const statistics = [
    {
      title: 'Все банки',
      value: 2701,
      lastMonthValue: 30,
      percent: 10.7,
      icon: (
        <UserIcon
          style={{
            color: '#FA8F54',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      backgroundColor: '#FCE7DB',
    },
    {
      title: 'Количество заявок',
      value: 317,
      lastMonthValue: 30,
      percent: 10.7,
      icon: (
        <PromotionIcon
          style={{
            color: '#635BFF',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      backgroundColor: '#E0DEFF',
    },
    {
      title: 'Средний процент ипотеки',
      value: 18,
      lastMonthValue: 1000,
      percent: 3.3,
      icon: (
        <HouseIcon
          style={{
            color: '#58C27D',
            width: '24px',
            height: '24px',
          }}
        />
      ),
      backgroundColor: '#DEF3E5',
    },
  ]

  return (
    <Flex vertical className="w-full">
      <Flex className="p-[24px] grid grid-cols-3 w-full" gap={24}>
        {statistics.map((item: any, index: number) => (
          <Flex
            key={item.title}
            vertical
            className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden"
          >
            <Flex
              style={{
                backgroundColor: item.backgroundColor,
              }}
              className="w-[40px] h-[40px] items-center justify-center rounded-full"
            >
              {item.icon}
            </Flex>

            <Flex vertical className="mt-[24px] mb-[16px] overflow-hidden">
              <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
                {item.title}
              </Typography.Text>

              <Typography.Text className="text-[#1E1E1E] font-medium text-[32px]">
                {formatNumberWithSpaces(item.value)}
              </Typography.Text>
            </Flex>

            <Flex
              gap={8}
              className="no-wrap items-center statistic-row overflow-hidden"
            >
              <Typography.Text className="text-[#1E1E1E] font-medium text-sm text-nowrap">
                {formatNumberWithSpaces(item.lastMonthValue)}
              </Typography.Text>

              <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
                чем в прошлом месяце
              </Typography.Text>

              {item.value > item.lastMonthValue ? (
                <AnalyticsUpIcon />
              ) : (
                <AnalyticsDownIcon />
              )}

              <Typography.Text
                className={`text-sm font-medium text-nowrap ${
                  item.value > item.lastMonthValue
                    ? 'text-[#58C27D]'
                    : 'text-[#CE5A67]'
                }`}
              >
                {item.percent}%
              </Typography.Text>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[0.5fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Flex>
            <Checkbox />
          </Flex>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Банк
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Логин
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Пароль
          </Typography.Text>

          <div />
        </Flex>

        <Flex className="w-full items-center grid grid-cols-[0.5fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Flex>
            <Checkbox />
          </Flex>

          <Flex className="items-center" gap={12}>
            <Flex className="w-[48px] h-[48px] overflow-hidden rounded-full border-solid border-[#E5E7EB] items-start justify-center">
              <Image
                src={NbuLogo}
                alt="uzbekistan"
                width="100%"
                height="100%"
                className="w-full h-full object-cover"
              />
            </Flex>
            <Typography.Text className="text-[#635BFF] font-normal text-base">
              Национальный банк
            </Typography.Text>
          </Flex>

          <Typography.Text className="text-[#1E1E1E] font-normal text-base">
            амиран_18720
          </Typography.Text>

          <Flex className="min-w-[220px] [w-220px] items-center" gap={10}>
            <Typography.Text className="text-[#1E1E1E] font-normal text-xs">
              ********
            </Typography.Text>
            <Button className="gap-[8px] flex items-center rounded-[32px] !bg-[#F1F2F4] !text-[#1E1E1E] text-xs font-normal">
              <RefreshIcon />
              Cбросить
            </Button>
          </Flex>

          <Popover
            content={
              <Flex vertical className="min-w-[170px]">
                <Button
                  type="link"
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  Изменить
                </Button>
                <Button
                  type="link"
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  Заблокировать
                </Button>
                <Button
                  type="link"
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  Удалить
                </Button>
              </Flex>
            }
            placement="bottomRight"
            trigger="click"
          >
            <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
              <MoreOutlined />
            </Button>
          </Popover>
        </Flex>

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination defaultCurrent={6} total={100} showSizeChanger={false} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BanksTable
