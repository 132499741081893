import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="120"
    height="40"
    viewBox="0 0 120 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8354 13.9326L0 22.9213V9.88764L17.8354 0L35.6709 9.88764V22.9213L17.8354 13.9326Z"
      fill="#635BF9"
    />
    <ellipse
      cx="17.8345"
      cy="31.9102"
      rx="8.02595"
      ry="8.08989"
      fill="#9892F5"
    />
    <path
      d="M50.2482 23.9348C50.2482 24.653 50.3809 25.2797 50.6464 25.8148C50.9258 26.3359 51.3309 26.7372 51.8618 27.0189C52.4067 27.3005 53.0703 27.4414 53.8527 27.4414C54.6491 27.4414 55.3127 27.3005 55.8436 27.0189C56.3745 26.7372 56.7727 26.3359 57.0381 25.8148C57.3036 25.2797 57.4363 24.653 57.4363 23.9348V15.1685H61.7953V24.1461C61.7953 25.5966 61.46 26.864 60.7894 27.9483C60.1327 29.0186 59.2106 29.8565 58.0231 30.462C56.8356 31.0535 55.4454 31.3492 53.8527 31.3492C52.274 31.3492 50.8839 31.0535 49.6824 30.462C48.4948 29.8565 47.5657 29.0186 46.8951 27.9483C46.2385 26.864 45.9102 25.5966 45.9102 24.1461V15.1685H50.2482V23.9348Z"
      fill="#101C42"
    />
    <path
      d="M67.9341 22.0971H73.6553C74.3538 22.0971 74.8987 21.9351 75.2899 21.6112C75.6811 21.2873 75.8767 20.8367 75.8767 20.2593C75.8767 19.6679 75.6811 19.2172 75.2899 18.9074C74.8987 18.5835 74.3538 18.4216 73.6553 18.4216H67.3264L69.2753 16.3303V31.0112H64.9373V15.1685H74.263C75.4645 15.1685 76.5124 15.3868 77.4065 15.8234C78.3007 16.2458 78.9992 16.8373 79.5022 17.5978C80.0051 18.3441 80.2566 19.2313 80.2566 20.2593C80.2566 21.2592 80.0051 22.1393 79.5022 22.8998C78.9992 23.6602 78.3007 24.2517 77.4065 24.6742C76.5124 25.0966 75.4645 25.3079 74.263 25.3079H67.9341V22.0971ZM70.2813 23.5969H75.1641L80.8015 31.0112H75.7719L70.2813 23.5969Z"
      fill="#101C42"
    />
    <path
      d="M93.8074 23.2589L94.1636 22.4351C95.3791 22.4632 96.3781 22.6533 97.1604 23.0054C97.9568 23.3434 98.5506 23.8222 98.9417 24.4418C99.3329 25.0473 99.5285 25.7515 99.5285 26.5542C99.5285 27.4132 99.3329 28.1807 98.9417 28.8566C98.5506 29.5185 97.9638 30.0466 97.1814 30.4409C96.413 30.8211 95.449 31.0112 94.2894 31.0112H82.6794L83.8949 22.8787L82.6794 15.1685H93.8702C95.3232 15.1685 96.4549 15.5136 97.2652 16.2036C98.0755 16.8796 98.4807 17.8231 98.4807 19.0342C98.4807 19.696 98.327 20.3297 98.0197 20.9353C97.7123 21.5408 97.2163 22.0548 96.5317 22.4773C95.8611 22.8857 94.953 23.1462 93.8074 23.2589ZM86.9755 29.9973L85.299 27.9483H93.1158C93.7445 27.9483 94.2335 27.7934 94.5828 27.4836C94.932 27.1738 95.1067 26.7443 95.1067 26.1951C95.1067 25.6458 94.9181 25.2093 94.5409 24.8854C94.1636 24.5474 93.6048 24.3784 92.8643 24.3784H86.4097V21.3789H92.2775C92.7805 21.3789 93.1996 21.231 93.5349 20.9353C93.8842 20.6255 94.0588 20.21 94.0588 19.689C94.0588 19.2665 93.9122 18.9215 93.6188 18.6539C93.3254 18.3723 92.9062 18.2315 92.3614 18.2315H85.3199L86.9755 16.1825L88.0443 22.8787L86.9755 29.9973Z"
      fill="#101C42"
    />
    <path
      d="M110.654 31.3492C108.796 31.3492 107.161 31.0042 105.75 30.3142C104.353 29.6241 103.263 28.6595 102.481 27.4202C101.713 26.181 101.328 24.7375 101.328 23.0899C101.328 21.4423 101.713 19.9988 102.481 18.7596C103.263 17.5203 104.353 16.5557 105.75 15.8656C107.161 15.1756 108.796 14.8306 110.654 14.8306C112.526 14.8306 114.161 15.1756 115.558 15.8656C116.955 16.5557 118.045 17.5203 118.827 18.7596C119.61 19.9988 120.001 21.4423 120.001 23.0899C120.001 24.7375 119.61 26.181 118.827 27.4202C118.045 28.6595 116.955 29.6241 115.558 30.3142C114.161 31.0042 112.526 31.3492 110.654 31.3492ZM110.654 27.4414C111.674 27.4414 112.554 27.2653 113.295 26.9133C114.035 26.5612 114.601 26.0613 114.992 25.4135C115.397 24.7657 115.6 23.9912 115.6 23.0899C115.6 22.1886 115.397 21.4141 114.992 20.7663C114.601 20.1185 114.035 19.6186 113.295 19.2665C112.554 18.9145 111.674 18.7384 110.654 18.7384C109.648 18.7384 108.775 18.9145 108.034 19.2665C107.294 19.6186 106.721 20.1185 106.316 20.7663C105.911 21.4141 105.708 22.1886 105.708 23.0899C105.708 23.9912 105.911 24.7657 106.316 25.4135C106.721 26.0613 107.294 26.5612 108.034 26.9133C108.775 27.2653 109.648 27.4414 110.654 27.4414Z"
      fill="#101C42"
    />
  </svg>
)

export default function LogoLight(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
