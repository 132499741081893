import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13784_13550)">
      <path
        d="M1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1"
        stroke="#CE5A67"
        strokeLinecap="round"
      />
      <path
        d="M2.32428 2.53938C2.39345 2.46606 2.46474 2.39475 2.53805 2.32555M3.97367 1.3647C4.06442 1.3239 4.15656 1.28564 4.25 1.25M1.36586 3.97096C1.32462 4.06259 1.28597 4.15564 1.25 4.25"
        stroke="#CE5A67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.5L6 6M6 6L4.5 7.5M6 6L7.5 7.5M6 6L4.5 4.5"
        stroke="#CE5A67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13784_13550">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function NotVerifiedIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
