import NoteIcon from '@/components/icons/note-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import getListServices from '../api'
import CreateDrawer from '../components/CreateDrawer'
import TableRow from '../components/TableRow'

function ServicesPage(): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['services'],
    queryFn: async () => await getListServices(),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Услуги
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-b-[1px]  border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Услуги
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setCreateDrawer(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить услугу
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Flex className="w-full h-full p-[24px]">
            <Skeleton active />
          </Flex>
        ) : (
          data?.results?.map((item: any) => (
            <TableRow key={item?.id} item={item} refetch={refetch} />
          ))
        )}
      </Flex>

      <CreateDrawer
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
        refetch={refetch}
      />
    </Flex>
  )
}

export default ServicesPage
