import PlusAddIcon from '@/components/icons/plus-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Empty, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import getListOffers from '../api'
import FormDrawer from '../components/FormDrawer'
import Table from '../components/Table'

function SpecialOffersPage(): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)
  const { watch, setValue } = useForm({
    defaultValues: {
      page: 1,
      status: 'true',
    },
  })

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['special-offers', watch()],
    queryFn: () => getListOffers({ filters: watch() }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <PromotionIcon
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
                <Typography.Text className="text-sm font-normal text-center">
                  Реклама
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Спец предложения
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Спец предложения
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setCreateDrawer(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить специальные предложения
            </Button>

            {/* <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px]">
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                1
              </Typography.Text>
            </Button> */}
          </Flex>
        </Flex>

        <Flex className="w-full p-[24px] pt-0 border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid">
          <Flex className="w-max p-[2px] rounded-[10px] bg-[#F1F2F4] ">
            <Button
              style={{
                backgroundColor:
                  watch('status') === 'true' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setValue('status', 'true')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Активные
            </Button>

            <Button
              onClick={() => {
                setValue('status', 'false')
              }}
              style={{
                backgroundColor:
                  watch('status') === 'false' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Архивированные
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full p-[0px_24px]">
          {isFetching ? (
            <Flex className="w-full h-full">
              <Skeleton active />
            </Flex>
          ) : data?.count === 0 ? (
            <Flex className="w-full h-full justify-center items-center min-h-[300px]">
              <Empty description="Нет данных" />
            </Flex>
          ) : (
            <Table setValue={setValue} data={data} refetch={refetch} />
          )}
        </Flex>
      </Flex>

      <FormDrawer
        refetch={refetch}
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
      />
    </Flex>
  )
}

export default SpecialOffersPage
