import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7507 3.3335C10.7507 2.91928 10.4149 2.5835 10.0007 2.5835C9.58644 2.5835 9.25065 2.91928 9.25065 3.3335H10.7507ZM9.25065 16.6668C9.25065 17.081 9.58644 17.4168 10.0007 17.4168C10.4149 17.4168 10.7507 17.081 10.7507 16.6668H9.25065ZM16.6673 10.7502C17.0815 10.7502 17.4173 10.4144 17.4173 10.0002C17.4173 9.58595 17.0815 9.25016 16.6673 9.25016V10.7502ZM3.33398 9.25016C2.91977 9.25016 2.58398 9.58595 2.58398 10.0002C2.58398 10.4144 2.91977 10.7502 3.33398 10.7502V9.25016ZM9.25065 3.3335V16.6668H10.7507V3.3335H9.25065ZM16.6673 9.25016H3.33398V10.7502H16.6673V9.25016Z"
      fill="currentColor"
    />
  </svg>
)

export default function PlusAddIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
