import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66699 10.0001C1.66699 6.66097 1.66699 4.99141 2.6433 3.95408C3.61961 2.91675 5.19096 2.91675 8.33366 2.91675H11.667C14.8097 2.91675 16.381 2.91675 17.3573 3.95408C18.3337 4.99141 18.3337 6.66097 18.3337 10.0001C18.3337 13.3392 18.3337 15.0088 17.3573 16.0461C16.381 17.0834 14.8097 17.0834 11.667 17.0834H8.33366C5.19096 17.0834 3.61961 17.0834 2.6433 16.0461C1.66699 15.0088 1.66699 13.3392 1.66699 10.0001Z"
      stroke="currentColor"
    />
    <path
      d="M5.74009 9.02615C6.52223 8.56923 7.20489 8.75336 7.61498 9.04667C7.78313 9.16693 7.8672 9.22706 7.91667 9.22706C7.96613 9.22706 8.05021 9.16693 8.21835 9.04667C8.62844 8.75336 9.3111 8.56923 10.0932 9.02615C11.1197 9.62582 11.352 11.6041 8.9843 13.2732C8.53333 13.5911 8.30785 13.75 7.91667 13.75C7.52549 13.75 7.3 13.5911 6.84903 13.2732C4.48134 11.6041 4.7136 9.62582 5.74009 9.02615Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M15 13.75H12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function LoyaltyCardIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
