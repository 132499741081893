import requestV3 from '@/utils/axiosV3'

export default async function getListBanners({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/banner',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getListBannersByType({ type }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/base/banner-type',
    method: 'get',
    params: { type },
  })

  return res
}

export async function createBanner({ data }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/banner',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateBanner({ data }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/banner/${data?.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function deleteBanner({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/banner/${id}`,
    method: 'delete',
  })

  return res
}

export async function getBanner({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/banner/${id}`,
    method: 'get',
  })

  return res
}
