import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3337 9.99992C18.3337 5.39754 14.6027 1.66658 10.0003 1.66658C5.39795 1.66658 1.66699 5.39754 1.66699 9.99992C1.66699 14.6023 5.39795 18.3333 10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99992Z"
      stroke="currentColor"
    />
    <path
      d="M10.2025 14.1666V9.99996C10.2025 9.60712 10.2025 9.4107 10.0804 9.28866C9.9584 9.16663 9.76198 9.16663 9.36914 9.16663"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99398 6.66663H10.0015"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function InfoIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
