import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import CloseIcon from '@/components/icons/close'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Flex, Modal, Spin, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { createFaq, getFaq, updateFaq } from '../api'

function CreateModal({
  createModal,
  setCreateModal,
  refetch,
  id = null,
}: any): JSX.Element {
  const { control, handleSubmit, reset, getValues } = useForm()
  const [activeTab, setActiveTab] = useState('ru')

  const { mutate: createFaqMutate, isLoading: createLoading } = useMutation(
    createFaq,
    {
      onSuccess: () => {
        reset({})
        refetch()
      },
    },
  )

  const { mutate: updateFaqMutate, isLoading: updateLoading } = useMutation(
    updateFaq,
    {
      onSuccess: () => {
        reset({})
        refetch()
      },
    },
  )

  const { isFetching } = useQuery({
    queryKey: ['faq', id],
    cacheTime: 0,
    queryFn: async () => {
      return await getFaq(id)
    },
    enabled: !!id && createModal,
    onSuccess: (data) => {
      reset({
        ...data,
        question_ru: data?.translations?.ru?.question,
        answer_ru: data?.translations?.ru?.answer,
        question_uz: data?.translations?.uz?.question,
        answer_uz: data?.translations?.uz?.answer,
      })
    },
  })

  const onSubmit = (): void => {
    if (id) {
      updateFaqMutate(getValues(), id)
    } else {
      createFaqMutate(getValues())
    }
  }

  return (
    <Modal
      open={createModal}
      onOk={() => {
        setCreateModal(false)
      }}
      onCancel={() => {
        setCreateModal(false)
      }}
      footer={null}
      width={700}
      height="max-content"
      closeIcon={
        <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
      }
    >
      <Flex vertical className="w-full overflow-auto">
        <Flex className="border-x-0 border-t-0 border-b-[1px] border-solid border-[#E5E7EB] w-full p-[24px]">
          <Typography.Title className="!font-medium !text-2xl !m-0">
            {id ? 'Редактировать' : 'Добавить'} вопрос
          </Typography.Title>
        </Flex>

        <Flex className="w-full p-[24px] relative">
          {isFetching && (
            <Flex className="absolute left-0 top-0 w-full h-[500px] bg-white items-center justify-center z-10">
              <Spin size="large" />
            </Flex>
          )}
          <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-full">
            <Button
              style={{
                backgroundColor: activeTab === 'ru' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setActiveTab('ru')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
            >
              RU
            </Button>

            <Button
              onClick={() => {
                setActiveTab('uz')
              }}
              style={{
                backgroundColor: activeTab === 'uz' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
            >
              UZ
            </Button>
          </Flex>
        </Flex>

        <Flex vertical className="w-full p-[24px]" gap={24}>
          <FRow label="Вопрос">
            <HFTextarea
              control={control}
              key={`question-${activeTab}`}
              name={`question_${activeTab}`}
              placeholder="Напишите вопрос"
              rows={6}
            />
          </FRow>

          <FRow label="Ответ">
            <HFTextarea
              control={control}
              key={`answer-${activeTab}`}
              name={`answer_${activeTab}`}
              placeholder="Напишите ответ"
              rows={6}
            />
          </FRow>

          <Flex className="w-full items-center" gap={24}>
            <Button
              className="w-full h-max py-[14px] px-[24px] rounded-[10px] border-[1px] border-solid border-[#E5E7EB] text-[#1E1E1E] font-medium text-base"
              onClick={() => {
                setCreateModal(false)
                reset({})
              }}
            >
              Отменить
            </Button>

            <Button
              loading={createLoading || updateLoading}
              onClick={() => {
                setCreateModal(false)
                handleSubmit(onSubmit)()
              }}
              className="w-full h-max py-[14px] px-[24px] rounded-[10px] border border-solid border-[#E5E7EB] text-[#FFF] bg-[#635BFF] font-medium text-base"
            >
              {id ? 'Сохранить' : 'Добавить'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CreateModal
