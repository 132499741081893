import { Empty, Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, setValue, refetch }: any): JSX.Element {
  if (data?.count === 0) {
    return (
      <Flex className="w-full items-center justify-center p-[24px]">
        <Empty description="Нет данных" />
      </Flex>
    )
  }
  return (
    <Flex vertical className="w-full pb-[24px]">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Фото статьи
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Название
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Тип статьи
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Дата публикации
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Автор публикации
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Оценки
          </Typography.Text>

          <div />
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow item={item} key={item?.id} refetch={refetch} />
        ))}

        <Flex className="mt-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.count}
            showSizeChanger={false}
            onChange={(page) => {
              setValue('page', page)
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
