import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Empty,
  Flex,
  Modal,
  Skeleton,
  Spin,
  Typography,
} from 'antd'
import { useForm } from 'react-hook-form'
import {
  createGlobalRole,
  getGlobalRole,
  getListPermissionCategories,
  updateGlobalRole,
} from '../api'
import CollapseItem from './CollapseItem'

function CreateRoleModal({
  createModal,
  setCreateModal,
  refetch,
  id = 'new',
}: any): JSX.Element {
  const { control, setValue, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      permissions: [],
    },
  })
  const showMessage = useMessage()

  const { data: permissionCategories, isLoading } = useQuery({
    queryKey: ['permission-categories'],
    queryFn: async () => await getListPermissionCategories(),
    placeholderData: [],
  })

  const { isFetching: loaderGetById } = useQuery({
    queryKey: ['global-role', { id }],
    queryFn: async () => await getGlobalRole({ id }),
    cacheTime: 0,
    enabled: id !== 'new' && createModal,
    onSuccess: (data) => {
      reset({
        id: data.id,
        name_uz: data?.translations?.uz?.name,
        name_ru: data?.translations?.ru?.name,
        permissions: data.permission_list.map((p: any) => p.id),
      })
    },
  })

  const { mutate: mutateCreateRole, isLoading: isLoadingCreate } = useMutation({
    mutationFn: createGlobalRole,
    onSuccess: () => {
      setCreateModal(false)
      reset()
      showMessage('success', 'Роль успешно создана')
      refetch()
    },
  })

  const { mutate: mutateUpdateRole, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateGlobalRole,
    onSuccess: () => {
      setCreateModal(false)
      reset()
      showMessage('success', 'Роль успешно обновлена')
      refetch()
      queryClient.invalidateQueries(['global-role', { id }])
    },
  })

  const onSubmit = () => {
    if (watch('permissions').length === 0) {
      showMessage('error', 'Выберите хотя бы одно право')
      return
    }
    if (id === 'new') {
      mutateCreateRole(watch())
    } else {
      mutateUpdateRole(watch())
    }
  }

  return (
    <Modal
      open={createModal}
      onOk={() => {
        setCreateModal(false)
      }}
      onCancel={() => {
        setCreateModal(false)
      }}
      footer={null}
      width={700}
      height="max-content"
      closeIcon={
        <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
      }
    >
      <Flex vertical className="w-full overflow-hidden relative">
        {loaderGetById && (
          <Flex className="w-full h-full items-center justify-center absolute left-0 top-0 bg-white z-10">
            <Spin size="large" />
          </Flex>
        )}
        <Flex className="border-x-0 border-t-0 border-b-[1px] border-solid border-[#E5E7EB] w-full p-[24px]">
          <Typography.Title className="!font-medium !text-2xl !m-0">
            {id === 'new' ? 'Добавить новый роль' : 'Изменить роль'}
          </Typography.Title>
        </Flex>

        <Flex
          gap={12}
          className="border-x-0 border-t-0 border-b-[1px] border-solid border-[#E5E7EB] w-full p-[24px]"
        >
          <FRow label="Роль (UZ)">
            <HFInput
              control={control}
              required
              name="name_uz"
              placeholder="Например: Менеджер"
            />
          </FRow>
          <FRow label="Роль (RU)">
            <HFInput
              control={control}
              required
              name="name_ru"
              placeholder="Например: Менеджер"
            />
          </FRow>
        </Flex>

        <Flex className="w-full h-[400px] min-h-[400px]">
          <Flex className="grid grid-cols-2 w-full p-[16px_24px]" gap={24}>
            <Flex
              vertical
              className="w-full h-full min-h-full items-start"
              gap={24}
            >
              <Flex className="items-center w-full justify-between">
                <Typography.Text className="text-[#1E1E1E] font-medium text-xl">
                  Вид доступа
                </Typography.Text>

                <Flex className="items-center">
                  <Checkbox
                    checked={
                      watch('permissions').length ===
                      permissionCategories.reduce(
                        (acc, curr) => acc.concat(curr.permissions),
                        [],
                      ).length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue(
                          'permissions',
                          permissionCategories
                            .reduce(
                              (acc, curr) => acc.concat(curr.permissions),
                              [],
                            )
                            .map((p: any) => p.id),
                        )
                      } else {
                        setValue('permissions', [])
                      }
                    }}
                  >
                    Полный доступ
                  </Checkbox>
                </Flex>
              </Flex>

              <Flex
                vertical
                gap={12}
                className="border border-[#E5E7EB] border-solid rounded-[12px] p-[16px] max-h-full h-full w-full overflow-auto"
              >
                {isLoading ? (
                  <Flex className="w-full items-center justify-center">
                    <Skeleton active />
                  </Flex>
                ) : (
                  permissionCategories?.map((item: any) => (
                    <CollapseItem
                      key={item?.id}
                      item={item}
                      setValue={setValue}
                      watch={watch}
                    />
                  ))
                )}
              </Flex>
            </Flex>

            <Flex
              vertical
              className="w-full h-full min-h-full items-start"
              gap={24}
            >
              <Flex className="items-center w-full justify-between">
                <Typography.Text className="text-[#1E1E1E] font-medium text-xl">
                  Выбрано: {watch('permissions').length}
                </Typography.Text>
              </Flex>

              <Flex className="max-h-full h-full w-full overflow-auto">
                {watch('permissions')?.length > 0 ? (
                  <Flex
                    className="flex-wrap items-start justify-start h-max"
                    gap={8}
                  >
                    {watch('permissions').map((id: string) => {
                      const permission = permissionCategories
                        .reduce((acc, curr) => acc.concat(curr.permissions), [])
                        .find((p: any) => p.id === id)

                      return (
                        <Flex
                          key={permission?.id}
                          className="p-[8px] rounded-[8px] bg-[#F1F2F4] gap-1 items-center h-max w-max"
                        >
                          <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                            {permission?.category_data?.name}:
                          </Typography.Text>
                          <Typography.Text className="text-[#1E1E1E] font-normal text-sm">
                            {permission?.code}
                          </Typography.Text>
                        </Flex>
                      )
                    })}
                  </Flex>
                ) : (
                  <Flex className="w-full items-center justify-center">
                    <Empty description="Нет выбранных прав" />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex className="w-full justify-end p-[24px] gap-4">
          <Button
            onClick={() => {
              setCreateModal(false)
              reset()
            }}
            className="border-none bg-[#F1F2F4] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#1E1E1E] !font-medium !text-base hover:!bg-[#F1F2F4]"
          >
            Отмена
          </Button>
          <Button
            loading={isLoadingCreate}
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {id === 'new' ? 'Добавить' : 'Сохранить'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CreateRoleModal
