import Icon from "@ant-design/icons/lib/components/Icon";

import type { ReactElement } from "react";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const defaultIcon = (): React.ReactElement => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="elements">
      <path
        id="Vector"
        d="M12.8346 1.1665L1.16797 12.8332M1.16797 1.1665L12.8346 12.8332"
        stroke="#777E90"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function CloseIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />;
}
