import requestV3 from '@/utils/axiosV3'

export default async function getMe(): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/get-me',
    method: 'get',
  })

  return res
}

export async function updateMe(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/user-update-profile',
    method: 'post',
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}