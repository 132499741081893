/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import BlockUserIcon from '@/components/icons/block-user'
import ReasonBlockIcon from '@/components/icons/reason-block'
import UnBlockUserIcon from '@/components/icons/unblock-user'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import { unBlockUser } from '../../api'
import BlockModal from './BlockModal'
import ReasonModal from './ReasonModal'

function TableRow({ user, refetch }: any): JSX.Element {
  const [reasonModal, setReasonModal] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateUnBlockUser, isLoading: loaderUnBlocking } =
    useMutation({
      mutationFn: unBlockUser,
      onSuccess: () => {
        showMessage('success', 'Пользователь успешно разблокирован')
        refetch()
      },
      onError: () => {
        showMessage('error', 'Ошибка')
      },
    })

  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {user?.first_name} {user?.last_name}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {user?.date_joined && format(new Date(user?.date_joined), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {user?.last_login && format(new Date(user?.last_login), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {user?.adv_count}
      </Typography.Text>

      <Typography.Text
        style={{
          backgroundColor: user?.is_block ? '#FFD6D6' : '#DEF3E5',
          color: user?.is_block ? '#CE5A67' : '#58C27D',
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
      >
        • {user?.is_block ? 'Заблокирован' : 'Активен'}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {user?.phone}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            {user?.is_block ? (
              <>
                <Button
                  loading={loaderUnBlocking}
                  type="link"
                  onClick={() => {
                    mutateUnBlockUser({
                      user: user?.id,
                    })
                    setIsPopoverVisible(false)
                  }}
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  <UnBlockUserIcon />
                  Разблокировать
                </Button>

                <Button
                  type="link"
                  onClick={() => {
                    setReasonModal(true)
                    setIsPopoverVisible(false)
                  }}
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  <ReasonBlockIcon />
                  Причина блокировки
                </Button>
              </>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setBlockModal(true)
                  setIsPopoverVisible(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                <BlockUserIcon />
                Заблокировать
              </Button>
            )}
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <ReasonModal
        reasonModal={reasonModal}
        setReasonModal={setReasonModal}
        user={user}
      />
      <BlockModal
        blockModal={blockModal}
        setBlockModal={setBlockModal}
        user={user}
        refetch={refetch}
      />
    </Flex>
  )
}

export default TableRow
