import PlusAddIcon from '@/components/icons/plus-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import getListBanners from '../api'
import CreateDrawer from '../components/CreateDrawer'
import Table from '../components/Table'

function BannersPage(): JSX.Element {
  const [drawerCreate, setDrawerCreate] = useState(false)
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['banners'],
    queryFn: () => getListBanners({ filters: { page } }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <PromotionIcon
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
                <Typography.Text className="text-sm font-normal text-center">
                  Реклама
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Баннеры
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Баннеры
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setDrawerCreate(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить новый
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full p-[0px_24px]">
          {isLoading ? (
            <Flex className="w-full">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} setPage={setPage} refetch={refetch} />
          )}
        </Flex>
      </Flex>

      <CreateDrawer
        drawerCreate={drawerCreate}
        setDrawerCreate={setDrawerCreate}
        refetch={refetch}
      />
    </Flex>
  )
}

export default BannersPage
