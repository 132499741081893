import PlusAddIcon from '@/components/icons/plus-icon'
import SeoIcon from '@/components/icons/seo-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import getListSeoSingleTemplates from '../api'
import CreateDrawer from '../components/CreateDrawer'
import Table from '../components/Table'

function SeoTemplatesPage(): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)
  const [activeTab, setActiveTab] = useState('ru')
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['list-seo-single', page, activeTab],
    queryFn: () =>
      getListSeoSingleTemplates({
        filters: {
          page,
        },
        lang: activeTab,
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <SeoIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  SEO
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Шаблон для отдельной страницы
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Шаблон для отдельной страницы
          </Typography.Title>

          <Flex gap={16}>
            <Flex className="w-full">
              <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-full">
                <Button
                  style={{
                    backgroundColor: activeTab === 'ru' ? '#FFF' : '#F1F2F4',
                  }}
                  onClick={() => {
                    setActiveTab('ru')
                  }}
                  className="p-[10px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                >
                  RU
                </Button>

                <Button
                  onClick={() => {
                    setActiveTab('uz')
                  }}
                  style={{
                    backgroundColor: activeTab === 'uz' ? '#FFF' : '#F1F2F4',
                  }}
                  className="p-[10px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                >
                  UZ
                </Button>
              </Flex>
            </Flex>

            <Button
              onClick={() => {
                setCreateDrawer(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить новый
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full p-[0px_24px]">
          {isLoading ? (
            <Flex className="w-full">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} refetch={refetch} setPage={setPage} />
          )}
        </Flex>
      </Flex>

      <CreateDrawer
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
        refetch={refetch}
      />
    </Flex>
  )
}

export default SeoTemplatesPage
