import { useAuthContext } from '@/contexts/auth-context'
import { Navigate } from 'react-router-dom'

function AuthRoute({
  keyId = '',
  element: Component,
  ...rest
}: any): JSX.Element {
  const { isAuth } = useAuthContext()
  const permissions = localStorage
    ?.getItem('permissions')
    ?.split(',')
    ?.map((item) => item.trim())

  const isSuperUser = localStorage.getItem('is_superuser')

  if (!isAuth) {
    return <Navigate to="/login" />
  }

  if (permissions?.includes(keyId) || isSuperUser) {
    return <Component {...rest} />
  }

  return <Navigate to="/no-permission" />
}

export default AuthRoute
