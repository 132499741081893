import BlurImage from '@/components/BlurImage'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import UrboIcon from '@/components/icons/urbo-icon'
import useMessage from '@/hooks/useShowMessage'
import CKEditorPage from '@/views/CKEditorPage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Image, Skeleton, Typography } from 'antd'
import { useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { createVacansy, getVacansy, updateVacansyWithPut } from '../api'
import GalleryImage from '../assets/Image.svg'
import queryClient from '@/utils/query-client'

function VacansyForm(): JSX.Element {
  const { control, watch, setValue, reset } = useForm()
  const navigate = useNavigate()
  const { id } = useParams()
  const showMessage = useMessage()

  const { isLoading } = useQuery({
    queryKey: ['vacansy', id],
    queryFn: () => getVacansy({ id }),
    cacheTime: 0,
    enabled: id !== 'new',
    onSuccess: (data) => {
      reset({
        ...data,
        title_uz: data?.translations?.uz?.title,
        title_ru: data?.translations?.ru?.title,
        requirements_uz: data?.translations?.uz?.requirements,
        requirements_ru: data?.translations?.ru?.requirements,
        responsibilities_uz: data?.translations?.uz?.responsibilities,
        responsibilities_ru: data?.translations?.ru?.responsibilities,
        conditions_uz: data?.translations?.uz?.conditions,
        conditions_ru: data?.translations?.ru?.conditions,
      })
    },
  })

  const { mutate: mutateCreate, isLoading: loaderCreate } = useMutation({
    mutationFn: createVacansy,
    onSuccess: async () => {
      await navigate('/vacansies')
      queryClient.invalidateQueries('vacansies')
    },
  })

  const { mutate: mutateUpdate, isLoading: loaderUpdate } = useMutation({
    mutationFn: updateVacansyWithPut,
    onSuccess: async () => {
      await navigate('/vacansies')
      queryClient.invalidateQueries('vacansies')
    },
  })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const onSubmit = async (): Promise<any> => {
    if (!watchingImage) {
      showMessage('error', 'Добавьте изображение')
      return
    }
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    if (id === 'new') {
      mutateCreate({
        ...watch(),
        image: imageFile,
      })
    } else {
      mutateUpdate({
        ...watch(),
        image: imageFile,
      })
    }
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('image', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <UrboIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Urbo
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Ваканция
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex className="w-full justify-center">
        <Flex
          vertical
          className="border-[1px] border-[#E5E7EB] border-solid rounded-[16px] bg-[#FFF] w-[70%] relative"
        >
          {isLoading && id !== 'new' && (
            <Flex className="absolute w-full h-full bg-slate-200 z-50">
              <Skeleton active />
            </Flex>
          )}

          <input type="file" hidden />

          <Flex className="border-[#E5E7EB] border-x-0 border-t-0 border-[1px] border-solid p-[24px]">
            <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 flex gap-1">
              {id === 'new' ? 'Создать ваканцию' : 'Редактировать ваканцию'}
            </Typography.Title>
          </Flex>

          <Flex vertical className="p-[24px]" gap={16}>
            <FRow label="Добавить изображение">
              <Flex className="border-[#E5E7EB] border-[1px] border-dashed rounded-[12px]">
                {imageUrlGenerator ? (
                  <Flex className="items-center justify-center w-full h-full relative">
                    <BlurImage
                      src={imageUrlGenerator}
                      preview={false}
                      className="object-cover h-full w-full"
                    />

                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('image', null)
                      }}
                      className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
                    >
                      <CloseOutlined />
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    onClick={inputClick}
                    className="items-center justify-center min-h-[200px] w-full"
                  >
                    <Image width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px]">
                      Выберите или перетащите минимум 1 фото
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                      Максимальный размер: 5 МБ.
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            <FRow label="Заголовок UZ" required>
              <HFInput
                placeholder="Введите заголовок"
                control={control}
                name="title_uz"
              />
            </FRow>

            <FRow label="Заголовок RU" required>
              <HFInput
                placeholder="Введите заголовок"
                control={control}
                name="title_ru"
              />
            </FRow>

            <FRow label="Требования UZ">
              <CKEditorPage
                value={watch('requirements_uz')}
                setValue={(e) => setValue('requirements_uz', e)}
              />
            </FRow>

            <FRow label="Требования RU">
              <CKEditorPage
                value={watch('requirements_ru')}
                setValue={(e) => setValue('requirements_ru', e)}
              />
            </FRow>

            <FRow label="Обязанности UZ">
              <CKEditorPage
                value={watch('responsibilities_uz')}
                setValue={(e) => setValue('responsibilities_uz', e)}
              />
            </FRow>

            <FRow label="Обязанности RU">
              <CKEditorPage
                value={watch('responsibilities_ru')}
                setValue={(e) => setValue('responsibilities_ru', e)}
              />
            </FRow>

            <FRow label="Условия UZ">
              <CKEditorPage
                value={watch('conditions_uz')}
                setValue={(e) => setValue('conditions_uz', e)}
              />
            </FRow>

            <FRow label="Условия RU">
              <CKEditorPage
                value={watch('conditions_ru')}
                setValue={(e) => setValue('conditions_ru', e)}
              />
            </FRow>

            <Flex gap={8} className="justify-end">
              {/* <Button className="p-[14px_24px] border-[1px] border-solid border-[#635BFF] text-[#635BFF] rounded-[8px] h-auto text-base font-medium">
                Сохранить как черновик
              </Button> */}

              <Button
                onClick={onSubmit}
                loading={loaderCreate || loaderUpdate}
                className="p-[14px_24px] border-[1px] border-solid border-[#635BFF] bg-[#635BFF] text-[#FFF] rounded-[8px] h-auto text-base font-medium"
              >
                {id === 'new' ? 'Опубликовать' : 'Сохранить'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default VacansyForm
