import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteSeoGeneralTemplate } from '../api'
import CreateDrawer from './CreateDrawer'

export default function TableRow({ refetch, item }: any): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDelete, isLoading: loaderDelete } = useMutation({
    mutationFn: deleteSeoGeneralTemplate,
    onSuccess: () => {
      refetch()
      setIsPopoverVisible(false)
      showMessage('success', 'Шаблон успешно удален')
    },
  })

  return (
    <Flex
      gap={10}
      className="w-full items-center flex-nowrap border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.name}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.region}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.district}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.chapter}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.sub_chapter}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.title}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.description}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.keywords}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.og_title}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.og_description}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.og_url}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.og_type}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base w-[350px] min-w-[350px]">
        {item?.og_site_name}
      </Typography.Text>

      <Flex className="w-[350px] min-w-[350px]">
        <Flex className="w-[72px] min-w-[72px] h-[72px]">
          <Image src={item?.og_image} />
        </Flex>
      </Flex>

      <Flex className="w-[100px] min-w-[100px]">
        <Popover
          content={
            <Flex vertical className="min-w-[170px]">
              <Button
                type="link"
                onClick={() => {
                  setCreateDrawer(true)
                  setIsPopoverVisible(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Изменить
              </Button>
              <Button
                type="link"
                loading={loaderDelete}
                onClick={() => {
                  mutateDelete(item?.id)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Удалить
              </Button>
            </Flex>
          }
          open={isPopoverVisible}
          onVisibleChange={(visible) => {
            setIsPopoverVisible(visible)
          }}
          placement="bottomRight"
          trigger="click"
        >
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
            <MoreOutlined />
          </Button>
        </Popover>
      </Flex>

      <CreateDrawer
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
        refetch={refetch}
        item={item?.id}
      />
    </Flex>
  )
}
