import LayoutStructure from '@/components/LayoutStructure'
import type { CustomRoute } from '@/types'
import { Flex } from 'antd'

interface Props {
  children: React.ReactElement
  sidebarRoutes: CustomRoute[]
}

export default function DefaultLayout(props: Props): React.ReactElement {
  const { children, sidebarRoutes } = props

  return (
    <Flex className='h-[100vh] w-[100wh] overflow-hidden'>
      <LayoutStructure>{children}</LayoutStructure>
    </Flex>
  )
}
