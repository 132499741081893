import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { postAuthorsList, postCategoriesList } from '../api'

export default function FilterDrawer({
  openFilterDrawer,
  setOpenFilterDrawer,
  control,
  reset,
}: any): JSX.Element {
  const { data: categories, isLoading: loaderCategory } = useQuery({
    queryKey: ['categories'],
    queryFn: postCategoriesList,
    enabled: openFilterDrawer,
  })

  const { data: authorsData, isLoading: loaderAuthors } = useQuery({
    queryKey: ['authors'],
    queryFn: postAuthorsList,
    enabled: openFilterDrawer,
  })

  return (
    <Drawer
      onClose={() => {
        setOpenFilterDrawer(false)
      }}
      open={openFilterDrawer}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Фильтр
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setOpenFilterDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Тип публикации">
              <HFSelect
                control={control}
                name="category"
                loading={loaderCategory}
                allowClear
                options={categories?.results?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Выберите автор статьи">
              <HFSelect
                control={control}
                loading={loaderAuthors}
                allowClear
                name="author"
                options={authorsData?.map((item: any) => ({
                  label: item.first_name + ' ' + item.last_name,
                  value: item.id,
                }))}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setOpenFilterDrawer(false)
              reset({})
            }}
          >
            Сбросить
          </Button>

          <Button
            onClick={() => {
              setOpenFilterDrawer(false)
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
