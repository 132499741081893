import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width=""
    height=""
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.95083 1.9407L5.51568 4.06969C5.17434 4.23354 4.80962 4.27459 4.43783 4.1913C4.19451 4.13679 4.07284 4.10954 3.97487 4.09835C2.75829 3.95942 2 4.92229 2 6.02952V6.63717C2 7.74439 2.75829 8.70727 3.97487 8.56834C4.07284 8.55715 4.19452 8.52989 4.43783 8.47539C4.80962 8.39211 5.17434 8.43314 5.51568 8.597L9.95083 10.726C10.969 11.2147 11.478 11.4591 12.0456 11.2686C12.6132 11.0781 12.808 10.6694 13.1976 9.85204C14.2675 7.60745 14.2675 5.05924 13.1976 2.81465C12.808 1.99725 12.6132 1.58855 12.0456 1.39808C11.478 1.20761 10.969 1.45197 9.95083 1.9407Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.63869 13.8473L6.64448 14.6667C4.40342 12.8893 4.67721 12.0417 4.67721 8.66669H5.43309C5.73985 10.574 6.4634 11.4774 7.46182 12.1314C8.07678 12.5342 8.20362 13.3817 7.63869 13.8473Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8.33334L5 4.33334"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function PromotionIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
