import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import CreateModal from './CreateModal'
import { deleteFaq } from '../api'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [createModal, setCreateModal] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)

  const { mutate: deleteFaqMutate, isLoading } = useMutation(deleteFaq, {
    onSuccess: () => {
      refetch()
    },
  })

  return (
    <>
      <Flex className="w-full h-full p-[24px]" key={item?.id}>
        <Flex
          gap={16}
          vertical
          className="w-full rounded-[12px] border-[1px] border-[#EEEEEE] border-solid p-[24px]"
        >
          <Flex className="items-center justify-between w-full">
            <Typography.Text className="text-[#1E1E1E] text-xl font-medium">
              Вопрос:
            </Typography.Text>

            <Popover
              content={
                <Flex vertical className="min-w-[170px]">
                  <Button
                    type="link"
                    loading={isLoading}
                    onClick={() => {
                      setOpenPopover(false)
                      setCreateModal(true)
                    }}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Редактировать
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      deleteFaqMutate(item?.id)
                      setOpenPopover(false)
                    }}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Удалить
                  </Button>
                </Flex>
              }
              open={openPopover}
              placement="bottomRight"
              trigger="click"
            >
              <Button
                onClick={() => {
                  setOpenPopover(!openPopover)
                }}
                className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center"
              >
                <MoreOutlined />
              </Button>
            </Popover>
          </Flex>

          <Flex>
            <Typography.Text className="text-[#1E1E1E] text-lg font-normal">
              {item?.question}
            </Typography.Text>
          </Flex>

          <Flex>
            <Typography.Text className="text-[#1E1E1E] text-xl font-medium">
              Ответ:
            </Typography.Text>
          </Flex>

          <Flex>
            <Typography.Text className="text-[#1E1E1E] text-lg font-normal">
              {item?.answer}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>

      <CreateModal
        createModal={createModal}
        setCreateModal={setCreateModal}
        refetch={refetch}
        id={item?.id}
      />
    </>
  )
}
