import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import FilterDrawer from '../components/FilterDrawer'
import CreateDrawer from '../components/CreateDrawer'
import BanksTable from '../components/BanksTable'

function BankInfoPage(): JSX.Element {
  const [drawerFilter, setDrawerFilter] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const { control, reset } = useForm()
  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <>
                <HomeOutlined />
                <span>Главная</span>
              </>
            ),
          },
          {
            href: '/banks',
            title: 'Банки',
          },
          {
            title: 'Национальный банк',
          }
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0  border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Банки akwdmlakdlaw
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setAddModalOpen(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить банк
            </Button>

            <Button
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px]"
              onClick={() => {
                setDrawerFilter(true)
              }}
            >
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                1
              </Typography.Text>
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          <BanksTable />
        </Flex>
      </Flex>

      <FilterDrawer
        control={control}
        drawerFilter={drawerFilter}
        setDrawerFilter={setDrawerFilter}
        reset={reset}
      />

      <CreateDrawer
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
      />
    </Flex>
  )
}

export default BankInfoPage
