import requestV3 from '@/utils/axiosV3'

export default async function getListGlobalUsers({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/global-users',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getGlobalUser(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/global-user-detail/${id}`,
    method: 'get',
  })

  return res
}

export async function createUser(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/global-user-create',
    method: 'post',
    data,
  })

  return res
}

export async function updateUserWithPut(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/global-user-update/${data?.id}/`,
    method: 'put',
    data,
  })

  return res
}

export async function updateUser(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/global-user-update/${data?.id}/`,
    method: 'patch',
    data,
  })

  return res
}
