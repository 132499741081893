import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8.5V12.5"
      stroke="#FA8F54"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.00065 12.5C6.88489 12.5 5.91379 13.1747 5.41242 14.17C5.17296 14.6453 5.51658 15.1667 5.97317 15.1667H10.0281C10.4847 15.1667 10.8283 14.6453 10.5889 14.17C10.0875 13.1747 9.11641 12.5 8.00065 12.5Z"
      stroke="#FA8F54"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.33398 3.83398H2.65712C1.99944 3.83398 1.6706 3.83398 1.46743 4.081C1.26426 4.32802 1.32384 4.60463 1.443 5.15785C1.67061 6.21456 2.16431 7.14292 2.83325 7.83398"
      stroke="#FA8F54"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.668 3.83398H13.3448C14.0025 3.83398 14.3314 3.83398 14.5345 4.081C14.7377 4.32802 14.6781 4.60463 14.559 5.15785C14.3313 6.21456 13.8376 7.14292 13.1687 7.83398"
      stroke="#FA8F54"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.00065 8.50065C10.578 8.50065 12.6673 6.42272 12.6673 3.85946C12.6673 3.79224 12.6659 3.72536 12.663 3.65885C12.6346 2.99269 12.6203 2.65961 12.1688 2.2468C11.7174 1.83398 11.2171 1.83398 10.2167 1.83398H5.78462C4.78416 1.83398 4.28393 1.83398 3.83246 2.2468C3.38099 2.65961 3.36675 2.99269 3.33827 3.65885C3.33542 3.72536 3.33398 3.79224 3.33398 3.85946C3.33398 6.42272 5.42332 8.50065 8.00065 8.50065Z"
      stroke="#FA8F54"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default function KubokIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
