import requestV3 from '@/utils/axiosV3'

export default async function getListInstructions({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/instructions',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getPlatformTypesList(): Promise<any> {
  const res: any = await requestV3({
    url: '/home/instruction-platforms',
    method: 'get',
  })

  return res
}

export async function getPurposesList(): Promise<any> {
  const res: any = await requestV3({
    url: '/home/instruction-purposes',
    method: 'get',
  })

  return res
}

export async function updateInstructionWithPatch(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/instruction/${data?.id}`,
    method: 'patch',
    data,
  })

  return res
}

export async function createInstruction(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/instruction-create',
    method: 'post',
    data,
  })

  return res
}

export async function updateInstructionWithPut(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/instruction/${data?.id}`,
    method: 'put',
    data,
  })

  return res
}

export async function getByidInstruction(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/instruction/${id}`,
    method: 'get',
  })

  return res
}
