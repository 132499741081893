import { Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, setPage }): JSX.Element {
  return (
    <Flex vertical className="w-full pb-[24px]">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Жилой комплекс
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Просмотры
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Поделится
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Заявки
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Оценки
          </Typography.Text>
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow key={item?.id} item={item} />
        ))}

        <Flex className="mt-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.count}
            showSizeChanger={false}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
