import requestV3 from '@/utils/axiosV3'

export default async function updatePassword(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/update-password',
    method: 'post',
    data,
  })

  return res
}
