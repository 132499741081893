import BlurImage from '@/components/BlurImage'
import IpotekaIcon from '@/components/icons/ipoteka-icon'
import KubokIcon from '@/components/icons/kubok-icon'
import { MoreOutlined } from '@ant-design/icons'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import FormDrawer from './FormDrawer'
import formatAmount from '@/helpers/formatAmount'
import { useMutation } from '@tanstack/react-query'
import { deleteSpecialOffer } from '../api'
import useMessage from '@/hooks/useShowMessage'

function TableRow({ item, refetch }: any): JSX.Element {
  const [openFormDrawer, setOpenFormDrawer] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDelete, isLoading: isLoadingDeleting } = useMutation({
    mutationFn: deleteSpecialOffer,
    onSuccess: () => {
      showMessage('success', 'Предложения успешно удален.')
      refetch()
      setIsPopoverVisible(false)
    },
  })

  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Flex className="items-center" gap={24}>
        <Flex className="w-[72px] h-[72px] rounded-[16px] overflow-hidden">
          <BlurImage src={item?.image} className="object-cover" />
        </Flex>
        <Typography.Link className="text-[#1E1E1E] font-normal text-base !underline">
          {item?.title}
        </Typography.Link>
      </Flex>

      <Flex gap={10}>
        {item?.tags?.includes(1) && (
          <Flex className="items-center bg-[#E5F1F8] rounded-[14px] p-[6px_10px] gap-[5px]">
            <IpotekaIcon
              style={{
                color: '#FA8F54',
              }}
            />

            <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
              Рассрочка
            </Typography.Text>
          </Flex>
        )}

        {item?.tags?.includes(2) && (
          <Flex className="items-center bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[5px]">
            <KubokIcon />

            <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
              Спецпредложения
            </Typography.Text>
          </Flex>
        )}

        {(item?.tags?.length === 0 ||
          item?.tags === null ||
          item?.tags === undefined) && (
          <Typography.Text className="text-[#1E1E1E] font-normal text-base">
            Нет тегов
          </Typography.Text>
        )}
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.address}
      </Typography.Text>

      <Flex vertical gap={8}>
        {item?.offer_types?.length !== 0 ? (
          item?.offer_types?.map((offer) => (
            <Flex>
              <Typography.Text className="text-[#1E1E1E] font-normal text-base">
                {offer?.offer_type_name}
              </Typography.Text>
              .............
              <Typography.Text className="text-[#1E1E1E] font-normal text-base">
                От {formatAmount(offer?.price)} UZS
              </Typography.Text>
            </Flex>
          ))
        ) : (
          <Typography.Text className="text-[#1E1E1E] font-normal text-base">
            Нет спецпредложений
          </Typography.Text>
        )}
      </Flex>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setOpenFormDrawer(true)
                setIsPopoverVisible(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Редактировать
            </Button>
            <Button
              type="link"
              loading={isLoadingDeleting}
              onClick={() => {
                mutateDelete(item?.id)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <FormDrawer
        refetch={refetch}
        createDrawer={openFormDrawer}
        setCreateDrawer={setOpenFormDrawer}
        id={item?.id}
      />
    </Flex>
  )
}

export default TableRow
