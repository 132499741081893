import HFSelect from '@/components/FormElements/HFSelect'
import NoteIcon from '@/components/icons/note-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import filterObject from '@/helpers/removeKeysWithoutValues'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import getListInstructions, { getPlatformTypesList } from '../api'
import CreateDrawer from '../components/CreateDrawer'
import ItemComponent from '../components/ItemComponent'

function InstructionsPage(): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)
  const { control, watch } = useForm()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['instructions', watch()],
    queryFn: () =>
      getListInstructions({
        filters: {
          ...filterObject(watch()),
        },
      }),
  })

  const { data: platformTypesList, isLoading: platformTypesListLoader } =
    useQuery({
      queryKey: ['platformTypesList'],
      queryFn: () => getPlatformTypesList(),
    })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Инструкции
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-b-[1px]  border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Инструкции
          </Typography.Title>

          <Flex gap={16}>
            <Flex className="w-[200px] h-[48px] instructions-select-wrapper">
              <HFSelect
                control={control}
                name="platform"
                loading={platformTypesListLoader}
                options={platformTypesList?.results.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </Flex>
            <Button
              onClick={() => {
                setCreateDrawer(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить инструкции
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Flex className="w-full h-full p-[24px]">
            <Skeleton active />
          </Flex>
        ) : (
          <Flex className="w-full h-full p-[24px] grid grid-cols-4" gap={24}>
            {data?.results?.map((item: any) => (
              <ItemComponent key={item.id} item={item} refetch={refetch} />
            ))}
          </Flex>
        )}
      </Flex>

      <CreateDrawer
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
        refetch={refetch}
      />
    </Flex>
  )
}

export default InstructionsPage
