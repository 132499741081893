/* eslint-disable import/no-extraneous-dependencies */
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import parse from 'html-react-parser'
import { useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import { updateInstructionWithPatch } from '../api'
import CreateDrawer from './CreateDrawer'

export default function ItemComponent({ item, refetch }: any): JSX.Element {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpenChange = (open: boolean): any => {
    setOpen(open)
  }

  const { mutate } = useMutation({
    mutationFn: updateInstructionWithPatch,
    onSuccess: () => {
      refetch()
      setOpen(false)
    },
  })

  return (
    <Flex
      vertical
      key={item.id}
      className="w-full overflow-hidden rounded-[12px] border-[1px] border-[#EEEEEE] border-solid"
    >
      <Flex className="w-full h-[160px] items-center justify-center overflow-hidden relative">
        <ReactPlayer url={item?.url} controls width="100%" height="100%" />

        <Popover
          content={
            <Flex vertical className="min-w-[170px]">
              <Button
                type="link"
                onClick={() => {
                  setOpenDrawer(true)
                  setOpen(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Изменить
              </Button>
              <Button
                type="link"
                onClick={() => {
                  mutate({
                    id: item?.id,
                    status: !item?.status,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                {item?.status ? 'Скрыть' : 'Показать'}
              </Button>
            </Flex>
          }
          placement="bottomRight"
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <Button className="border-[#FFFFFF33] bg-[#FFFFFF33] right-3 top-3 absolute rounded-full h-[38px] w-[38px] p-0 flex items-center justify-center">
            <MoreOutlined
              style={{
                color: '#FFF',
                fontSize: 16,
              }}
            />
          </Button>
        </Popover>
      </Flex>

      <Flex vertical className="p-[16px]" gap={8}>
        <Typography.Text className="!text-[#1E1E1E] !font-normal !text-sm">
          {parse(item?.description)}
        </Typography.Text>
      </Flex>

      <CreateDrawer
        createDrawer={openDrawer}
        setCreateDrawer={setOpenDrawer}
        id={item?.id}
        refetch={refetch}
      />
    </Flex>
  )
}
