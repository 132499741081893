import { Empty, Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, setPage, refetch }: any): JSX.Element {
  if (data?.count === 0) {
    return (
      <Flex className="w-full p-[24px] justify-center items-center">
        <Empty description="Нет данных" />
      </Flex>
    )
  }
  
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[2fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Фото баннера
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Тип баннера
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Активен до
          </Typography.Text>

          <div />
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow key={item.id} item={item} refetch={refetch} />
        ))}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.count}
            showSizeChanger={false}
            onChange={(e) => {
              setPage(e)
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
