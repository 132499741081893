import BlurImage from '@/components/BlurImage'
import HFDatePicker from '@/components/FormElements/HFDatePicker'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import CalendarIcon from '@/components/icons/calendar-icon'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Image, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import {
  createBanner,
  getBanner,
  getListBannersByType,
  updateBanner,
} from '../api'
import GalleryImage from '../assets/Image.svg'
import useMessage from '@/hooks/useShowMessage'

function CreateDrawer({
  drawerCreate,
  setDrawerCreate,
  refetch,
  item = 'new',
}: any): JSX.Element {
  const { control, reset, watch, setValue, handleSubmit } = useForm()
  const showMessage = useMessage()

  const { data: bannerTypes, isLoading: loaderTypes } = useQuery({
    queryKey: ['banners-types'],
    queryFn: getListBannersByType,
    enabled: drawerCreate,
  })

  const { isFetching } = useQuery({
    queryKey: ['banner-id', item],
    queryFn: () => getBanner({ id: item }),
    enabled: item !== 'new' && drawerCreate,
    onSuccess: (data) => {
      reset(data)
    },
  })

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    createBanner,
    {
      onSuccess: () => {
        refetch()
        setDrawerCreate(false)
        reset({})
        showMessage('success', 'Баннер успешно создан')
      },
    },
  )

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    updateBanner,
    {
      onSuccess: () => {
        refetch()
        setDrawerCreate(false)
        reset({})
        showMessage('success', 'Баннер успешно обновлен')
      },
    },
  )

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const onSubmit = async () => {
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    if (item === 'new') {
      mutateCreate({
        data: {
          ...watch(),
          image: imageFile,
        },
      })
    } else {
      mutateUpdate({
        data: {
          ...watch(),
          image: imageFile,
        },
      })
    }
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('image', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  return (
    <Drawer
      onClose={() => {
        setDrawerCreate(false)
      }}
      open={drawerCreate}
      headerStyle={{
        display: 'none',
      }}
      width={600}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-[1px] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {item === 'new'
                ? 'Добавить новый баннер'
                : 'Редактировать баннер'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerCreate(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            {isFetching && (
              <Flex className="w-full h-full bg-white absolute items-center justify-center z-10">
                <Spin size="large" />
              </Flex>
            )}
            <input type="file" hidden />
            <FRow label="Добавить фото баннера">
              <Flex className="border-[#E5E7EB] border-[1px] border-dashed rounded-[12px]">
                {imageUrlGenerator ? (
                  <Flex className="items-center justify-center w-full h-full relative">
                    <BlurImage
                      src={imageUrlGenerator}
                      preview={false}
                      className="object-cover h-full w-full"
                    />

                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('image', null)
                      }}
                      className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
                    >
                      <CloseOutlined />
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    onClick={inputClick}
                    className="items-center justify-center min-h-[200px] w-full"
                  >
                    <Image width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px]">
                      Выберите или перетащите минимум 1 фото
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                      Максимальный размер: 5 МБ.
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            <FRow label="Тип баннера">
              <HFSelect
                control={control}
                required
                loading={loaderTypes}
                name="type"
                options={bannerTypes?.map((item: any) => ({
                  label: item?.title,
                  value: item?.id,
                }))}
              />
            </FRow>

            <FRow label="Активен до">
              <HFDatePicker
                control={control}
                required
                name="to_date"
                prefix={<CalendarIcon />}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setDrawerCreate(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            loading={isLoadingCreate || isLoadingUpdate}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {item === 'new' ? 'Создать' : 'Сохранить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
