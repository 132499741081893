import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33203 5.71336C1.33203 4.91591 1.65362 4.4272 2.31912 4.05684L5.05861 2.53228C6.4941 1.73342 7.21184 1.33398 7.9987 1.33398C8.78556 1.33398 9.5033 1.73342 10.9388 2.53228L13.6783 4.05684C14.3438 4.4272 14.6654 4.91591 14.6654 5.71336C14.6654 5.92961 14.6654 6.03773 14.6418 6.12661C14.5177 6.59361 14.0945 6.66732 13.6858 6.66732H2.31155C1.90288 6.66732 1.47971 6.59361 1.35565 6.12661C1.33203 6.03773 1.33203 5.92961 1.33203 5.71336Z"
      stroke="#8BC5E5"
      strokeWidth="1.2"
    />
    <path
      d="M7.99597 4.66797H8.00195"
      stroke="#8BC5E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66406 6.66797V12.3346M5.33073 6.66797V12.3346"
      stroke="#8BC5E5"
      strokeWidth="1.2"
    />
    <path
      d="M10.6641 6.66797V12.3346M13.3307 6.66797V12.3346"
      stroke="#8BC5E5"
      strokeWidth="1.2"
    />
    <path
      d="M12.6654 12.334H3.33203C2.22746 12.334 1.33203 13.2294 1.33203 14.334C1.33203 14.5181 1.48127 14.6673 1.66536 14.6673H14.332C14.5161 14.6673 14.6654 14.5181 14.6654 14.334C14.6654 13.2294 13.7699 12.334 12.6654 12.334Z"
      stroke="#8BC5E5"
      strokeWidth="1.2"
    />
  </svg>
)

export default function IpotekaIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
