import { DeleteOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import AdvantagesRow from './AdvantagesRow'

function AdvantagesSection({ control, watch, setValue }: any): JSX.Element {
  const {
    fields: advantagesFields,
    append: advantagesFieldsAppend,
    remove: removeAdvantagesFields,
  } = useFieldArray({
    control,
    name: 'advantages',
  })

  useEffect(() => {
    if (advantagesFields.length === 0) {
      advantagesFieldsAppend({})
    }
  }, [advantagesFields])

  return (
    <section id="advantages">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#2F3336]">
          <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
            Преимущества ЖК
          </Typography.Title>
        </Flex>

        {advantagesFields.map((field, index) => (
          <Flex key={field?.id} className="w-full" gap={20}>
            <Flex className="w-full">
              <AdvantagesRow
                index={index}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </Flex>

            <Flex className="w-max pt-[41px]">
              <Button
                type="link"
                disabled={advantagesFields.length === 1}
                className="w-[30px] h-[30px] flex items-center justify-center text-[#A9A9A9]"
                onClick={() => {
                  removeAdvantagesFields(index)
                }}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          </Flex>
        ))}

        <Flex className="w-full items-center justify-center mt-[24px]">
          <Button
            onClick={() => {
              advantagesFieldsAppend({})
            }}
            className="p-[14px_24px] w-max h-max border-[#635BFF] text-[#635BFF] rounded-[8px] text-base font-medium dark:!bg-[#2F3336]"
          >
            + Добавить новое преимущество
          </Button>
        </Flex>
      </Flex>
    </section>
  )
}

export default AdvantagesSection
