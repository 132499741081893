import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 13.7539L4.5 14.7539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 14.2539L19.5 14.7539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 19.2539L8.24567 18.6397C8.61101 17.7264 8.79368 17.2697 9.17461 17.0118C9.55553 16.7539 10.0474 16.7539 11.0311 16.7539H12.9689C13.9526 16.7539 14.4445 16.7539 14.8254 17.0118C15.2063 17.2697 15.389 17.7264 15.7543 18.6397L16 19.2539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 18.7539V21.6359C2 22.0146 2.24075 22.3609 2.62188 22.5303C2.86918 22.6402 3.10538 22.7539 3.39058 22.7539H5.10942C5.39462 22.7539 5.63082 22.6402 5.87812 22.5303C6.25925 22.3609 6.5 22.0146 6.5 21.6359V19.7539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 19.7539V21.6359C17.5 22.0146 17.7408 22.3609 18.1219 22.5303C18.3692 22.6402 18.6054 22.7539 18.8906 22.7539H20.6094C20.8946 22.7539 21.1308 22.6402 21.3781 22.5303C21.7592 22.3609 22 22.0146 22 21.6359V18.7539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 10.2539L21 9.75391"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.2539L3 9.75391"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 11.2539L5.5883 8.26107C6.02832 7.05104 6.24832 6.44602 6.7721 6.09997C7.29587 5.75391 7.99159 5.75391 9.38304 5.75391H14.617C16.0084 5.75391 16.7041 5.75391 17.2279 6.09997C17.7517 6.44602 17.9717 7.05104 18.4117 8.26107L19.5 11.2539"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 11.2539H19.5C20.4572 12.2111 22 13.5441 22 15.0313V18.3091C22 18.8479 21.6205 19.3013 21.1168 19.3643L18 19.7539H6L2.88316 19.3643C2.37955 19.3013 2 18.8479 2 18.3091V15.0313C2 13.5441 3.54279 12.2111 4.5 11.2539Z"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.55391C10 3.5598 10.5373 2.75391 11.2 2.75391H12H12.8C13.4627 2.75391 14 3.5598 14 4.55391C14 5.21665 13.6418 5.75391 13.2 5.75391H10.8C10.3582 5.75391 10 5.21665 10 4.55391Z"
      stroke="#373737"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

export default function CarMetroIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
