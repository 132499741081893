import BlurImage from '@/components/BlurImage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteService } from '../api'
import CreateDrawer from './CreateDrawer'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [createDrawer, setCreateDrawer] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)

  const { mutate: mutateDelete, isLoading } = useMutation(deleteService, {
    onSuccess: () => {
      refetch()
    },
  })

  return (
    <>
      <Flex className="w-full h-full p-[24px]">
        <Flex
          gap={16}
          vertical
          className="w-full rounded-[12px] border-[1px] border-[#EEEEEE] border-solid p-[24px]"
        >
          <Flex className="items-center justify-between w-full">
            <Flex className="w-[56px] min-w-[56px] h-[56px] rounded-[12px] bg-[#DEF3E5] items-center justify-center">
              <BlurImage src={item?.icon} preview={false} />
            </Flex>

            <Popover
              content={
                <Flex vertical className="min-w-[170px]">
                  <Button
                    type="link"
                    onClick={() => {
                      setCreateDrawer(!createDrawer)
                      setOpenPopover(!openPopover)
                    }}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Редактировать
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      mutateDelete(item?.id)
                      setOpenPopover(!openPopover)
                    }}
                    loading={isLoading}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Удалить
                  </Button>
                </Flex>
              }
              open={openPopover}
              onOpenChange={() => {
                setOpenPopover(!openPopover)
              }}
              trigger="click"
              placement="bottomRight"
            >
              <Button
                onClick={() => {
                  setOpenPopover(!openPopover)
                }}
                className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center"
              >
                <MoreOutlined />
              </Button>
            </Popover>
          </Flex>

          <Flex>
            <Typography.Text className="text-[#1E1E1E] text-xl font-medium">
              {item?.title}
            </Typography.Text>
          </Flex>

          <Flex>
            <Typography.Text className="text-[#1E1E1E] text-lg font-normal">
              {item?.short_desc}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>

      <CreateDrawer
        createDrawer={createDrawer}
        setCreateDrawer={setCreateDrawer}
        id={item?.id}
        refetch={refetch}
      />
    </>
  )
}
