import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Flex, Image, Modal, Spin, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { getBuildingById, updateRCPage } from '../api'
import BackspaceIcon from '../assets/backspace.svg'
import AdvantagesSection from './RCPageForm/AdvantagesSection'
import CharacteristicsSection from './RCPageForm/CharacteristicsSection'
import ConvenienceSection from './RCPageForm/ConvenienceSection'
import GallerySection from './RCPageForm/GallerySection'
import LocationSection from './RCPageForm/LocationSection'
import MainInformationSection from './RCPageForm/MainInformationSection'
import filterObject from '@/helpers/filterObjects'

export default function RCModalForm({ id }: any): JSX.Element {
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    unregister,
    handleSubmit,
  } = useForm({
    defaultValues: {
      nearby_metros: [
        {
          type: 1,
          time: 5,
        },
      ],
    },
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isLoading } = useQuery({
    queryKey: ['rcPagegetById', id],
    queryFn: async () => getBuildingById(id),
    onSuccess: (data) => {
      reset({
        ...data,
        region: data?.region?.id,
        district: data?.region?.id,
      })
    },
  })

  const showMessage = useMessage()

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlParts = url.split('/')
    const filename = urlParts[urlParts.length - 1]

    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'

    const file = new File([blob], filename, { type: mimeType })
    return file
  }

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateRCPage,
    onSuccess: () => {
      queryClient.invalidateQueries(['rcPagegetById', id])
      showMessage('success', 'Успешно изменено')
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const handleSave = async () => {
    let imageFile = watch('image')
    if (isValidURL(watch('image'))) {
      imageFile = await urlToFile(watch('image'))
    }

    let imagesFiles = watch('images')

    imagesFiles = await Promise.all(
      imagesFiles.map(async (image: any) => {
        if (isValidURL(image.image)) {
          return {
            image: await urlToFile(image.image),
          }
        }
        return image
      }),
    )

    let advantages = watch('advantages')

    advantages = advantages.map((advantage: any) => {
      if (isValidURL(advantage.image)) {
        return {
          ...filterObject(advantage),
          image: urlToFile(advantage.image),
        }
      }
      return advantage
    })

    mutateUpdate({
      ...filterObject(getValues()),
      image: imageFile,
      images: imagesFiles,
      advantages
    })
  }

  const onError = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    showMessage(
      'error',
      'Ошибка при создании. Пожалуйста, проверьте введенные данные.',
    )
  }

  return (
    <Flex
      style={{
        height: isLoading ? '300px' : 'auto',
      }}
      vertical
      gap={24}
      className="pb-[30px] w-full relative"
    >
      {isLoading && (
        <Flex
          vertical
          className="h-[full absolute inset-0 bg-[#FFF] bg-opacity-90 z-50 items-center justify-center"
        >
          <Spin />
        </Flex>
      )}
      <MainInformationSection
        control={control}
        watch={watch}
        setValue={setValue}
      />

      <LocationSection control={control} setValue={setValue} watch={watch} />

      <CharacteristicsSection control={control} />

      <ConvenienceSection setValue={setValue} watch={watch} />

      <GallerySection watch={watch} setValue={setValue} />

      <AdvantagesSection control={control} watch={watch} setValue={setValue} />

      <Flex className="items-center justify-end" gap={14}>
        <Button
          onClick={() => {
            setIsModalOpen(true)
          }}
          className="h-auto p-[14px_54px] border-[#E5E7EB] text-[#878787] font-medium text-base dark:!bg-[#2F3336] dark:border-[#2F3336]"
        >
          <CloseIcon />
          Очистить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(handleSave, onError)()
          }}
          loading={isLoadingUpdate}
          className="h-auto p-[14px_54px] bg-[#635BFF] hover:!bg-[#635BFF] text-[#FFFFFF] hover:!text-[#FFFFFF] font-medium text-base"
        >
          Сохранить
        </Button>
      </Flex>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        closeIcon={null}
        footer={null}
        className="search-modal"
      >
        <Flex
          vertical
          gap={24}
          className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid p-[24px]"
        >
          <Flex vertical className="items-center relative">
            <Image src={BackspaceIcon} preview={false} />

            <Typography.Title className="!text-2xl !font-medium m-0 ml-[16px] text-[#1E1E1E] text-center">
              Вы уверены, что хотите очистить все данные?
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="absolute right-1 top-1 w-[24px] h-[24px] p-0"
            >
              <CloseCircleOutlined
                style={{
                  color: '#1E1E1E',
                  fontSize: 24,
                }}
              />
            </Button>
          </Flex>

          <Flex className="w-full items-center justify-center" gap={24}>
            <Button
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="w-[130px] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#A9A9A9] font-medium text-base"
            >
              Отменить
            </Button>

            <Button
              onClick={() => {
                reset()
                unregister()
                setIsModalOpen(false)
              }}
              className="w-[130px] bg-[#635BFF] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#FFFFFF] font-medium text-base"
            >
              Очистить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}
