import requestV3 from '@/utils/axiosV3'

export default async function getAnalytics(period: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/analitics?period=${period}`,
    method: 'get',
  })

  return res
}
