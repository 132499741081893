import BlurImage from '@/components/BlurImage'
import { Flex, Typography } from 'antd'

function TableRow({ item }): JSX.Element {
  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Flex className="items-center" gap={8}>
        <Flex className="w-[70px] h-[70px] overflow-hidden rounded-[14px]">
          <BlurImage
            src={item?.image}
            className="w-full !h-full object-cover"
          />
        </Flex>
        <Typography.Text className="text-[#1E1E1E] font-normal text-base">
          {item?.name}
        </Typography.Text>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.views}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.phone_views}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.requests}
      </Typography.Text>

      <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
        {Math.round(item?.rating * 10) / 10}
      </Typography.Text>
    </Flex>
  )
}

export default TableRow
