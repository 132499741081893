import PlusAddIcon from '@/components/icons/plus-icon'
import { Button, Flex, Image, Typography } from 'antd'
import NoDataIllustration from '../assets/nodata.svg'

function NoData(): JSX.Element {
  return (
    <Flex vertical className="w-full h-[calc(100vh-370px)] items-center justify-center" gap={24}>
      <Image src={NoDataIllustration} alt="No data" preview={false} />

      <Flex vertical className="items-center" gap={8}>
        <Typography.Text className="!text-[#1E1E1E] !font-bold !text-2xl !m-0">
          Вы еще не добавили ни одного затройщика
        </Typography.Text>

        <Typography.Text className="!text-[#777E90] !font-normal !text-base">
          Добавить нового застройщика
        </Typography.Text>
      </Flex>

      <Button className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]">
        <PlusAddIcon
          style={{
            color: '#FFF',
          }}
        />
        Добавить сотрудника
      </Button>
    </Flex>
  )
}

export default NoData
