import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.58366 11.6764C7.87814 11.5971 6.15311 12.0054 4.6483 12.9014C3.46933 13.6034 0.378129 15.0368 2.26088 16.8306C3.18058 17.7068 4.2049 18.3334 5.49272 18.3334H10.0003"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.917 5.41675C12.917 7.48782 11.2381 9.16675 9.16699 9.16675C7.09592 9.16675 5.41699 7.48782 5.41699 5.41675C5.41699 3.34568 7.09592 1.66675 9.16699 1.66675C11.2381 1.66675 12.917 3.34568 12.917 5.41675Z"
      stroke="currentColor"
    />
    <path
      d="M15.0003 17.2621V18.3335M15.0003 17.2621C14.0363 17.2621 13.187 16.7886 12.6889 16.0696M15.0003 17.2621C15.9643 17.2621 16.8136 16.7886 17.3117 16.0696M15.0003 11.9049C15.9644 11.9049 16.8137 12.3785 17.3118 13.0976M15.0003 11.9049C14.0363 11.9049 13.1869 12.3785 12.6888 13.0976M15.0003 11.9049V10.8335M18.3337 12.4406L17.3118 13.0976M11.6673 16.7264L12.6889 16.0696M11.667 12.4406L12.6888 13.0976M18.3333 16.7264L17.3117 16.0696M17.3118 13.0976C17.6064 13.5228 17.7781 14.0338 17.7781 14.5835C17.7781 15.1333 17.6063 15.6444 17.3117 16.0696M12.6888 13.0976C12.3943 13.5228 12.2225 14.0338 12.2225 14.5835C12.2225 15.1333 12.3943 15.6444 12.6889 16.0696"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default function RolesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
