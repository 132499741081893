/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/destructuring-assignment */
import { Image } from 'antd'
import { useState } from 'react'

function BlurImage(props: any): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true)

  return (
    <Image
      {...props}
      alt={props.alt}
      className={`${props.className} duration-300 ease-in-out ${
        isLoading ? 'blur-2xl opacity-0' : 'blur-0 opacity-100'
      }`}
      onLoad={() => {
        setLoading(false)
      }}
    />
  )
}

export default BlurImage
