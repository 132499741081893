import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.33203 8.66683C4.33203 6.15267 4.33203 4.89559 5.1619 4.11454C5.99176 3.3335 7.32741 3.3335 9.9987 3.3335H10.5138C12.688 3.3335 13.7751 3.3335 14.53 3.86539C14.7463 4.01778 14.9383 4.19851 15.1002 4.40209C15.6654 5.11261 15.6654 6.13573 15.6654 8.18198V9.87895C15.6654 11.8544 15.6654 12.8421 15.3527 13.631C14.8502 14.8992 13.7873 15.8996 12.4398 16.3726C11.6016 16.6668 10.5522 16.6668 8.45324 16.6668C7.25386 16.6668 6.65418 16.6668 6.17522 16.4987C5.40523 16.2284 4.79787 15.6568 4.51067 14.9321C4.33203 14.4813 4.33203 13.9169 4.33203 12.788V8.66683Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M15.6667 10C15.6667 11.2273 14.6717 12.2222 13.4444 12.2222C13.0006 12.2222 12.4773 12.1444 12.0458 12.2601C11.6623 12.3628 11.3628 12.6623 11.2601 13.0458C11.1444 13.4773 11.2222 14.0006 11.2222 14.4444C11.2222 15.6717 10.2273 16.6667 9 16.6667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33203 6.66699H11.9987"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33203 9.3335H9.33203"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function NoteIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
