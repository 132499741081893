import requestV3 from '@/utils/axiosV3'

export default async function getListPosts({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/posts',
    method: 'get',
    params: filters,
  })

  return res
}

export async function postCategoriesList(): Promise<any> {
  const res: any = await requestV3({
    url: '/content/post-category',
    method: 'get',
  })

  return res
}

export async function postCategoryCreate(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/post-category',
    method: 'post',
    data,
  })

  return res
}

export async function postAuthorsList(): Promise<any> {
  const res: any = await requestV3({
    url: '/content/authors',
    method: 'get',
  })

  return res
}

export async function getNewsById({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/post/${id}`,
    method: 'get',
  })

  return res
}

export async function createNews(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/posts/create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateNewsWithPut(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/post/${data.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateNewsWithPatch(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/post/${data.id}`,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function deletePost({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/post/${id}`,
    method: 'delete',
  })

  return res
}