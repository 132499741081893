/* eslint-disable no-nested-ternary */
import UrboIcon from '@/components/icons/urbo-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Empty, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAppliesList } from '../api'
import ResponsesTable from '../components/ResponsesTable'

function ResponsesPage(): JSX.Element {
  const { id } = useParams<{ id: string }>()
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch} = useQuery({
    queryKey: ['vacansy-applies', id, page],
    queryFn: () => getAppliesList({ id, page }),
    cacheTime: 0,
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <UrboIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Urbo
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Ваканция
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex
          vertical
          gap={24}
          className="p-[24px] border-x-0 border-t-0  border-[#E5E7EB] border-solid items-start justify-center w-full"
        >
          <Flex className="items-center justify-between w-full">
            <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 flex gap-1">
              Отклики
              <Typography.Title className="!text-[#A9A9A9] !font-medium !text-2xl !m-0">
                ({data?.results?.[0]?.vacancy ?? 0})
              </Typography.Title>
            </Typography.Title>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          {isLoading ? (
            <Flex className="w-full h-full p-[24px]">
              <Skeleton active />
            </Flex>
          ) : data?.results?.length > 0 ? (
            <ResponsesTable
              data={data?.results}
              count={data?.count}
              setPage={setPage}
              page={page}
              refetch={refetch}
            />
          ) : (
            <Flex className="w-full h-full p-[24px] items-center justify-center">
              <Empty description="Нет откликов" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ResponsesPage
