import { Flex, Image, Typography } from 'antd'
import NoDataIllustration from '../assets/nodata.png'

function NoData(): JSX.Element {
  return (
    <Flex
      vertical
      className="w-full h-[calc(100vh-370px)] items-center justify-center"
      gap={24}
    >
      <Image src={NoDataIllustration} alt="No data" preview={false} />

      <Flex vertical className="items-center" gap={8}>
        <Typography.Text className="!text-[#1E1E1E] !font-bold !text-2xl !m-0">
          Нет данных
        </Typography.Text>
      </Flex>
    </Flex>
  )
}

export default NoData
