/* eslint-disable @typescript-eslint/restrict-template-expressions */
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { useQuery } from '@tanstack/react-query'
import { Flex, Typography } from 'antd'
import {
  getListClassTypes,
  getListHeights,
  getListHouseDecorations,
  getListHouseTypes,
  getListParkings,
} from '../../api'

function CharacteristicsSection({ control }: any): JSX.Element {
  const { data: houseDecorations } = useQuery({
    queryKey: ['houseDecorations'],
    queryFn: getListHouseDecorations,
  })

  const { data: classTypes } = useQuery({
    queryKey: ['classTypes'],
    queryFn: getListClassTypes,
  })

  const { data: houseTypes } = useQuery({
    queryKey: ['houseTypes'],
    queryFn: getListHouseTypes,
  })

  const { data: parkingList } = useQuery({
    queryKey: ['parkingList'],
    queryFn: getListParkings,
  })

  const { data: heightList } = useQuery({
    queryKey: ['heightList'],
    queryFn: getListHeights,
  })

  return (
    <section id="characteristics">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#2F3336]">
          <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
            Характеристики
          </Typography.Title>
        </Flex>

        <Flex vertical gap={24} className="pt-[24px]">
          <Flex vertical>
            <Flex gap={24} className="grid grid-cols-2">
              <FRow label="Класс жилья">
                <HFSelect
                  control={control}
                  required
                  name="class_type"
                  options={
                    classTypes?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                />
              </FRow>
              <FRow label="Тип отделки">
                <HFSelect
                  control={control}
                  required
                  name="house_decoration"
                  options={
                    houseDecorations?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                />
              </FRow>
              <FRow label="Материал стен">
                <HFSelect
                  control={control}
                  required
                  name="house_type"
                  options={
                    houseTypes?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                />
              </FRow>

              <FRow label="Высота потолков">
                <HFSelect
                  control={control}
                  required
                  name="ceiling_height"
                  options={
                    heightList?.map((item: any) => ({
                      label: `${item.ceiling_height} м`,
                      value: item.id,
                    })) ?? []
                  }
                />
              </FRow>

              <FRow label="Парковка">
                <HFSelect
                  control={control}
                  required
                  name="parking"
                  options={
                    parkingList?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                />
              </FRow>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </section>
  )
}

export default CharacteristicsSection
