import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84783 1.8335C11.022 1.8335 12.109 1.8335 12.864 2.36539C13.0803 2.51778 13.2723 2.69851 13.4342 2.90209C13.9993 3.61261 13.9993 4.63573 13.9993 6.68198V8.37895C13.9993 10.3544 13.9993 11.3421 13.6867 12.131C13.1841 13.3992 12.1213 14.3996 10.7738 14.8726C9.9356 15.1668 8.88614 15.1668 6.78723 15.1668C5.58785 15.1668 4.98816 15.1668 4.5092 14.9987C3.73921 14.7284 3.13185 14.1568 2.84466 13.4321C2.66602 12.9813 2.66602 12.4169 2.66602 11.288V8.50016"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0007 8.5C14.0007 9.7273 13.0057 10.7222 11.7784 10.7222C11.3346 10.7222 10.8113 10.6444 10.3797 10.7601C9.99631 10.8628 9.69681 11.1623 9.59407 11.5458C9.47843 11.9773 9.55621 12.5006 9.55621 12.9444C9.55621 14.1717 8.56128 15.1667 7.33398 15.1667"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 6.50016L4.33333 4.16683M4.33333 4.16683L2 1.8335M4.33333 4.16683L6.66667 1.8335M4.33333 4.16683L2 6.50016"
      stroke="#A9A9A9"
      strokeLinecap="round"
    />
  </svg>
)

export default function ReasonBlockIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
