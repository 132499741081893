import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteAdvantages } from '../api'
import CreateDrawer from './CreateDrawer'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [drawerCreate, setDrawerCreate] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDeleteAdvantages, isLoading: loaderDeletion } =
    useMutation({
      mutationFn: deleteAdvantages,
      onSuccess: () => {
        refetch()
        setIsPopoverVisible(false)
        showMessage('success', 'Преимущество успешно удалено')
      },
    })

  return (
    <Flex
      key={item?.id}
      className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Flex className="w-[70px] h-[70px] overflow-hidden rounded-[14px]">
        <Image
          preview={false}
          src={item?.image}
          className="w-full !h-full object-cover"
        />
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.title}
      </Typography.Text>

      <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
        {item?.type}
      </Typography.Text>

      <Typography.Link
        onClick={() => {
          window.open(item?.link, '_blank')
        }}
        className="!text-[#635BFF] font-normal text-base !underline"
      >
        {item?.link}
      </Typography.Link>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setDrawerCreate(true)
                setIsPopoverVisible(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Редактировать
            </Button>
            <Button
              type="link"
              loading={loaderDeletion}
              onClick={() => {
                mutateDeleteAdvantages({
                  id: item?.id,
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <CreateDrawer
        drawerCreate={drawerCreate}
        setDrawerCreate={setDrawerCreate}
        refetch={refetch}
        item={item?.id}
      />
    </Flex>
  )
}
