import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Modal, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { getListReasons, placementReject } from '../api'

function IgnoreAds({
  ignoreModal,
  setIgnoreModal,
  refetch,
  item,
}: any): JSX.Element {
  const showMessage = useMessage()
  const { control, watch, setValue } = useForm({
    defaultValues: {
      reason: [] as string[],
      description: '',
    },
  })

  const { data: reasons } = useQuery({
    queryKey: ['block-reasons'],
    queryFn: async () => await getListReasons(),
  })

  const { mutate: mutateBlockUser, isLoading: loaderBlocking } = useMutation({
    mutationFn: placementReject,
    onSuccess: () => {
      showMessage('success', 'Успешно!')
      refetch()
      setIgnoreModal(false)
    },
  })

  return (
    <Modal
      open={ignoreModal}
      onOk={() => {
        setIgnoreModal(false)
      }}
      onCancel={() => {
        setIgnoreModal(false)
      }}
      footer={null}
      width={500}
      height="max-content"
      closeIcon={
        <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
      }
    >
      <Flex vertical className="w-full overflow-auto">
        <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] w-full border p-[24px]">
          <Typography.Title className="!font-medium !text-2xl !m-0">
            Причина блокировки
          </Typography.Title>
        </Flex>

        <Flex vertical className="p-[24px]" gap={24}>
          <FRow label="Выберите причину">
            <Flex vertical gap={24}>
              {reasons?.map((reason: any) => (
                <Checkbox
                  key={reason?.id}
                  value={reason?.id}
                  onChange={(e) => {
                    setValue('reason', [...watch('reason'), e.target.value])
                  }}
                >
                  {reason?.name}
                </Checkbox>
              ))}
            </Flex>
          </FRow>

          <FRow label="Напишите причину">
            <HFTextarea control={control} rows={6} name="description" />
          </FRow>
        </Flex>

        <Flex className="w-full items-center p-[24px]" gap={24}>
          <Button
            className="w-full h-max py-[14px] px-[24px] rounded-[10px] border border-solid border-[#E5E7EB] text-[#1E1E1E] font-medium text-base"
            // onClick={() => {
            //   setBlockModal(false)
            // }}
          >
            Отмена
          </Button>

          <Button
            loading={loaderBlocking}
            onClick={() => {
              mutateBlockUser({
                placement: item?.id,
                reject_reasons: watch('reason'),
                description: watch('description'),
              })
            }}
            className="w-full h-max py-[14px] px-[24px] rounded-[10px] border border-solid border-[#E5E7EB] text-[#FFF] bg-[#CE5A67] font-medium text-base"
          >
            Заблокировать
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default IgnoreAds
