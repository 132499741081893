import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33301 11.6667H5.32869C5.57381 11.6667 5.81557 11.722 6.03482 11.8281L7.73646 12.6514C7.95571 12.7575 8.19747 12.8127 8.44259 12.8127H9.31145C10.1518 12.8127 10.833 13.4719 10.833 14.2851C10.833 14.3179 10.8105 14.3468 10.7778 14.3559L8.6604 14.9413C8.28056 15.0463 7.87384 15.0097 7.52051 14.8388L5.70143 13.9586"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.833 13.75L14.6603 12.5741C15.3388 12.3627 16.0722 12.6134 16.4973 13.2019C16.8046 13.6275 16.6794 14.2368 16.2317 14.4951L9.96874 18.1088C9.5704 18.3386 9.10046 18.3947 8.6623 18.2647L3.33301 16.6833"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 10.0001H10.8333C9.26198 10.0001 8.47631 10.0001 7.98816 9.51193C7.5 9.02377 7.5 8.2381 7.5 6.66675V5.00008C7.5 3.42873 7.5 2.64306 7.98816 2.1549C8.47631 1.66675 9.26198 1.66675 10.8333 1.66675H12.5C14.0713 1.66675 14.857 1.66675 15.3452 2.1549C15.8333 2.64306 15.8333 3.42873 15.8333 5.00008V6.66675C15.8333 8.2381 15.8333 9.02377 15.3452 9.51193C14.857 10.0001 14.0713 10.0001 12.5 10.0001Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.833 4.16675H12.4997"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function ServicesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
