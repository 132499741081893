import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3337 9.63889C18.3337 14.0415 14.6022 17.6112 10.0003 17.6112C9.45923 17.6118 8.91969 17.5618 8.38817 17.462C8.0056 17.3902 7.81431 17.3542 7.68077 17.3746C7.54722 17.395 7.35797 17.4957 6.97948 17.697C5.90877 18.2664 4.66029 18.4674 3.45958 18.2441C3.91594 17.6828 4.22761 17.0093 4.36514 16.2873C4.44847 15.8457 4.24199 15.4166 3.93273 15.1025C2.5281 13.6762 1.66699 11.7542 1.66699 9.63889C1.66699 5.23627 5.39847 1.66663 10.0003 1.66663C14.6022 1.66663 18.3337 5.23627 18.3337 9.63889Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M8.33398 8.20509C8.33398 7.35542 9.08018 6.66663 10.0007 6.66663C10.9211 6.66663 11.6673 7.35542 11.6673 8.20509C11.6673 8.51136 11.5704 8.79673 11.4032 9.03647C10.9052 9.75099 10.0007 10.4323 10.0007 11.282V11.6666"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M10 13.75H10.0075"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function FaqIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
