import requestV3 from '@/utils/axiosV3'

export default async function getListGlobalRoles({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/global_roles/',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getListPermissionCategories(): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/global_permission_categories/',
    method: 'get',
  })

  return res
}

export async function createGlobalRole(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/global_roles/',
    method: 'post',
    data,
  })

  return res
}

export async function updateGlobalRole( data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/update-global-role/${data?.id}/`,
    method: 'put',
    data,
  })

  return res
}

export async function deleteGlobalRole({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/global_roles/${id}/`,
    method: 'delete',
  })

  return res
}

export async function getGlobalRole({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/accounts/global_roles/${id}/`,
    method: 'get',
  })

  return res
}
