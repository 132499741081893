import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11426_22690)">
      <path
        d="M14.0007 3.3335V8.66683M16.6673 6.00016L11.334 6.00016"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 6.00016C3.33398 5.06674 3.33398 4.60003 3.51564 4.24351C3.67543 3.92991 3.9304 3.67494 4.244 3.51515C4.60052 3.3335 5.06723 3.3335 6.00065 3.3335C6.93407 3.3335 7.40078 3.3335 7.7573 3.51515C8.07091 3.67494 8.32587 3.92991 8.48566 4.24351C8.66732 4.60003 8.66732 5.06674 8.66732 6.00016C8.66732 6.93358 8.66732 7.40029 8.48566 7.75681C8.32587 8.07042 8.07091 8.32538 7.7573 8.48517C7.40078 8.66683 6.93407 8.66683 6.00065 8.66683C5.06723 8.66683 4.60052 8.66683 4.244 8.48517C3.9304 8.32538 3.67543 8.07042 3.51564 7.75681C3.33398 7.40029 3.33398 6.93358 3.33398 6.00016Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <path
        d="M3.33398 14.0002C3.33398 13.0667 3.33398 12.6 3.51564 12.2435C3.67543 11.9299 3.9304 11.6749 4.244 11.5152C4.60052 11.3335 5.06723 11.3335 6.00065 11.3335C6.93407 11.3335 7.40078 11.3335 7.7573 11.5152C8.07091 11.6749 8.32587 11.9299 8.48566 12.2435C8.66732 12.6 8.66732 13.0667 8.66732 14.0002C8.66732 14.9336 8.66732 15.4003 8.48566 15.7568C8.32587 16.0704 8.07091 16.3254 7.7573 16.4852C7.40078 16.6668 6.93407 16.6668 6.00065 16.6668C5.06723 16.6668 4.60052 16.6668 4.244 16.4852C3.9304 16.3254 3.67543 16.0704 3.51564 15.7568C3.33398 15.4003 3.33398 14.9336 3.33398 14.0002Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <path
        d="M11.334 14.0002C11.334 13.0667 11.334 12.6 11.5156 12.2435C11.6754 11.9299 11.9304 11.6749 12.244 11.5152C12.6005 11.3335 13.0672 11.3335 14.0007 11.3335C14.9341 11.3335 15.4008 11.3335 15.7573 11.5152C16.0709 11.6749 16.3259 11.9299 16.4857 12.2435C16.6673 12.6 16.6673 13.0667 16.6673 14.0002C16.6673 14.9336 16.6673 15.4003 16.4857 15.7568C16.3259 16.0704 16.0709 16.3254 15.7573 16.4852C15.4008 16.6668 14.9341 16.6668 14.0007 16.6668C13.0672 16.6668 12.6005 16.6668 12.244 16.4852C11.9304 16.3254 11.6754 16.0704 11.5156 15.7568C11.334 15.4003 11.334 14.9336 11.334 14.0002Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </g>
    <defs>
      <clipPath id="clip0_11426_22690">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)

export default function MenuIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
