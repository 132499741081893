import BlurImage from '@/components/BlurImage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { updateVacansy } from '../api'

function TableRow({ item, refetch }: any): JSX.Element {
  const navigate = useNavigate()

  // const { mutate: deleteVacansyMutation, isLoading: loaderDeleting } =
  //   useMutation({
  //     mutationFn: deleteVacansy,
  //     onSuccess: () => {
  //       navigate('/vacansies')
  //       refetch()
  //     },
  //   })

  const { mutate: updateStatusVacansyMutation, isLoading: loaderUpdating } =
    useMutation({
      mutationFn: updateVacansy,
      onSuccess: () => {
        refetch()
      },
    })

  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Flex className="w-[76px] h-[76px] overflow-hidden rounded-[16px]">
        <BlurImage src={item?.image} className="w-full h-full object-cover" />
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.title}
      </Typography.Text>

      <Typography.Link
        onClick={() => {
          navigate(`/vacansies/${item?.id}/responses`)
        }}
        className="font-normal text-base !underline"
      >
        {item?.vacancy_apply_count}
      </Typography.Link>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {format(new Date(item?.created_at), 'dd.MM.yyyy')}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                navigate(`/vacansies/${item?.id}`)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Редактировать
            </Button>
            {/* <Button
              type="link"
              onClick={() => {
                deleteVacansyMutation(item?.id)
              }}
              loading={loaderDeleting}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button> */}
            <Button
              type="link"
              onClick={() => {
                updateStatusVacansyMutation({
                  id: item?.id,
                  status: !item?.status,
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              {item?.status ? 'Скрыть' : 'Показать'}
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>
    </Flex>
  )
}

export default TableRow
