import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="elements">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25C5.06294 0.25 0.25 5.06294 0.25 11ZM15.6757 7.26285C16.0828 7.63604 16.1103 8.26861 15.7372 8.67573L10.2372 14.6757C10.0528 14.8768 9.79441 14.9938 9.52172 14.9998C9.24903 15.0057 8.98576 14.9 8.79289 14.7071L6.29289 12.2071C5.90237 11.8166 5.90237 11.1834 6.29289 10.7929C6.68342 10.4024 7.31658 10.4024 7.70711 10.7929L9.46859 12.5544L14.2628 7.32428C14.636 6.91716 15.2686 6.88966 15.6757 7.26285Z"
        fill="white"
      />
    </g>
  </svg>
)

export default function DoneIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
