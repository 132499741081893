import ArrowDownIcon from '@/components/icons/arrow-down'
import { Button, Flex, Popover } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

function MenuButtonWithChildren({ element }: any): JSX.Element | null {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isSuperUser = localStorage.getItem('is_superuser')
  const permissions = localStorage
    ?.getItem('permissions')
    ?.split(',')
    ?.map((item) => item.trim())

  function getLastString(inputString: string): string {
    const parts = inputString.split('/')
    const lastString = parts[parts.length - 1]
    return lastString
  }

  function isActive(childrens: any): boolean {
    return childrens.some(
      (child: any) => getLastString(pathname) === getLastString(child.link),
    )
  }

  const content = (
    <Flex className="flex-col w-full min-w-[160px]" gap={6}>
      {element.children.map(
        (child: any) =>
          (permissions?.includes(child.id) || isSuperUser) && (
            <Button
              key={child.id}
              className={`${
                getLastString(pathname) === getLastString(child.link)
                  ? '!bg-[#F1F2F4]'
                  : ''
              } menu-item-margin p-[9px] flex rounded-[12px] gap-[8px] items-center justify-start h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left`}
              onClick={() => {
                navigate(child.link)
              }}
            >
              {child.icon}
              {child.name}
            </Button>
          ),
      )}
    </Flex>
  )

  if (
    !isSuperUser &&
    !element?.key?.some((element) => permissions?.includes(element))
  ) {
    return null
  }
  return (
    <Popover
      placement="bottom"
      arrow={false}
      content={content}
      overlayInnerStyle={{
        padding: '8px',
      }}
    >
      <Button
        className={`${
          isActive(element.children) ? '!text-[#635BFF] !bg-[#F1F2F4]' : ''
        } menu-item-margin p-[9px] flex rounded-[12px] items-center h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left`}
      >
        {element.icon}
        {element.name}
        <ArrowDownIcon />
      </Button>
    </Popover>
  )
}

export default MenuButtonWithChildren
