import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.05394 7.78654C1.84682 7.78654 1.65781 7.61866 1.66734 7.3992C1.7231 6.11414 1.87933 5.27756 2.31706 4.61578C2.56889 4.23505 2.8817 3.9039 3.24135 3.63731C4.21345 2.91675 5.58482 2.91675 8.32755 2.91675H11.6731C14.4159 2.91675 15.7872 2.91675 16.7593 3.63731C17.119 3.9039 17.4318 4.23505 17.6836 4.61578C18.1213 5.27749 18.2775 6.11396 18.3333 7.39877C18.3429 7.61848 18.1536 7.78654 17.9462 7.78654C16.7914 7.78654 15.8553 8.77758 15.8553 10.0001C15.8553 11.2226 16.7914 12.2136 17.9462 12.2136C18.1536 12.2136 18.3429 12.3817 18.3333 12.6014C18.2775 13.8862 18.1213 14.7227 17.6836 15.3844C17.4318 15.7651 17.119 16.0963 16.7593 16.3628C15.7872 17.0834 14.4159 17.0834 11.6731 17.0834H8.32755C5.58482 17.0834 4.21345 17.0834 3.24135 16.3628C2.8817 16.0963 2.56889 15.7651 2.31706 15.3844C1.87933 14.7226 1.7231 13.886 1.66734 12.601C1.65781 12.3815 1.84682 12.2136 2.05394 12.2136C3.20876 12.2136 4.14492 11.2226 4.14492 10.0001C4.14492 8.77758 3.20876 7.78654 2.05394 7.78654Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)

export default function CouponIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
