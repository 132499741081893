import { Flex, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, refetch }: any): JSX.Element {
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_3fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Роль
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Функции
          </Typography.Text>

          <div />
        </Flex>

        {data?.map((item: any) => (
          <TableRow key={item?.id} item={item} refetch={refetch}/>
        ))}
      </Flex>
    </Flex>
  )
}

export default Table
