/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import requestV3 from '@/utils/axiosV3'

export default async function getListUsers({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/users',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getListRealtors({ filters }: any): Promise<any> {
    const res: any = await requestV3({
      url: '/home/realtors',
      method: 'get',
      params: filters,
    })
  
    return res
  }

export async function createBuilding(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/building-user-create',
    method: 'post',
    data,
  })

  return res
}

export async function getListReasons(): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/block-reasons',
    method: 'get',
  })

  return res
}

export async function blockUser(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/block_user',
    method: 'post',
    data,
  })

  return res
}

export async function unBlockUser(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/accounts/un-block-user',
    method: 'post',
    data,
  })

  return res
}

export async function verifyRealtor(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/realtor-verified/${data?.user}`,
    method: 'put',
    data,
  })

  return res
}