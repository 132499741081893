import requestV3 from '@/utils/axiosV3'

export default async function getListAdvantages({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/advertisings',
    method: 'get',
    params: filters,
  })

  return res
}

export async function createAdvantages({ data }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/advertisings/create',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function updateAdvantages({ data }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/advertising/${data?.id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function deleteAdvantages({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/advertising/${id}`,
    method: 'delete',
  })

  return res
}

export async function getAdvantageById({ item }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/advertising/${item}`,
    method: 'get',
  })

  return res
}

export async function getAdvantagesTypesList(): Promise<any> {
  const res: any = await requestV3({
    url: '/base/advertising-type',
    method: 'get',
  })

  return res
}
