import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66732 15.1668H4.39456C3.36431 15.1668 2.54486 14.6655 1.80909 13.9645C0.302894 12.5296 2.77585 11.3828 3.71903 10.8212C5.31851 9.86882 7.22899 9.60518 9.00065 10.0303"
      stroke="#CE5A67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 4.8335C11 6.49035 9.65685 7.8335 8 7.8335C6.34315 7.8335 5 6.49035 5 4.8335C5 3.17664 6.34315 1.8335 8 1.8335C9.65685 1.8335 11 3.17664 11 4.8335Z"
      stroke="#CE5A67"
    />
    <path
      d="M10.7 11.2L13.9667 14.4667M14.6667 12.8333C14.6667 11.5447 13.622 10.5 12.3333 10.5C11.0447 10.5 10 11.5447 10 12.8333C10 14.122 11.0447 15.1667 12.3333 15.1667C13.622 15.1667 14.6667 14.122 14.6667 12.8333Z"
      stroke="#CE5A67"
      strokeLinecap="round"
    />
  </svg>
)

export default function BlockUserIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
