import { Flex, Pagination, Typography } from 'antd'
import ResponsesTableRow from './ResponsesTableRow'

function ResponsesTable({ data, count, setPage, page, refetch }: any): JSX.Element {
  return (
    <Flex vertical className="w-full pb-[24px]">
      <Flex vertical className="w-full mt-[16px] px-[24px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Файл
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Номер телефона
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Текст
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>

          <div />
        </Flex>

        {data?.map((item: any) => (
          <ResponsesTableRow key={item.id} item={item} refetch={refetch}/>
        ))}

        <Flex className="mt-[24px]">
          <Pagination
            defaultCurrent={1}
            total={count}
            showSizeChanger={false}
            onChange={(page) => {
              setPage(page)
            }}
            current={page}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ResponsesTable
