import HFInput from '@/components/FormElements/HFInput'
import HFInputMask from '@/components/FormElements/HFInputMask'
import FRow from '@/components/FormElements/HFRow'
import { Flex } from 'antd'
import axios from 'axios'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'

function EditSection({setValue, watch, control}: any): JSX.Element {
  

  const getNominalByCoords = async (
    latitude: number,
    longitude: number,
  ): Promise<any> => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`

    try {
      const response = await axios.get(url)
      const { data: dataField } = response

      if (dataField.display_name) {
        setValue(`address`, dataField.display_name)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickMap = (e: any): void => {
    const coords = e.get('coords')
    void getNominalByCoords(coords[0], coords[1])

    setValue('latitude', coords[0])
    setValue('longitude', coords[1])
  }

  const isCoordsValid =
    watch('longitude') !== undefined && watch('latitude') !== undefined

 

  return (
    <Flex vertical className="w-full h-full p-[32px_24px]">
      <Flex gap={24} className="w-full">
        <FRow label="Телефон номер">
          <HFInputMask control={control} name="phone" required />
        </FRow>

        <FRow label="Электронная почта">
          <HFInput control={control} name="email" />
        </FRow>
      </Flex>

      <Flex gap={24} className="w-full mt-[24px]">
        <FRow label="Адрес">
          <HFInput control={control} name="address" />
        </FRow>
      </Flex>

      <Flex className="rounded-[12px] mt-[24px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid">
        <YMaps>
          <Map
            onClick={handleClickMap}
            width="100%"
            height="500px"
            defaultState={{ center: [41.311081, 69.240562], zoom: 12 }}
          >
            <ZoomControl />
            {isCoordsValid && (
              <Placemark geometry={[watch('latitude'), watch('longitude')]} />
            )}
          </Map>
        </YMaps>
      </Flex>
    </Flex>
  )
}

export default EditSection
