import useMessage from '@/hooks/useShowMessage'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Typography } from 'antd'
import { updateAppliesStatus } from '../api'

function ResponsesTableRow({ item, refetch }: any): JSX.Element {
  const showMessage = useMessage()
  const { mutate } = useMutation({
    mutationFn: updateAppliesStatus,
    onSuccess: () => {
      refetch()
      showMessage('success', 'Статус успешно обновлен')
    },
  })
  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.user?.first_name + ' ' + item?.user?.last_name}
      </Typography.Text>

      <Button
        className="p-[14px_24px] border border-solid border-[#E5E7EB] text-[#1E1e1e] font-medium text-base rounded-[8px] w-max h-auto"
        onClick={() => {
          window.open(item?.file, '_blank')
        }}
      >
        Посмотреть Файл
      </Button>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.user?.phone}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.description}
      </Typography.Text>

      <Typography.Text
        style={{
          color: item?.status === 'rejeced' ? '#CE5A67' : '#58C27D',
          backgroundColor: item?.status === 'rejeced' ? '#F3DCDF' : '#DEF3E5',
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
      >
        •{' '}
        {item?.status === 'in_progress'
          ? 'Новый'
          : item?.status === 'approved'
          ? 'Принят'
          : 'Отклонен'}
      </Typography.Text>

      {item?.status === 'in_progress' && (
        <Flex gap={12}>
          <Button
            onClick={() => {
              mutate({ id: item.id, status: 'rejeced' })
            }}
            className="p-[14px_24px] border border-solid border-[#CE5A67] text-[#CE5A67] font-medium text-base rounded-[8px] w-max h-auto"
          >
            Отказать
          </Button>
          <Button
            onClick={() => {
              mutate({ id: item.id, status: 'approved' })
            }}
            className="p-[14px_24px] border border-solid border-[#58C27D] text-[#58C27D] font-medium text-base rounded-[8px] w-max h-auto"
          >
            Принять
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default ResponsesTableRow
