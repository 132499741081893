import requestV3 from '@/utils/axiosV3'

export default async function getListVacansies({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/vacancies',
    method: 'get',
    params: filters,
  })

  return res
}

export async function deleteVacansy(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy/${id}`,
    method: 'delete',
  })

  return res
}

export async function createVacansy(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/vacancy-create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateVacansy(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy/${data?.id}`,
    method: 'patch',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateVacansyWithPut(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy/${data?.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function getVacansy({ id }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy-detail/${id}`,
    method: 'get',
  })

  return res
}

export async function getAppliesList({ id, page }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy-applies?vacancy_id=${id}&page=${page}`,
    method: 'get',
  })

  return res
}

export async function updateAppliesStatus({ id, status }: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/vacancy-apply/${id}`,
    method: 'patch',
    data: {
      status,
    },
  })

  return res
}
