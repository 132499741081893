import { Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ setValue, data, refetch }: any): JSX.Element {
  return (
    <Flex vertical className="w-full pb-[24px]">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Название
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Теги
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Адрес
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Специальное предложение
          </Typography.Text>

          <div />
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow key={item?.id} item={item} refetch={refetch} />
        ))}

        <Flex className="mt-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.count}
            onChange={(page) => {
              setValue('page', page)
            }}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
