import Spinner from '@/components/spinner'
import DefaultLayout from '@/layouts/default-layout'
import type { CustomRoute } from '@/types'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

interface Props {
  getRoutes: () => CustomRoute[]
}

export default function Root(props: Props): React.ReactElement {
  const { getRoutes } = props
  const routes = getRoutes()

  return (
    <DefaultLayout sidebarRoutes={routes[0].children as CustomRoute[]}>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </DefaultLayout>
  )
}
