import BlurImage from '@/components/BlurImage'
import FRow from '@/components/FormElements/HFRow'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Typography } from 'antd'
import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import GalleryImage from '../assets/Image.svg'

export default function OgImageUz({ control, setValue }: any): JSX.Element {
  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('og_image_uz', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'og_image_uz',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  return (
    <>
      <input type="file" hidden />
      <FRow label="Og image">
        <Flex className="border-[#E5E7EB] border-[1px] border-dashed rounded-[12px]">
          {imageUrlGenerator ? (
            <Flex className="items-center justify-center w-full h-full relative">
              <BlurImage
                src={imageUrlGenerator}
                preview={false}
                className="object-cover h-full w-full"
              />

              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setValue('og_image_uz', null)
                }}
                className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
              >
                <CloseOutlined />
              </Button>
            </Flex>
          ) : (
            <Flex
              vertical
              onClick={inputClick}
              className="items-center justify-center min-h-[200px] w-full"
            >
              <Image width={70} src={GalleryImage} preview={false} />
              <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px]">
                Выберите или перетащите минимум 1 фото
              </Typography.Text>
              <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                Максимальный размер: 5 МБ.
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </FRow>
    </>
  )
}
