/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import filterObject from '@/helpers/removeKeysWithoutValues'
import { HomeOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import getListBuilders from '../api'
import AddBuilderModal from '../components/AddBuilderModal'
import FilterDrawer from '../components/FilterDrawer'
import NoData from '../components/NoData'
import Table from '../components/Table'

function BuildersPage(): JSX.Element {
  const [drawerFilter, setDrawerFilter] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const { control, reset, watch } = useForm({
    defaultValues: {
      filters: {},
    },
  })
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['builders', page, watch('filters')],
    queryFn: async () =>
      await getListBuilders({
        filters: {
          page,
          ...filterObject(watch('filters')),
        },
      }),
  })

  const filterCount = useMemo(() => {
    let count = 0
    if (watch('filters.status')) {
      count++
    }
    if (watch('filters.name')) {
      count++
    }
    if (watch('filters.date')) {
      count++
    }
    return count
  }, [watch('filters.name'), watch('filters.status'), watch('filters.date')])

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <HomeOutlined />
                <Typography.Text className="text-sm font-normal text-center">
                  Главная
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Застройщики
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-[1px] border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px]  border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Застройщики
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setAddModalOpen(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить застройщик
            </Button>

            <Button
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px]"
              onClick={() => {
                setDrawerFilter(true)
              }}
            >
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              {Object.keys(filterObject(watch('filters')))?.length > 0 && (
                <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                  {Object.keys(filterObject(watch('filters')))?.length}
                </Typography.Text>
              )}
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          {isLoading ? (
            <Flex className="p-[24px] w-full h-full">
              <Skeleton active />
            </Flex>
          ) : data?.companies?.count === 0 ? (
            <NoData />
          ) : (
            <Table
              data={data}
              setPage={setPage}
              page={page}
              refetch={refetch}
            />
          )}
        </Flex>
      </Flex>

      <FilterDrawer
        control={control}
        drawerFilter={drawerFilter}
        setDrawerFilter={setDrawerFilter}
        reset={reset}
        watch={watch}
      />

      <AddBuilderModal
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        refetch={refetch}
      />
    </Flex>
  )
}

export default BuildersPage
