import requestV3 from '@/utils/axiosV3'
import type { AxiosResponse } from 'axios'
import type { AuthResponse } from '../types'

export async function login(data: {
  username: string
  password: string
}): Promise<AuthResponse> {
  const res: AuthResponse = await requestV3({
    url: '/accounts/me/',
    method: 'post',
    data,
  })

  return res
}

export async function refreshToken(data: {
  refresh: string
}): Promise<AxiosResponse<AuthResponse>> {
  const res = await requestV3({
    url: '/accounts/me/refresh/',
    method: 'post',
    data,
  })

  return res
}
