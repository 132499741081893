/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-return-await */
import HFInput from '@/components/FormElements/HFInput'
import HFInputMask from '@/components/FormElements/HFInputMask'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import getListBuilders from '@/features/builders/api'
import useMessage from '@/hooks/useShowMessage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { createBuilding } from '../api'

function CreateDrawer({
  setDrawerFilter,
  drawerFilter,
  refetch,
}: any): JSX.Element {
  const { control, reset, handleSubmit, getValues } = useForm()
  const showMessage = useMessage()
  const [pageBuilders, setPageBuilders] = useState(1)
  const [dataBuilders, setDataBuilders] = useState<any>([])
  const [searchBuilders, setSearchBuilders] = useState('')

  const { data, isLoading } = useQuery({
    queryKey: ['builders', pageBuilders],
    queryFn: async () =>
      await getListBuilders({
        filters: {
          page: pageBuilders,
          search: searchBuilders,
        },
      }),
    enabled: drawerFilter,
    onSuccess: (res) => {
      setDataBuilders((prev: any) => [
        ...prev,
        ...res?.companies?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        })),
      ])
    },
  })

  const { mutate: mutateCreate, isLoading: isLoadingCreating } = useMutation({
    mutationFn: createBuilding,
    onSuccess: () => {
      setDrawerFilter(false)
      showMessage('success', 'Застройщик успешно создан.')
      reset()
      refetch()
    },
  })

  const onSubmit = (): void => {
    mutateCreate({
      ...getValues(),
    })
  }

  return (
    <Drawer
      onClose={() => {
        setDrawerFilter(false)
      }}
      open={drawerFilter}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Добавить ЖК
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerFilter(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Номер телефон">
              <HFInputMask
                control={control}
                name="phone"
                prefix="+998"
                required
              />
            </FRow>

            <FRow label="Логин">
              <HFInput
                control={control}
                name="username"
                placeholder="Напишите логин"
                required
              />
            </FRow>

            <FRow label="Пароль">
              <HFInput
                control={control}
                name="password"
                placeholder="Напишите пароль"
                required
              />
            </FRow>

            <FRow label="Застройщик">
              <HFSelect
                control={control}
                loading={isLoading}
                required
                name="organization"
                options={dataBuilders}
                onPopupScroll={(e: any) => {
                  if (
                    e.target.scrollTop + e.target.clientHeight ===
                    e.target.scrollHeight
                  ) {
                    setPageBuilders((prev) => prev + 1)
                  }
                }}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setDrawerFilter(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            loading={isLoadingCreating}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
