/* eslint-disable no-return-await */
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import getListGlobalRoles from '@/features/roles/api'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'

function FilterDrawer({
  setDrawerFilter,
  drawerFilter,
  control,
  reset,
}: any): JSX.Element {

  const { data, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => await getListGlobalRoles({}),
  })

  console.log('data', data);
  

  return (
    <Drawer
      onClose={() => {
        setDrawerFilter(false)
      }}
      open={drawerFilter}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Фильтр
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerFilter(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Статус">
              <HFSelect
                control={control}
                allowClear
                name="is_active"
                options={[
                  { label: 'Активные', value: 'true' },
                  { label: 'Архивированные', value: 'false' },
                ]}
              />
            </FRow>

            <FRow label="Роль">
              <HFSelect
                loading={isLoading}
                control={control}
                allowClear
                name="role"
                options={data?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setDrawerFilter(false)
              reset({})
            }}
          >
            Сбросить
          </Button>

          <Button
            onClick={() => {
              setDrawerFilter(false)
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default FilterDrawer
