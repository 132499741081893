import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83301 14.1666L5.83301 10.8333"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path d="M10 14.1666L10 5.83325" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M14.166 14.1665L14.166 9.1665"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M2.08301 9.99992C2.08301 6.26797 2.08301 4.40199 3.24238 3.24262C4.40175 2.08325 6.26772 2.08325 9.99967 2.08325C13.7316 2.08325 15.5976 2.08325 16.757 3.24262C17.9163 4.40199 17.9163 6.26797 17.9163 9.99992C17.9163 13.7319 17.9163 15.5978 16.757 16.7572C15.5976 17.9166 13.7316 17.9166 9.99967 17.9166C6.26772 17.9166 4.40175 17.9166 3.24238 16.7572C2.08301 15.5978 2.08301 13.7319 2.08301 9.99992Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)

export default function AnalyticsIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
