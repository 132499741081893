import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0208 11.407V10.4685C11.0208 9.74971 11.6085 9.16699 12.3333 9.16699C12.6592 9.16699 12.9572 9.28472 13.1867 9.47963M11.8958 15.167H12.7708C13.4518 15.167 13.7922 15.167 14.05 15.0304C14.2535 14.9225 14.4201 14.7572 14.5289 14.5554C14.6667 14.2999 14.6667 13.9622 14.6667 13.287C14.6667 12.6117 14.6667 12.2741 14.5289 12.0185C14.4201 11.8167 14.2535 11.6514 14.05 11.5436C13.7922 11.407 13.4518 11.407 12.7708 11.407H11.8958C11.2149 11.407 10.8744 11.407 10.6167 11.5436C10.4132 11.6514 10.2465 11.8167 10.1378 12.0185C10 12.2741 10 12.6117 10 13.287C10 13.9622 10 14.2999 10.1378 14.5554C10.2465 14.7572 10.4132 14.9225 10.6167 15.0304C10.8744 15.167 11.2149 15.167 11.8958 15.167Z"
      stroke="#58C27D"
      strokeLinecap="round"
    />
    <path
      d="M8.33398 15.1668H4.39456C3.36431 15.1668 2.54486 14.6655 1.80909 13.9645C0.302894 12.5296 2.77585 11.3828 3.71903 10.8212C5.31851 9.86882 7.22899 9.60518 9.00065 10.0303"
      stroke="#58C27D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 4.8335C11 6.49035 9.65685 7.8335 8 7.8335C6.34315 7.8335 5 6.49035 5 4.8335C5 3.17664 6.34315 1.8335 8 1.8335C9.65685 1.8335 11 3.17664 11 4.8335Z"
      stroke="#58C27D"
    />
  </svg>
)

export default function UnBlockUserIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
