import BlurImage from '@/components/BlurImage'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import getMe from '../api'
import bg from '../assets/bg.jpg'
import FormDrawer from '../components/FormDrawer'

export default function ProfilePage(): JSX.Element {
  const [openDrawer, setOpenDrawer] = useState(false)

  const { data, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: getMe,
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px] pb-[40px]">
      {/* <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Контакты
              </Typography.Text>
            ),
          },
        ]}
      /> */}

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid rounded-[16px] border bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Аккаунт
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setOpenDrawer(true)
              }}
              className="border-[#635BFF] border-solid border-1 bg-[#FFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#635BFF] !font-medium !text-base"
            >
              Изменить
            </Button>
          </Flex>
        </Flex>

        <Flex vertical className="w-full h-full p-[24px]" gap={24}>
          <Flex
            vertical
            className="border-[#E5E7EB] border-solid border rounded-[12px] p-[24px]"
          >
            <Flex
              className="w-full h-[200px] rounded-[16px] relative"
              style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Flex
                className="w-[152px] h-[152px] rounded-full border-[8px] items-center justify-center bg-white border-[#FFF] border-solid overflow-hidden absolute bottom-[-75px] left-[50%]"
                style={{
                  transform: 'translateX(-50%)',
                }}
              >
                <BlurImage
                  src={data?.avatar}
                  className="w-full h-full object-cover"
                  preview={false}
                />
              </Flex>
            </Flex>

            <Flex
              vertical
              className="mt-[100px] items-center justify-center"
              gap={10}
            >
              <Typography.Text className="text-[#1E1E1E] text-2xl font-medium">
                {data?.first_name} {data?.last_name}
              </Typography.Text>
              <Typography.Text className="p-[8px_16px] rounded-[32px] bg-[#E0DEFF] text-[#635BFF] font-normal text-base">
                {data?.global_role_name}
              </Typography.Text>
            </Flex>
          </Flex>

          <Flex
            vertical
            className="border-[#E5E7EB] border-solid border rounded-[12px] p-[24px]"
            gap={24}
          >
            <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
              Общая информация
            </Typography.Text>

            <Flex className="w-full items-center justify-between">
              <Flex vertical gap={12}>
                <Typography.Text className="text-base font-normal text-[#A9A9A9]">
                  Дата регистрации
                </Typography.Text>
                <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
                  {data?.date_joined &&
                    format(new Date(data?.date_joined), 'dd.MM.yyyy')}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={12}>
                <Typography.Text className="text-base font-normal text-[#A9A9A9]">
                  Логин
                </Typography.Text>
                <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
                  {data?.username}
                </Typography.Text>
              </Flex>

              <Flex vertical gap={12}>
                <Typography.Text className="text-base font-normal text-[#A9A9A9]">
                  Пароль
                </Typography.Text>
                <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
                  **********
                </Typography.Text>
              </Flex>

              <Flex vertical gap={12}>
                <Typography.Text className="text-base font-normal text-[#A9A9A9]">
                  Номер телефона
                </Typography.Text>
                <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
                  {data?.phone}
                </Typography.Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            vertical
            className="border-[#E5E7EB] border-solid border rounded-[12px] p-[24px]"
            gap={24}
          >
            <Typography.Text className="text-2xl font-medium text-[#1E1E1E]">
              Мои возможности
            </Typography.Text>

            <Flex
              className="w-full items-center justify-start flex-wrap"
              gap={8}
            >
              {data?.global_role?.permission_list?.map((item) => (
                <Typography.Text
                  key={item}
                  className="rounded-[8px] bg-[#F1F2F4] p-[8px] text-base font-medium text-[#1E1E1E] flex items-center gap-2"
                >
                  {item?.category_data?.name}:
                  <span className="font-normal">{item?.code}</span>
                </Typography.Text>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <FormDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        refetch={refetch}
        data={data}
      />
    </Flex>
  )
}
