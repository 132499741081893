import Icon from "@ant-design/icons/lib/components/Icon";

import type { ReactElement } from "react";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const defaultIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" fill="none">
    <path
      d="M1 4H4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 14H7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 14L19 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 4L19 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4C4 3.06812 4 2.60218 4.15224 2.23463C4.35523 1.74458 4.74458 1.35523 5.23463 1.15224C5.60218 1 6.06812 1 7 1C7.93188 1 8.39782 1 8.76537 1.15224C9.25542 1.35523 9.64477 1.74458 9.84776 2.23463C10 2.60218 10 3.06812 10 4C10 4.93188 10 5.39782 9.84776 5.76537C9.64477 6.25542 9.25542 6.64477 8.76537 6.84776C8.39782 7 7.93188 7 7 7C6.06812 7 5.60218 7 5.23463 6.84776C4.74458 6.64477 4.35523 6.25542 4.15224 5.76537C4 5.39782 4 4.93188 4 4Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M10 14C10 13.0681 10 12.6022 10.1522 12.2346C10.3552 11.7446 10.7446 11.3552 11.2346 11.1522C11.6022 11 12.0681 11 13 11C13.9319 11 14.3978 11 14.7654 11.1522C15.2554 11.3552 15.6448 11.7446 15.8478 12.2346C16 12.6022 16 13.0681 16 14C16 14.9319 16 15.3978 15.8478 15.7654C15.6448 16.2554 15.2554 16.6448 14.7654 16.8478C14.3978 17 13.9319 17 13 17C12.0681 17 11.6022 17 11.2346 16.8478C10.7446 16.6448 10.3552 16.2554 10.1522 15.7654C10 15.3978 10 14.9319 10 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default function FiltersIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />;
}
