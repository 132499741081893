import BlurImage from '@/components/BlurImage'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deletePost, updateNewsWithPatch } from '../api'
import queryClient from '@/utils/query-client'

function TableRow({ item, refetch }: any): JSX.Element {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const navigate = useNavigate()
  const showMessage = useMessage()

  const { mutate: mutateDelete, isLoading: loaderDeletion } = useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      refetch()
      setIsPopoverVisible(false)
      showMessage('success', 'Преимущество успешно удалено')
    },
  })

  const { mutate: mutateUpdatePatch, isLoading: loaderUpdatePatch } =
    useMutation({
      mutationFn: updateNewsWithPatch,
      onSuccess: () => {
        refetch()
        setIsPopoverVisible(false)
        showMessage('success', 'Преимущество успешно изменено')
        queryClient.invalidateQueries('news')
      },
    })

  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Flex className="w-[72px] h-[72px] overflow-hidden rounded-[16px]">
        <BlurImage src={item?.image} className="w-full !h-full object-cover" />
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.title}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.category}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {format(new Date(item?.published_date), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.author?.first_name + ' ' + item?.author?.last_name}
      </Typography.Text>

      <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
        {item?.rating}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setIsPopoverVisible(true)
                navigate(`/news/${item?.id}`)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            <Button
              type="link"
              loading={loaderDeletion}
              onClick={() => {
                mutateDelete({
                  id: item?.id,
                })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
            {item?.status === false && (
              <Button
                type="link"
                onClick={() => {
                  mutateUpdatePatch({
                    id: item?.id,
                    status: true,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Опубликовать
              </Button>
            )}

            {item?.status === true && (
              <Button
                type="link"
                onClick={() => {
                  mutateUpdatePatch({
                    id: item?.id,
                    status: false,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Перенести в черновики
              </Button>
            )}
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>
    </Flex>
  )
}

export default TableRow
