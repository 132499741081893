import AnalyticsUpIcon from '@/components/icons/analytics-up'
import { Flex, Typography } from 'antd'
import { useMemo } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from 'react-apexcharts'

function AnalyticsChart({ data, usersCount }): JSX.Element {
  const sortedData = useMemo(() => {
    if (!data) return []

    const allMonths = Array.from({ length: 12 }, (_, i) => i + 1) // [1, 2, 3, ..., 12]
    const monthsPresent = data?.map((item) => item.date_joined__month)

    allMonths.forEach((month) => {
      if (!monthsPresent?.includes(month)) {
        data.push({
          users_count: 0,
          date_joined__month: month,
        })
      }
    })

    return data
      .sort((a, b) => a.date_joined__month - b.date_joined__month)
      .map((item) => item.users_count)
  }, [data])

  const options = {
    series: [
      {
        name: 'Количество кликов «Показать номер телефона»',
        data: sortedData,
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],
    },
    fill: {
      opacity: 1,
      colors: ['#635BFF'],
    },
  }

  return (
    <Flex
      vertical
      className="bg-[#FFF] rounded-[12px] border border-[#E5E7EB] border-solid"
    >
      <Flex className="p-[16px_24px] items-center justify-between w-full border-b border-solid border-x-0 border-t-0 border-[#E5E7EB]">
        <Typography.Title
          level={5}
          className="m-0 !text-[#1E1E1E] !text-2xl !font-medium !m-0"
        >
          Пользователи
        </Typography.Title>
      </Flex>

      <Flex vertical className="p-[24px]">
        <Flex
          gap={8}
          vertical
          className="no-wrap statistic-row overflow-hidden"
        >
          <Typography.Text className="text-[#1E1E1E] font-bold text-3xl text-nowrap">
            {usersCount}
          </Typography.Text>

          {/* <Flex
            gap={8}
            className="statistic-row overflow-hidden no-wrap items-center"
          >
            <AnalyticsUpIcon />
            <AnalyticsDownIcon />

            <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
              чем в прошлом году
            </Typography.Text>

            <Typography.Text
              className={`text-sm font-medium text-nowrap ${
                true ? 'text-[#58C27D]' : 'text-[#CE5A67]'
              }`}
            >
              2.2%
            </Typography.Text>
          </Flex> */}
        </Flex>

        <Flex className="w-full">
          <Chart
            options={options}
            series={options.series}
            type="bar"
            height={350}
            width="100%"
            style={{ width: '100%' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AnalyticsChart
