import requestV3 from '@/utils/axiosV3'

export default async function getListPlacements({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/placements',
    method: 'get',
    params: filters,
  })

  return res
}

export async function rejectionList(): Promise<any> {
  const res: any = await requestV3({
    url: '/home/placement_rejected',
    method: 'get',
  })

  return res
}

export async function getPlacementTypes(): Promise<any> {
  const res: any = await requestV3({
    url: '/base/placement-type',
    method: 'get',
  })

  return res
}

export async function getPropertyType(): Promise<any> {
  const res: any = await requestV3({
    url: '/base/property-type',
    method: 'get',
  })

  return res
}

export async function placementReject(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/placement_rejected',
    method: 'post',
    data,
  })

  return res
}

export async function getListReasons(): Promise<any> {
  const res: any = await requestV3({
    url: '/home/placement-reject-reasons',
    method: 'get',
  })

  return res
}

export async function activePlacement(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/placement-active',
    method: 'post',
    data,
  })

  return res
}