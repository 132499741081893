import requestV3 from '@/utils/axiosV3'

export default async function getListFaqs({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/faqs',
    method: 'get',
    params: filters,
  })

  return res
}

export async function createFaq(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/faq-create',
    method: 'post',
    data,
  })

  return res
}

export async function updateFaq(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/faq-updatew/${data?.id}`,
    method: 'put',
    data,
  })

  return res
}

export async function deleteFaq(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/content/faq/${id}`,
    method: 'delete',
  })

  return res
}

export async function getFaq(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/content/faq/${id}`,
    method: 'get',
  })

  return res
}
