import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14801_40382)">
      <path
        d="M7.9877 3.3335C6.11663 3.37613 5.02375 3.55424 4.27704 4.29836C3.62352 4.94962 3.40506 5.86512 3.33203 7.3335M12.0097 3.3335C13.8808 3.37613 14.9736 3.55424 15.7204 4.29836C16.3739 4.94962 16.5923 5.86512 16.6654 7.3335M12.0097 16.6668C13.8808 16.6242 14.9736 16.4461 15.7204 15.702C16.3739 15.0507 16.5923 14.1352 16.6654 12.6668M7.9877 16.6668C6.11663 16.6242 5.02375 16.4461 4.27704 15.702C3.62352 15.0507 3.40506 14.1352 3.33203 12.6668"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9772 13.6872C13.1724 13.8825 13.489 13.8825 13.6843 13.6872C13.8795 13.4919 13.8795 13.1754 13.6843 12.9801L12.9772 13.6872ZM11.6438 12.3539L12.9772 13.6872L13.6843 12.9801L12.3509 11.6468L11.6438 12.3539ZM13.1641 9.66699C13.1641 7.734 11.5971 6.16699 9.66406 6.16699V7.16699C11.0448 7.16699 12.1641 8.28628 12.1641 9.66699H13.1641ZM9.66406 6.16699C7.73107 6.16699 6.16406 7.734 6.16406 9.66699H7.16406C7.16406 8.28628 8.28335 7.16699 9.66406 7.16699V6.16699ZM6.16406 9.66699C6.16406 11.6 7.73107 13.167 9.66406 13.167V12.167C8.28335 12.167 7.16406 11.0477 7.16406 9.66699H6.16406ZM9.66406 13.167C11.5971 13.167 13.1641 11.6 13.1641 9.66699H12.1641C12.1641 11.0477 11.0448 12.167 9.66406 12.167V13.167Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_14801_40382">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)

export default function SeoIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
