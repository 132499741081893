/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @tanstack/query/no-deprecated-options */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
import HFDatePicker from '@/components/FormElements/HFDatePicker'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import useMessage from '@/hooks/useShowMessage'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Divider, Flex, Modal, Spin, Typography } from 'antd'
import axios from 'axios'
import { useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'
import { createBuilder, getBuilderById, updateBuilder } from '../api'
import GalleryIcon from '../assets/gallery.svg'
import HFInputMask from '@/components/FormElements/HFInputMask'

function AddBuilderModal({
  addModalOpen,
  setAddModalOpen,
  item = null,
  refetch,
}: any): JSX.Element {
  const { control, reset, handleSubmit, getValues, setValue, watch } = useForm()
  const showMessage = useMessage()
  const [imageErrorRequires, setImageErrorRequires] = useState(false)
  const [isShowMap, setIsShowMap] = useState(false)

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]

        if (file) {
          setValue('image', file)
        }
      })
  }

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      if (isValidURL(watchingImage)) {
        return watchingImage
      }

      if (watchingImage instanceof File || watchingImage instanceof Blob) {
        return URL.createObjectURL(watchingImage)
      }
    }
    return GalleryIcon
  }, [watchingImage])

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const { mutate: mutateCreate, isLoading: isLoadingCreating } = useMutation({
    mutationFn: createBuilder,
    onSuccess: () => {
      setAddModalOpen(false)
      showMessage('success', 'Застройщик успешно создан.')
      reset()
      refetch()
    },
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdating } = useMutation({
    mutationFn: updateBuilder,
    onSuccess: () => {
      setAddModalOpen(false)
      showMessage('success', 'Застройщик успешно изменен.')
      reset()
      refetch()
    },
  })

  const { isLoading } = useQuery({
    queryKey: ['getOneBuilder', item?.id],
    queryFn: (): any => getBuilderById(item?.id),
    enabled: addModalOpen && !!item?.id,
    onSuccess: async (res) => {
      const imageFile = await urlToFile(res.image)
      reset({
        ...res,
        image: imageFile,
      })
    },
  })

  const onSubmit = async (): Promise<any> => {
    if (!watchingImage) {
      setImageErrorRequires(true)
      return
    }
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }
    if (item) {
      mutateUpdate({
        ...getValues(),
        image: imageFile,
      })
    } else {
      mutateCreate({
        ...getValues(),
        image: imageFile,
      })
    }
  }

  const getNominalByCoords = async (
    latitude: number,
    longitude: number,
    lang: string,
  ): Promise<any> => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`

    try {
      const response = await axios.get(url, {
        headers: {
          'Accept-Language': lang,
        },
      })
      const { data: dataField } = response

      if (dataField.display_name) {
        setValue(`address_${lang}`, dataField.display_name)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClickMap = (e: any): void => {
    const coords = e.get('coords')
    void getNominalByCoords(coords[0], coords[1], 'uz')
    void getNominalByCoords(coords[0], coords[1], 'ru')

    setValue('latitude', coords[0])
    setValue('longitude', coords[1])
  }

  const isCoordsValid =
    watch('longitude') !== undefined && watch('latitude') !== undefined

  return (
    <Modal
      open={addModalOpen}
      onOk={() => {
        setAddModalOpen(false)
      }}
      onCancel={() => {
        setAddModalOpen(false)
      }}
      footer={null}
      width="80%"
      style={{
        padding: 0,
      }}
    >
      <Flex
        vertical
        className="w-full h-[70vh] relative overflow-auto rounded-[12px]"
      >
        {isLoading && item !== null && (
          <Flex className="w-full h-full absolute items-center justify-center bg-[#FFFFFFB4] z-10">
            <Spin size="large" spinning={isLoading} />
          </Flex>
        )}

        <Flex className="border-x-0 border-t-0 border-[1px] border-solid border-[#E5E7EB] w-full p-[24px] sticky top-0 bg-[#FFF] z-10">
          <Typography.Title className="!font-medium !text-2xl !m-0">
            {item ? 'Редактировать застройщика' : 'Добавить застройщика'}
          </Typography.Title>
        </Flex>

        <Flex className="p-[24px]" gap={24} vertical>
          <input type="file" hidden />
          <FRow label="Добавить фотографи">
            <Flex gap={16}>
              <Flex>
                <Flex
                  className="w-[72px] h-[72px] rounded-full bg-[#F9FAFC] items-center object-contain justify-center overflow-hidden"
                  style={{
                    border: imageErrorRequires ? '1px solid #ff4d4f' : '',
                    padding: watchingImage ? '0' : '16px',
                  }}
                >
                  <img
                    src={imageUrlGenerator}
                    alt="user"
                    className="w-[100%] h-[100%]"
                  />
                </Flex>
              </Flex>
              <input type="file" hidden />
              <Flex vertical>
                <Flex gap={22}>
                  <Button
                    onClick={inputClick}
                    className="bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
                  >
                    {watchingImage ? 'Изменить' : 'Выбрать'}
                  </Button>
                  {watchingImage && (
                    <Button
                      onClick={() => {
                        setValue('image', null)
                      }}
                      className="bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
                    >
                      Удалить
                    </Button>
                  )}
                </Flex>

                <Typography.Text className="text-[#A9A9A9] font-normal text-sm mt-[11px]">
                  Ваше изображение должно быть квадратным, размером не менее
                  100x100 пикселей и в формате JPG или PNG.
                </Typography.Text>
              </Flex>
            </Flex>
          </FRow>

          <FRow label="Название">
            <HFInput
              control={control}
              name="name"
              placeholder="Например: Golden House"
              required
            />
          </FRow>

          <Flex className="items-center" gap={24}>
            <FRow label="Год основания">
              <HFDatePicker
                control={control}
                name="date"
                placeholder="Например: 2018"
                required
              />
            </FRow>
            <FRow label="Готовые объекты">
              <HFInput
                control={control}
                name="completed_buildings_count"
                placeholder="Например: 10"
                required
              />
            </FRow>
            <FRow label="Строящиеся объекты">
              <HFInput
                control={control}
                name="buildings_count"
                placeholder="Например: 10"
                required
              />
            </FRow>
          </Flex>

          <Divider className="m-0" />

          <Typography.Title className="text-[#1E1E1E] !font-medium !text-xl !m-0">
            О нас
          </Typography.Title>

          <Flex gap={24}>
            <Flex vertical gap={10} className="w-full">
              <Flex className="items-center justify-between">
                <Typography.Text className="text-[#1E1E1E] !font-medium !text-sm !m-0">
                  О застройщике
                </Typography.Text>

                <Typography.Text className="text-[#1E1E1E] !font-normal !text-sm !m-0">
                  UZ
                </Typography.Text>
              </Flex>

              <HFTextarea
                control={control}
                name="description_uz"
                rows={6}
                required
              />
            </Flex>

            <Flex vertical gap={10} className="w-full">
              <Flex className="items-center justify-between">
                <Typography.Text className="text-[#1E1E1E] !font-medium !text-sm !m-0">
                  О застройщике
                </Typography.Text>

                <Typography.Text className="text-[#1E1E1E] !font-normal !text-sm !m-0">
                  RU
                </Typography.Text>
              </Flex>

              <HFTextarea
                control={control}
                name="description_ru"
                rows={6}
                required
              />
            </Flex>
          </Flex>

          <Divider className="m-0" />

          <Typography.Title className="text-[#1E1E1E] !font-medium !text-xl !m-0">
            Адрес
          </Typography.Title>

          <FRow label="Расположение">
            <HFInput
              control={control}
              name="address_ru"
              required
              placeholder="Введите адрес"
              suffix={
                <Button
                  type="link"
                  onClick={() => {
                    setIsShowMap(!isShowMap)
                  }}
                  className="text-[#635BFF] !font-medium !text-base p-0"
                >
                  На карте
                </Button>
              }
            />
          </FRow>

          {isShowMap && (
            <Flex className="rounded-[12px] mt-[24px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid">
              <YMaps>
                <Map
                  onClick={handleClickMap}
                  width="100%"
                  height="300px"
                  defaultState={{ center: [41.311081, 69.240562], zoom: 12 }}
                >
                  <ZoomControl />
                  {isCoordsValid && (
                    <Placemark
                      geometry={[watch('latitude'), watch('longitude')]}
                    />
                  )}
                </Map>
              </YMaps>
            </Flex>
          )}

          <Divider className="m-0" />

          <Typography.Title className="text-[#1E1E1E] !font-medium !text-xl !m-0">
            Контакты
          </Typography.Title>

          <Flex gap={24}>
            <FRow label="Электронная почта">
              <HFInput control={control} name="email" required />
            </FRow>

            <FRow label="Телефон номер">
              <HFInputMask control={control} name="phone" required />
            </FRow>
          </Flex>
        </Flex>

        <Flex className="items-center justify-end p-[24px]" gap={24}>
          <Button
            loading={isLoadingCreating || isLoadingUpdating}
            className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            {item ? 'Сохранить изменения' : 'Добавить застройщика'}
          </Button>

          <Button
            className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setAddModalOpen(false)
            }}
          >
            Отмена
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default AddBuilderModal
