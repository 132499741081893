import PlusAddIcon from '@/components/icons/plus-icon'
import UrboIcon from '@/components/icons/urbo-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getListVacansies from '../api'
import Table from '../components/Table'

function VacansiesPage(): JSX.Element {
  const [activeTab, setActiveTab] = useState('active')
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['vacansies', activeTab],
    cacheTime: 0,
    queryFn: async () =>
      await getListVacansies({
        filters: {
          status: activeTab === 'active' ? 'true' : 'false',
        },
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <UrboIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Urbo
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Ваканция
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex
          vertical
          gap={24}
          className="p-[24px] border-x-0 border-t-0 border border-[#E5E7EB] border-solid items-start justify-center w-full"
        >
          <Flex className="items-center justify-between w-full">
            <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 flex gap-1">
              Ваканции
              <Typography.Title className="!text-[#A9A9A9] !font-medium !text-2xl !m-0">
                ({data?.count})
              </Typography.Title>
            </Typography.Title>

            <Button
              onClick={() => {
                navigate('/vacansies/new')
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить новый
            </Button>
          </Flex>

          <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4]">
            <Button
              style={{
                backgroundColor: activeTab === 'active' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setActiveTab('active')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Активные
            </Button>

            <Button
              onClick={() => {
                setActiveTab('closed')
              }}
              style={{
                backgroundColor: activeTab === 'closed' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Закрытые
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          {isLoading ? (
            <Flex className="w-full h-full p-[24px]">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} refetch={refetch}/>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default VacansiesPage
