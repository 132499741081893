import AnalyticsUpIcon from '@/components/icons/analytics-up'
import { Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import Chart from 'react-apexcharts'

function AnalyticsRate(): JSX.Element {
  const [activeTab, setActiveTab] = useState('posts')

  const options = {
    series: [
      {
        name: 'Количество кликов «Показать номер телефона»',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 100, 10, 23],
      },
    ],
    chart: {
      type: 'line',
      height: 350,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },

    stroke: {
      // curve: 'stepline',
      show: true,
      width: 5,
      colors: ['#1DBF73'],
    },
    xaxis: {
      categories: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],
    },
    fill: {
      opacity: 1,
      colors: ['#635BFF'],
    },
  }

  return (
    <Flex className="bg-[#FFF] rounded-[12px] border border-[#E5E7EB] border-solid overflow-hidden">
      <Flex vertical className=" w-full">
        <Flex className="items-center justify-between p-[16px_24px] border-b border-solid border-x-0 border-t-0 border-[#E5E7EB]">
          <Typography.Title
            level={5}
            className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 w-full"
          >
            Доходы
          </Typography.Title>

          <Flex className="rounded-[8px] bg-[#EEEEEF] p-[2px]" gap={2}>
            <Button
              className={`font-medium text-sm text-[#878787] bg-[#EEEEEF] rounded-[8px] ${
                activeTab === 'posts'
                  ? '!border-[0.5px] !border-[#0000000A] !border-solid !bg-[#FFFFFF] !text-[#1E1E1E]'
                  : ''
              }`}
              onClick={() => {
                setActiveTab('posts')
              }}
            >
              Посты
            </Button>
            <Button
              className={`font-medium text-sm text-[#878787] bg-[#EEEEEF] rounded-[8px] ${
                activeTab === 'stories'
                  ? '!border-[0.5px] !border-[#0000000A] !border-solid !bg-[#FFFFFF] !text-[#1E1E1E]'
                  : ''
              }`}
              onClick={() => {
                setActiveTab('stories')
              }}
            >
              Продвигаемые посты
            </Button>
          </Flex>
        </Flex>

        <Flex vertical className="p-[16px_24px]">
          <Flex
            gap={8}
            vertical
            className="no-wrap statistic-row overflow-hidden"
          >
            <Typography.Text className="text-[#1E1E1E] font-bold text-3xl text-nowrap">
              $117 215
            </Typography.Text>

            {/* <Flex
              gap={8}
              className="statistic-row overflow-hidden no-wrap items-center"
            >
              <AnalyticsUpIcon />
              <AnalyticsDownIcon />

              <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
                чем в прошлом году
              </Typography.Text>

              <Typography.Text
                className={`text-sm font-medium text-nowrap ${
                  true ? 'text-[#58C27D]' : 'text-[#CE5A67]'
                }`}
              >
                2.2%
              </Typography.Text>
            </Flex> */}
          </Flex>

          <Chart
            options={options}
            series={options.series}
            type="line"
            height={350}
            width="100%"
            style={{ width: '100%' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AnalyticsRate
