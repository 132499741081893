import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33301 11.1111C8.33301 10.8528 8.33301 10.7236 8.3614 10.6177C8.43846 10.3301 8.66308 10.1055 8.95066 10.0284C9.05663 10 9.18579 10 9.44412 10H10.5552C10.8136 10 10.9427 10 11.0487 10.0284C11.3363 10.1055 11.5609 10.3301 11.6379 10.6177C11.6663 10.7236 11.6663 10.8528 11.6663 11.1111V11.6667C11.6663 12.5871 10.9201 13.3333 9.99967 13.3333C9.0792 13.3333 8.33301 12.5871 8.33301 11.6667V11.1111Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.583 11.2498H12.5685C13.6387 11.2498 14.1738 11.2498 14.6294 11.1533C15.948 10.874 17.0238 10.0171 17.4946 8.87111C17.6572 8.47519 17.7236 7.9961 17.8564 7.03792C17.9062 6.67822 17.9311 6.49837 17.9071 6.35124C17.8374 5.92278 17.494 5.57182 17.0315 5.4562C16.8726 5.4165 16.6717 5.4165 16.27 5.4165H3.72936C3.3276 5.4165 3.12672 5.4165 2.9679 5.4562C2.50536 5.57182 2.16198 5.92278 2.09221 6.35124C2.06825 6.49837 2.09316 6.67822 2.14299 7.03792C2.27574 7.9961 2.34211 8.47519 2.50475 8.87111C2.97551 10.0171 4.05132 10.874 5.36997 11.1533C5.82555 11.2498 6.36066 11.2498 7.43087 11.2498H8.41634"
      stroke="currentColor"
    />
    <path
      d="M2.91602 9.58325V11.2499C2.91602 14.3926 2.91602 15.964 3.83809 16.9403C4.76016 17.9166 6.24421 17.9166 9.21231 17.9166H10.7864C13.7545 17.9166 15.2385 17.9166 16.1606 16.9403C17.0827 15.964 17.0827 14.3926 17.0827 11.2499V9.58325"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9163 5.41659L12.852 5.12224C12.5311 3.65553 12.3707 2.92218 11.9888 2.50271C11.6069 2.08325 11.0996 2.08325 10.085 2.08325H9.91439C8.8998 2.08325 8.3925 2.08325 8.01058 2.50271C7.62866 2.92218 7.46824 3.65553 7.1474 5.12224L7.08301 5.41659"
      stroke="currentColor"
    />
  </svg>
)

export default function VacansiesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
