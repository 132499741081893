import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.083 13.1899C14.9984 18.13 8.21973 19.5696 4.3342 15.7202C0.524245 11.9457 1.70378 5.10744 6.71345 2.91675"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M14.7356 10.4167C16.3087 10.4167 17.0952 10.4167 17.6031 9.71253C17.6221 9.68621 17.6537 9.63899 17.6707 9.61139C18.1269 8.87263 17.8918 8.30504 17.4216 7.16986C16.9952 6.14048 16.3702 5.20517 15.5824 4.41732C14.7945 3.62947 13.8592 3.00451 12.8299 2.57813C11.6947 2.10792 11.1271 1.87282 10.3883 2.32901C10.3607 2.34606 10.3135 2.37761 10.2872 2.39659C9.58301 2.90454 9.58301 3.69106 9.58301 5.26409V7.02294C9.58301 8.62278 9.58301 9.4227 10.08 9.9197C10.577 10.4167 11.3769 10.4167 12.9768 10.4167H14.7356Z"
      stroke="currentColor"
    />
  </svg>
)

export default function PieChartIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
