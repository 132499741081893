import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import GalleryIcon from '@/components/icons/gallery-icon'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Flex, Typography } from 'antd'
import { useForm } from 'react-hook-form'

function CreateDrawer({ setAddModalOpen, addModalOpen }: any): JSX.Element {
  const { control, reset } = useForm()
  return (
    <Drawer
      onClose={() => {
        setAddModalOpen(false)
      }}
      width={400}
      open={addModalOpen}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full justify-between h-full">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Добавить банк
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setAddModalOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl">
                Добавить логотип
              </Typography.Text>

              <Flex>
                <Flex className="w-[100px] min-w-[100px] h-[100px] rounded-full border-dashed border-[#E5E7EB] border-[1.5px] items-center justify-center">
                  <GalleryIcon />
                </Flex>

                <Flex gap={16} className="p-[24px] w-full">
                  <Button className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]">
                    Добавить
                  </Button>

                  <Button className="border-[#E5E7EB] text-[#CE5A67] rounded-[8px] w-full h-[48px] flex items-center gap-[10px]">
                    Удалить
                  </Button>
                </Flex>
              </Flex>
            </Flex>

            <FRow label="Название банка">
              <HFInput control={control} name="name" />
            </FRow>

            <FRow label="Логин">
              <HFInput control={control} name="login" />
            </FRow>

            <FRow label="Пароль">
              <HFInput control={control} name="password" />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center gap-[10px]"
            onClick={() => {
              setAddModalOpen(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              setAddModalOpen(false)
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Добавить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
