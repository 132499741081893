/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Spin, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactPlayer from 'react-player/youtube'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  createInstruction,
  getByidInstruction,
  getPlatformTypesList,
  getPurposesList,
  updateInstructionWithPut,
} from '../api'

function CreateDrawer({
  createDrawer,
  setCreateDrawer,
  id = 'new',
  refetch,
}: any): JSX.Element {
  const { control, reset, watch, setValue, handleSubmit } = useForm()
  const [activeTab, setActiveTab] = useState('ru')

  const { isFetching } = useQuery({
    queryKey: ['instruction-by-id', id],
    queryFn: () => getByidInstruction(id),
    enabled: id !== 'new' && createDrawer,
    onSuccess: (data) => {
      reset({
        ...data,
        description_ru: data?.translations?.ru?.description,
        description_uz: data?.translations?.uz?.description,
        platform: data?.platform?.id,
        purpose: data?.purpose?.id,
      })
    },
  })

  const { data: platformTypesList, isLoading: platformTypesListLoader } =
    useQuery({
      queryKey: ['platformTypesList'],
      queryFn: () => getPlatformTypesList(),
    })

  const { data: purposesList, isLoading: purposesListLoader } = useQuery({
    queryKey: ['purposesList'],
    queryFn: () => getPurposesList(),
  })

  const { mutate: createInstructionMutate, isLoading: loaderCreate } =
    useMutation({
      mutationFn: createInstruction,
      onSuccess: () => {
        refetch()
        setCreateDrawer(false)
      },
    })

  const { mutate: updateInstructionWithPutMutate, isLoading: loaderUpdate } =
    useMutation({
      mutationFn: updateInstructionWithPut,
      onSuccess: () => {
        refetch()
        setCreateDrawer(false)
      },
    })

  const onSubmit = () => {
    if (id === 'new') {
      createInstructionMutate({
        ...watch(),
      })
    } else {
      updateInstructionWithPutMutate({
        ...watch(),
      })
    }
  }

  return (
    <Drawer
      onClose={() => {
        setCreateDrawer(false)
      }}
      width={700}
      open={createDrawer}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full justify-between h-full">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {id === 'new' ? 'Добавить инструкцию' : 'Изменить инструкцию'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setCreateDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px] relative">
            {isFetching && (
              <Flex className="w-full h-full bg-white absolute items-center justify-center z-10">
                <Spin size="large" />
              </Flex>
            )}
            <FRow label="Выберите язык">
              <Flex className="w-full py-[16px]">
                <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-full">
                  <Button
                    style={{
                      backgroundColor: activeTab === 'ru' ? '#FFF' : '#F1F2F4',
                    }}
                    onClick={() => {
                      setActiveTab('ru')
                    }}
                    className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                  >
                    RU
                  </Button>

                  <Button
                    onClick={() => {
                      setActiveTab('uz')
                    }}
                    style={{
                      backgroundColor: activeTab === 'uz' ? '#FFF' : '#F1F2F4',
                    }}
                    className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                  >
                    UZ
                  </Button>
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Выберите платформу">
              <HFSelect
                control={control}
                loading={platformTypesListLoader}
                required
                name="platform"
                options={platformTypesList?.results.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Цель инструкции">
              <HFSelect
                control={control}
                loading={purposesListLoader}
                required
                name="purpose"
                options={purposesList?.results.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Текст инструкции">
              <ReactQuill
                key={activeTab}
                theme="snow"
                value={watch(`description_${activeTab}`)}
                onChange={(value) => {
                  setValue(`description_${activeTab}`, value)
                }}
              />
            </FRow>

            <FRow label="Ccылка на видео">
              <HFInput control={control} name="url" required />
            </FRow>

            <ReactPlayer
              url={watch('url')}
              controls
              width="100%"
              height="100%"
            />
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setCreateDrawer(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            loading={loaderCreate || loaderUpdate}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {id === 'new' ? 'Добавить' : 'Изменить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
