import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13436_1790)">
      <path
        d="M14.8324 8.00016C14.8324 11.6821 11.8476 14.6668 8.16569 14.6668C4.48379 14.6668 1.49902 11.6821 1.49902 8.00016C1.49902 4.31826 4.48379 1.3335 8.16569 1.3335C10.3468 1.3335 12.2832 2.3809 13.4995 4.00016V1.3335"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13436_1790">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.166016)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default function RefreshIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
