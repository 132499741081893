import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import Table from '../components/UsersAll/Table'
import RealtorsTable from '../components/Realtors/Table'

function UserPage(): JSX.Element {
  const [activeTab, setActiveTab] = useState('all')

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <HomeOutlined />
                <Typography.Text className="text-sm font-normal text-center">
                  Главная
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Пользователи
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-[1px] border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex
          vertical
          gap={24}
          className="p-[24px] border-x-0 border-[1px] border-t-0  border-[#E5E7EB] border-solid items-start justify-center w-full"
        >
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            {activeTab === 'all' ? 'Пользователи' : 'Риелторы'}
          </Typography.Title>

          <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4]">
            <Button
              style={{
                backgroundColor: activeTab === 'all' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setActiveTab('all')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Все пользователи
            </Button>

            <Button
              onClick={() => {
                setActiveTab('realtors')
              }}
              style={{
                backgroundColor: activeTab === 'realtors' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Риелторы
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full px-[24px]">
          {activeTab === 'all' ? <Table /> : <RealtorsTable />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserPage
