import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4412 1.66669C13.7687 1.66669 15.4325 1.66669 16.4663 2.643C17.5 3.61931 17.5 5.19066 17.5 8.33335V11.6667C17.5 14.8094 17.5 16.3807 16.4663 17.357C15.4325 18.3334 13.7687 18.3334 10.4412 18.3334H9.55882C6.23126 18.3334 4.56748 18.3334 3.53374 17.357C2.5 16.3807 2.5 14.8094 2.5 11.6667L2.5 8.33335C2.5 5.19066 2.5 3.61931 3.53374 2.643C4.56748 1.66669 6.23126 1.66669 9.55882 1.66669L10.4412 1.66669Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M6.66699 5.83337H13.3337"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path d="M6.66699 10H13.3337" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M6.66699 14.1667L10.0003 14.1667"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default function PaperIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
