import CheckmarkCircleIcon from '@/components/icons/checkmark-circle'
import ClickIcon from '@/components/icons/click-icon'
import FlashIcon from '@/components/icons/flash-icon'
import IgnoreIcon from '@/components/icons/ignore-icon'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import { activePlacement } from '../api'
import IgnoreAds from './IgnoreAds'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [ignoreModal, setIgnoreModal] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate, isLoading } = useMutation(activePlacement, {
    onSuccess: () => {
      refetch()
      setIsPopoverVisible(false)
    },
    onError: () => {
      showMessage('error', 'Ошибка при активации объявления')
    },
  })

  return (
    <Flex
      key={item?.id}
      gap={10}
      className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.created_by_name}
      </Typography.Text>

      <Flex className="items-center" gap={12}>
        <Flex className="w-[48px] h-[48px] min-w-[48px] overflow-hidden rounded-full border-solid border-[#E5E7EB] items-start justify-center">
          <Image
            src={item?.image}
            alt="uzbekistan"
            width="100%"
            height="100%"
            className="w-full h-full object-cover"
          />
        </Flex>
        <Typography.Link className="text-[#635BFF] font-normal text-base">
          {item?.title}
        </Typography.Link>

        <Flex className="w-[32px] min-w-[32px] h-[32px] rounded-full bg-[#FCE7DB] items-center justify-center">
          <FlashIcon
            style={{
              color: '#FA8F54',
            }}
          />
        </Flex>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {format(new Date(item?.published_at), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.property_type_name}
      </Typography.Text>

      <Typography.Text className="text-[#FA8F54] font-normal text-xs p-[8px] rounded-[32px] bg-[#FCE7DB] w-max">
        {item?.placement_type_name}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.views}
      </Typography.Text>

      <Typography.Text className="text-[#878787] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#F1F2F4] h-max w-max flex items-center gap-[2px]">
        <ClickIcon />
        {item?.phone_views}
      </Typography.Text>

      <Typography.Text
        style={{
          color: item?.status === 1 ? '#58C27D' : '#FA8F54',
          backgroundColor: item?.status === 1 ? '#DEF3E5' : '#FCE7DB',
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
      >
        • {item?.status === 1 ? 'Активный' : 'Архивный'}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            {item?.status === 1 ? (
              <Button
                type="link"
                onClick={() => {
                  setIgnoreModal(true)
                  setIsPopoverVisible(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                <IgnoreIcon />
                Архивировать
              </Button>
            ) : (
              <Button
                type="link"
                loading={isLoading}
                onClick={() => {
                  mutate({ id: item?.id })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                <CheckmarkCircleIcon
                  style={{
                    color: '#58C27D',
                  }}
                />
                Активировать
              </Button>
            )}
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <IgnoreAds
        ignoreModal={ignoreModal}
        setIgnoreModal={setIgnoreModal}
        refetch={refetch}
        item={item}
      />
    </Flex>
  )
}
