import requestV3 from '@/utils/axiosV3'

export default async function getListServices(): Promise<any> {
  const res: any = await requestV3({
    url: '/content/services',
    method: 'get',
  })

  return res
}

export async function createService(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/service/create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateService(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/service/update/${data?.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function deleteService(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/content/service/${id}`,
    method: 'delete',
  })

  return res
}

export async function getService(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/content/service/${id}`,
    method: 'get',
  })

  return res
}
