import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 9.93582C9.7272 2.05725 14.9908 1.9664 17.0942 2.90579C18.0336 5.0092 17.9428 10.2728 10.0642 14.5C9.98252 14.0303 9.52813 12.7999 8.36412 11.6359C7.2001 10.4719 5.9697 10.0175 5.5 9.93582Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.125 14.125C12.6571 14.875 12.8207 16.2198 13.0329 17.5C13.0329 17.5 16.2418 15.1279 14.1892 11.875"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.87497 8.93875C5.12497 7.40666 3.78012 7.24309 2.5 7.03085C2.5 7.03085 4.87209 3.82198 8.12498 5.87457"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.01507 12.4258C4.58854 12.8523 3.86344 14.0893 4.37528 15.6248C5.91078 16.1366 7.14771 15.4115 7.57424 14.985"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4176 7.04167C14.4176 6.23625 13.7647 5.58333 12.9593 5.58333C12.1539 5.58333 11.501 6.23625 11.501 7.04167C11.501 7.84708 12.1539 8.5 12.9593 8.5C13.7647 8.5 14.4176 7.84708 14.4176 7.04167Z"
      stroke="currentColor"
    />
  </svg>
)

export default function RocketIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
