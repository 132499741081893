import Icon from "@ant-design/icons/lib/components/Icon";

import type { ReactElement } from "react";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const defaultIcon = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M14.6673 7.99935C14.6673 4.31745 11.6825 1.33268 8.00065 1.33268C4.31875 1.33268 1.33398 4.31745 1.33398 7.99935C1.33398 11.6812 4.31875 14.666 8.00065 14.666C11.6825 14.666 14.6673 11.6812 14.6673 7.99935Z"
      stroke="currentColor"
      strokeWidth="1.2"
    />
    <path
      d="M5.33398 8.33333L7.00065 10L10.6673 6"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function CheckmarkCircleIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} className="w-4 h-4" {...props} />;
}
