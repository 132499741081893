/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
import RefreshIcon from '@/components/icons/refresh-icon'
import StarFilledIcon from '@/components/icons/star-icon'
import { EditOutlined } from '@ant-design/icons'
import { Button, Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, setPage, page }: any): JSX.Element {
  return (
    <Flex vertical className="w-full mt-[16px] overflow-x-auto px-[24px]">
      <Flex className="w-max border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Название комплекса
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Логин
        </Typography.Text>

        {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Пароль
        </Typography.Text> */}

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Срок сдачи
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Адрес
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Район
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Цена
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Рейтинг
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Статус
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[300px]">
          Застройщик
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[300px] min-w-[100px]">
          Действия
        </Typography.Text>
      </Flex>

      {data?.results?.map((item: any) => (
        <TableRow key={item.id} item={item} />
      ))}

      <Flex className="mt-[24px] pl-[24px] sticky left-0">
        <Pagination
          defaultCurrent={1}
          total={data?.count}
          pageSize={20}
          showSizeChanger={false}
          current={page}
          onChange={(e) => {
            setPage(e)
          }}
        />
      </Flex>
    </Flex>
  )
}

export default Table
