import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4666 1.25H12.5295H12.5295C13.1335 1.24999 13.6336 1.24998 14.0465 1.28515C14.4793 1.32201 14.8703 1.40069 15.2475 1.58742C15.9194 1.92009 16.3442 2.44877 16.6424 2.979C16.8735 3.39004 17.053 3.85182 17.2051 4.24318C17.241 4.33553 17.2754 4.42396 17.3086 4.50689L17.456 4.87534C17.5285 5.0566 17.5647 5.14722 17.6397 5.19869C17.7146 5.25016 17.8133 5.25145 18.0106 5.25403C18.9319 5.26608 19.6281 5.31819 20.2394 5.57479C21.1872 5.97261 21.9565 6.70632 22.4052 7.63547C22.6054 8.05002 22.7073 8.49536 22.7747 9.01254C22.8405 9.51676 22.8789 10.1389 22.9269 10.9174L23.0869 13.5123C23.2068 15.4557 23.3015 16.9915 23.2131 18.196C23.1223 19.4329 22.8322 20.4418 22.0842 21.2486C21.3348 22.0571 20.355 22.4154 19.1375 22.5851C17.9548 22.75 16.4347 22.75 14.515 22.75H10.4808C8.56116 22.75 7.04105 22.75 5.85833 22.5851C4.64086 22.4154 3.66102 22.0571 2.91158 21.2486C2.1636 20.4418 1.87356 19.4329 1.78274 18.196C1.6943 16.9915 1.78903 15.4557 1.9089 13.5123L2.06895 10.9172C2.11695 10.1388 2.15531 9.51672 2.22108 9.01254C2.28854 8.49536 2.39046 8.05002 2.59063 7.63547C3.03929 6.70632 3.80858 5.97261 4.75638 5.57479C5.36778 5.31817 6.06411 5.26607 6.98546 5.25403C7.18279 5.25145 7.28146 5.25016 7.35642 5.19869C7.43139 5.14722 7.46764 5.0566 7.54014 4.87534L7.68752 4.50689C7.72069 4.42397 7.75506 4.33554 7.79095 4.2432L7.79095 4.2432L7.79096 4.24317C7.94307 3.85181 8.12256 3.39003 8.35372 2.979C8.65192 2.44877 9.0767 1.92009 9.74864 1.58742C10.1258 1.40069 10.5168 1.32201 10.9496 1.28515C11.3625 1.24998 11.8625 1.24999 12.4666 1.25H12.4666ZM11.5 6C11.5 5.44772 11.9457 5 12.4955 5C13.0414 5 13.5 5.44791 13.5 6C13.5 6.55209 13.0414 7 12.4955 7C11.9457 7 11.5 6.55229 11.5 6ZM12.5 10C10.2909 10 8.5 11.7909 8.5 14C8.5 16.2091 10.2909 18 12.5 18C14.7091 18 16.5 16.2091 16.5 14C16.5 11.7909 14.7091 10 12.5 10Z"
      fill="#635BFF"
    />
  </svg>
)

export default function Camera2Icon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
