/* eslint-disable no-return-await */
import HFSelect from '@/components/FormElements/HFSelect'
import AlertTriangleIcon from '@/components/icons/alert-triangle-icon'
import HouseIcon from '@/components/icons/house-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import UserIcon from '@/components/icons/user'
import { useQuery } from '@tanstack/react-query'
import { Flex, Typography } from 'antd'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import getAnalytics from '../api'
import AnalyticsChart from '../components/AnalyticsChart'
import AnalyticsRate from '../components/AnalyticsRate'
import AnalyticsStatistics from '../components/AnalyticsStatistics'
import AnalyticsTopRieltors from '../components/AnalyticsTopRieltors'

function Analytics(): JSX.Element {
  const { control, watch } = useForm({
    defaultValues: {
      period: 'today',
    },
  })

  const { data, isLoading } = useQuery({
    queryKey: ['services', watch('period')],
    queryFn: async () => await getAnalytics(watch('period')),
  })

  const statistics = useMemo(
    () => [
      {
        title: 'Пользователи',
        value: data?.user_count,
        lastMonthValue: 2500,
        percent: 10.7,
        icon: (
          <UserIcon
            style={{
              color: '#FA8F54',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#FCE7DB',
      },
      {
        title: 'Объявления',
        value: data?.placement_count,
        lastMonthValue: 417201,
        percent: 51.3,
        icon: (
          <PromotionIcon
            style={{
              color: '#635BFF',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#E0DEFF',
      },
      {
        title: 'Жилые комплексы',
        value: data?.building_count,
        lastMonthValue: 1000,
        percent: 3.3,
        icon: (
          <HouseIcon
            style={{
              color: '#58C27D',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#DEF3E5',
      },
      {
        title: 'Жалобы',
        value: data?.complaint_count,
        lastMonthValue: 7,
        percent: 3.3,
        icon: (
          <AlertTriangleIcon
            style={{
              color: '#CE5A67',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#F3DCDF',
        moreOptions: true,
      },
    ],
    [data],
  )

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Flex className="justify-between items-center">
        <Flex vertical className="mb-[35px]">
          <Typography.Title className="m-0 !text-[32px] !font-medium !text-[#1E1E1E] !mb-[8px]">
            Добро пожаловать! 👋
          </Typography.Title>
          <Typography.Text className="text-[#878787] font-normal text-base">
            Вот что происходит с вашей аналитикой сегодня.
          </Typography.Text>
        </Flex>

        <Flex className="min-w-[150px] items-center">
          <HFSelect
            name="period"
            control={control}
            defaultValue="today"
            options={[
              { value: 'today', label: 'Сегодня' },
              { value: 'week', label: 'Неделя' },
              { value: 'month', label: 'Месяц' },
              { value: 'quarter', label: 'Квартал' },
              { value: 'year', label: 'Год' },
            ]}
          />
        </Flex>
      </Flex>

      <Flex gap={18} className="grid grid-cols-4">
        <AnalyticsStatistics statistics={statistics} isLoading={isLoading} />
      </Flex>

      <Flex gap={18} className="mt-[20px] grid grid-cols-[1fr_1fr]">
        <AnalyticsChart
          data={data?.user_charts}
          usersCount={data?.user_count}
        />
        <AnalyticsRate />
      </Flex>

      <Flex gap={18} className="mt-[20px] pb-[70px]">
        <AnalyticsTopRieltors data={data?.top_rieltor} isLoading={isLoading} />
      </Flex>
    </Flex>
  )
}

export default Analytics
