import NoteIcon from '@/components/icons/note-icon'
import useMessage from '@/hooks/useShowMessage'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import getListContacts, { createContacts, updateContacts } from '../api'
import EditSection from '../components/EditSection'

function ContactsPage(): JSX.Element {
  const { control, handleSubmit, setValue, watch, reset } = useForm()
  const showMessage = useMessage()

  const { mutate: createContactsMutate, isLoading: mutateCreateLoader } =
    useMutation(createContacts, {
      onSuccess: () => {
        refetch()
        showMessage('success', 'Контакт успешно создан')
      },
    })

  const { mutate: updateContactsMutate, isLoading: mutateUpdateLoader } =
    useMutation(updateContacts, {
      onSuccess: () => {
        refetch()
        showMessage('success', 'Контакт успешно обновлен')
      },
    })

  const { isLoading, refetch } = useQuery({
    queryKey: ['contacts'],
    queryFn: getListContacts,
    onSuccess: (data) => {
      reset(data)
    },
  })

  const onSubmit = (data: any): void => {
    if (data.id) {
      updateContactsMutate(data)
    } else {
      createContactsMutate(data)
    }
  }

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Контакты
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid rounded-[16px] border bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Контакты
          </Typography.Title>

          <Flex gap={16}>
            {/* <Button className="border-[#E5E7EB] border-[1px] border-solid bg-[#FFFFFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#878787] !font-medium !text-base">
              Отменить
            </Button> */}

            <Button
              loading={mutateCreateLoader || mutateUpdateLoader}
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              Сохранить
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          <EditSection control={control} setValue={setValue} watch={watch} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ContactsPage
