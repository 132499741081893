/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
/* eslint-disable @tanstack/query/no-deprecated-options */
/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import HFDatePicker from '@/components/FormElements/HFDatePicker'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import CarMetroIcon from '@/components/icons/car-icon'
import IpotekaIcon from '@/components/icons/ipoteka-icon'
import KubokIcon from '@/components/icons/kubok-icon'
import WalkingMetroIcon from '@/components/icons/walking-to-metro'
import { getListMetros } from '@/features/RC/api'
import useMessage from '@/hooks/useShowMessage'
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Image, Spin, Typography } from 'antd'
import { useEffect, useMemo } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import {
  createSpecialOffer,
  getRoomCount,
  getSpecialOffer,
  updateSpecialOffer,
} from '../api'
import GalleryImage from '../assets/Image.svg'
import queryClient from '@/utils/query-client'

export default function FormDrawer({
  createDrawer,
  setCreateDrawer,
  refetch,
  id = null,
}: any): JSX.Element {
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      offer_types: [
        {
          offer_type: '',
          price: '',
        },
      ],
      nearby_metro: {
        type: 1,
        time: 5,
      },
      tags: [],
    },
  })

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'offer_types',
  })

  useEffect(() => {
    if (fields.length === 0) {
      append({})
    }
  }, [fields])

  const showMessage = useMessage()

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation({
    mutationFn: createSpecialOffer,
    onSuccess: () => {
      setCreateDrawer(false)
      refetch()
      showMessage('success', 'Предложения успешно добавлена')
      queryClient.invalidateQueries(['special-offers'])
    },
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateSpecialOffer,
    onSuccess: () => {
      setCreateDrawer(false)
      refetch()
      showMessage('success', 'Предложения успешно обновлена')
      queryClient.invalidateQueries(['special-offers'])
    },
  })

  const { isFetching } = useQuery({
    queryKey: ['spec-offer-by-id', id],
    queryFn: async () => await getSpecialOffer(id),
    enabled: !!id && createDrawer,
    onSuccess: (data) => {
      reset({
        ...data,
        title_ru: data?.translations?.ru?.title,
        title_uz: data?.translations?.uz?.title,
        address_ru: data?.translations?.ru?.address,
        address_uz: data?.translations?.uz?.address,
      })
    },
  })

  const { data: roomCounts, isFetching: isFetchingRoomCounts } = useQuery({
    queryKey: ['room-counts'],
    queryFn: getRoomCount,
  })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response != null) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const onSubmit = async (data: any): Promise<any> => {
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    if (id) {
      mutateUpdate({ id, ...data, image: imageFile })
    } else {
      mutateCreate(data)
    }
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file != null) {
          setValue('image', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  const { data: metros } = useQuery({
    queryKey: ['metros'],
    queryFn: getListMetros,
  })

  return (
    <Drawer
      onClose={() => {
        setCreateDrawer(false)
        reset({
          offer_types: [
            {
              offer_type: '',
              price: '',
            },
          ],
          nearby_metro: {
            type: 1,
            time: 5,
          },
          tags: [],
        })
      }}
      width={600}
      open={createDrawer}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full justify-between h-full relative">
        {isFetching && (
          <Flex className="w-full h-full p-[24px] items-center justify-center absolute top-0 left-0 z-10 bg-white">
            <Spin size="large" />
          </Flex>
        )}
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {id
                ? 'Редактировать спец. предложение'
                : 'Добавить спец. предложение'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setCreateDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <input type="file" hidden />

            <FRow label="Добавить фото">
              <Flex className="border-[#E5E7EB] border-[1px] border-dashed rounded-[12px]">
                {imageUrlGenerator ? (
                  <Flex className="items-center justify-center w-full h-full relative">
                    <BlurImage
                      src={imageUrlGenerator}
                      preview={false}
                      className="object-cover h-full w-full"
                    />

                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('image', null)
                      }}
                      className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
                    >
                      <CloseOutlined />
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    onClick={inputClick}
                    className="items-center justify-center min-h-[200px] w-full"
                  >
                    <Image width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px]">
                      Выберите или перетащите фото
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                      Максимальный размер: 5 МБ.
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            <FRow label="Выберите теги">
              <Flex gap={16}>
                <Flex
                  onClick={() => {
                    if (watch('tags').includes(1)) {
                      setValue(
                        'tags',
                        watch('tags').filter((item: string) => item !== 1),
                      )
                    } else {
                      setValue('tags', [...watch('tags'), 1])
                    }
                  }}
                  style={{
                    backgroundColor: watch('tags').includes(1)
                      ? '#E5F1F8'
                      : '#FFF',
                    border: watch('tags').includes(1)
                      ? 'none'
                      : '1px solid #E5E7EB',
                  }}
                  className="items-center h-[44px] bg-[#E5F1F8] rounded-[14px] p-[6px_10px] gap-[6px] cursor-pointer"
                >
                  <IpotekaIcon
                    style={{
                      color: '#FA8F54',
                    }}
                  />

                  <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
                    Рассрочка
                  </Typography.Text>

                  {watch('tags').includes(1) && (
                    <Button type="link" className="p-0 m-0 text-[#A9A9A9]">
                      <CloseCircleOutlined />
                    </Button>
                  )}
                </Flex>

                <Flex
                  onClick={() => {
                    if (watch('tags').includes(2)) {
                      setValue(
                        'tags',
                        watch('tags').filter((item: string) => item !== 2),
                      )
                    } else {
                      setValue('tags', [...watch('tags'), 2])
                    }
                  }}
                  style={{
                    backgroundColor: watch('tags').includes(2)
                      ? '#FCE7DB'
                      : '#FFF',
                    border: watch('tags').includes(2)
                      ? 'none'
                      : '1px solid #E5E7EB',
                  }}
                  className="items-center h-[44px] bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[6px] cursor-pointer"
                >
                  <KubokIcon />

                  <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
                    Спецпредложения
                  </Typography.Text>

                  {watch('tags').includes(2) && (
                    <Button type="link" className="p-0 m-0 text-[#A9A9A9]">
                      <CloseCircleOutlined />
                    </Button>
                  )}
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Название услуги UZ">
              <HFInput control={control} name="title_uz" required />
            </FRow>

            <FRow label="Название услуги RU">
              <HFInput control={control} name="title_ru" required />
            </FRow>

            <FRow label="Продолжительность рекламы">
              <Flex gap={24}>
                <HFDatePicker control={control} name="from_date" required />
                <HFDatePicker control={control} name="to_date" required />
              </Flex>
            </FRow>

            <Flex vertical>
              <Flex className="text-[#1E1E1E] text-sm font-medium mb-[10px] dark:!text-[#FFF]">
                Расстояние до метро
              </Flex>
              <Flex className="items-center" gap={12}>
                <Flex className="p-[3px] bg-[#F1F2F4] rounded-[8px] w-max h-[45px]">
                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metro.type') === 1
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metro.type', 1)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <WalkingMetroIcon />
                  </Button>

                  <Button
                    type="link"
                    style={{
                      backgroundColor:
                        watch('nearby_metro.type') === 2
                          ? '#FFF'
                          : 'transparent',
                    }}
                    onClick={() => {
                      setValue('nearby_metro.type', 2)
                    }}
                    className="bg-inherit p-[0px_24px] h-full flex items-center justify-center rounded-[8px]"
                  >
                    <CarMetroIcon />
                  </Button>
                </Flex>

                <Flex className="w-full" gap={12}>
                  <Button
                    onClick={() => {
                      setValue('nearby_metro.time', 5)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metro.time') === 5
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    5 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metro.time', 10)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metro.time') === 10
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    10 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metro.time', 15)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metro.time') === 15
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    15 мин.
                  </Button>
                  <Button
                    onClick={() => {
                      setValue('nearby_metro.time', 20)
                    }}
                    style={{
                      backgroundColor:
                        watch('nearby_metro.time') === 20
                          ? '#F1F2F4'
                          : 'transparent',
                    }}
                    className="text-[#373737] font-normal text-base border-[1.5px] border-solid border-[#F1F2F4] rounded-[12px] py-[9px] h-auto"
                  >
                    20 мин.
                  </Button>
                </Flex>
              </Flex>
            </Flex>

            <FRow label="Ближайшее метро">
              <HFSelect
                control={control}
                required
                name="nearby_metro.metro"
                placeholder="Выберите метро"
                className="!h-full"
                options={metros?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Адрес UZ">
              <HFInput control={control} name="address_uz" required />
            </FRow>

            <FRow label="Адрес RU">
              <HFInput control={control} name="address_ru" required />
            </FRow>

            {fields?.map((item, index) => (
              <Flex
                vertical
                key={item.id}
                className="rounded-[12px] border border-solid border-[#E5E7EB] p-[16px]"
                gap={24}
              >
                <Typography.Text className="text-[#1E1E1E] font-medium text-xl">
                  Вариант {index + 1}
                </Typography.Text>
                <Flex vertical gap={16}>
                  <FRow label="Выберите комнатность">
                    <HFSelect
                      control={control}
                      required
                      name={`offer_types.${index}.offer_type`}
                      placeholder="Выберите метро"
                      className="!h-full"
                      loading={isFetchingRoomCounts}
                      options={roomCounts?.map((item: any) => ({
                        label: item.title,
                        value: item.id,
                      }))}
                    />
                  </FRow>

                  <FRow label="Сумма за м2">
                    <HFInput
                      control={control}
                      name={`offer_types.${index}.price`}
                      placeholder="От"
                      required
                    />
                  </FRow>
                </Flex>

                <Button
                  onClick={() => {
                    remove(index)
                  }}
                  disabled={fields.length === 1}
                  className="w-full border-[#CE5A67] text-[#CE5A67] rounded-[8px] h-[48px] disabled:border-[#E5E7EB] disabled:text-[#E5E7EB] disabled:hover:!cursor-not-allowed"
                >
                  Удалить вариант
                </Button>
              </Flex>
            ))}

            <Button
              onClick={() => {
                append({})
              }}
              className="w-full border-[#635BFF] text-[#635BFF] rounded-[8px] h-[48px]"
            >
              Добавить вариант
            </Button>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setCreateDrawer(false)
              reset({
                offer_types: [
                  {
                    offer_type: '',
                    price: '',
                  },
                ],
                nearby_metro: {
                  type: 1,
                  time: 5,
                },
                tags: [],
              })
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            loading={isLoadingCreate || isLoadingUpdate}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {id ? 'Сохранить' : 'Добавить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
