/* eslint-disable @typescript-eslint/restrict-template-expressions */
import AnalyticsDownIcon from '@/components/icons/analytics-down'
import AnalyticsUpIcon from '@/components/icons/analytics-up'
import formatAmount from '@/helpers/formatAmount'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'
import { Flex, Skeleton, Typography } from 'antd'

function AnalyticsStatistics({
  statistics,
  isLoading,
}: {
  statistics: any
  isLoading: boolean
}): JSX.Element {
  return (
    <>
      {statistics.map((item: any, index: number) => (
        <Flex
          key={item.title}
          vertical
          className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden"
        >
          <Flex
            style={{
              backgroundColor: item.backgroundColor,
            }}
            className="w-[40px] h-[40px] items-center justify-center rounded-full"
          >
            {item.icon}
          </Flex>

          <Flex vertical className="mt-[24px] mb-[16px] overflow-hidden">
            <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
              {item.title}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-medium text-[32px]">
              {isLoading ? <Skeleton.Button active /> : formatNumberWithSpaces(item?.value ?? 0)}
            </Typography.Text>
          </Flex>

          <Flex
            gap={8}
            className="no-wrap items-center statistic-row overflow-hidden"
          >
            <Typography.Text className="text-[#1E1E1E] font-medium text-sm text-nowrap">
              {formatNumberWithSpaces(item.lastMonthValue)}
            </Typography.Text>

            <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
              чем в прошлом месяце
            </Typography.Text>

            {item.value > item.lastMonthValue ? (
              <AnalyticsUpIcon />
            ) : (
              <AnalyticsDownIcon />
            )}

            <Typography.Text
              className={`text-sm font-medium text-nowrap ${
                item.value > item.lastMonthValue
                  ? 'text-[#58C27D]'
                  : 'text-[#CE5A67]'
              }`}
            >
              {item.percent}%
            </Typography.Text>
          </Flex>
        </Flex>
      ))}
    </>
  )
}

export default AnalyticsStatistics
