import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 4.16675C12.5 5.54746 11.0348 7.08341 10 7.08341C8.96517 7.08341 7.5 5.54746 7.5 4.16675C7.5 2.78604 8.61929 1.66675 10 1.66675C11.3807 1.66675 12.5 2.78604 12.5 4.16675Z"
      stroke="currentColor"
    />
    <path
      d="M13.3679 7.5C14.6371 8.61276 15.4594 10.4974 14.7209 12.2543C14.5618 12.6328 14.1967 12.8783 13.7928 12.8783C13.3821 12.8783 12.7075 12.7466 12.5764 13.2812L11.662 17.0103C11.4713 17.7879 10.7861 18.3333 9.99999 18.3333C9.21388 18.3333 8.52865 17.7879 8.33799 17.0103L7.42361 13.2812C7.29253 12.7466 6.61791 12.8783 6.20718 12.8783C5.80325 12.8783 5.43819 12.6328 5.27907 12.2543C4.54055 10.4974 5.36298 8.61276 6.63214 7.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default function EmployeesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
