import HouseIcon from '@/components/icons/house-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import UserIcon from '@/components/icons/user'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'
import { Flex, Pagination, Skeleton, Typography } from 'antd'
import { useMemo } from 'react'
import TableRow from './TableRow'

function Table({ data, isLoading, setPage, page, refetch }: any): JSX.Element {
  const statistics = useMemo(
    () => [
      {
        title: 'Все объявления',
        value: data?.counts?.all_count,
        backgroundColor: '#FCE7DB',
        icon: (
          <UserIcon
            style={{
              color: '#FA8F54',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
      {
        title: 'Активные объявления',
        value: data?.counts?.active_count,
        backgroundColor: '#E0DEFF',
        icon: (
          <PromotionIcon
            style={{
              color: '#635BFF',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
      {
        title: 'Архивный объявления',
        value: data?.counts?.archived_count,
        backgroundColor: '#DEF3E5',
        icon: (
          <HouseIcon
            style={{
              color: '#58C27D',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
    ],
    [data],
  )

  return (
    <Flex vertical className="w-full">
      <Flex className="p-[24px] grid grid-cols-3 w-full" gap={24}>
        {statistics.map((item: any, index: number) => (
          <Flex
            key={item?.title}
            vertical
            className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden"
          >
            <Flex
              style={{ backgroundColor: item.backgroundColor }}
              className="w-[40px] h-[40px] items-center justify-center rounded-full"
            >
              {item?.icon}
            </Flex>

            <Flex vertical className="mt-[24px] mb-[16px] overflow-hidden">
              <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
                {item?.title}
              </Typography.Text>

              <Typography.Text className="text-[#1E1E1E] font-medium text-[32px]">
                {isLoading ? (
                  <Skeleton.Button active size="small" shape="square" block />
                ) : (
                  formatNumberWithSpaces(item?.value ?? 0)
                )}
              </Typography.Text>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex vertical className="w-full mt-[16px]">
        <Flex
          gap={10}
          className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
        >
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Владелец
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Объявление
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Опубликовано
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Тип недвижимости
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Тип объявления
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Просмотры
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Действия
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>

          <div />
        </Flex>

        {data?.placements?.results?.map((item: any) => (
          <TableRow key={item?.id} item={item} refetch={refetch}/>
        ))}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={page}
            pageSize={20}
            total={data?.placements?.count}
            showSizeChanger={false}
            onChange={(pagee) => {
              setPage(pagee)
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
