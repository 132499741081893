import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { postCategoryCreate } from '../api'

export default function AddCategory({
  open,
  setOpen,
  refetch,
}: any): JSX.Element {
  const { control, reset, watch, handleSubmit } = useForm()

  const { mutate: mutateCreate, isLoading: loaderCreate } = useMutation({
    mutationFn: postCategoryCreate,
    onSuccess: async () => {
      await refetch()
      setOpen(false)
    },
  })

  const onSubmit = () => {
    mutateCreate(watch())
  }

  return (
    <Drawer
      onClose={() => {
        setOpen(false)
      }}
      open={open}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Добавить категорию
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Название UZ" required>
              <HFInput
                placeholder="Введите название (UZ)"
                control={control}
                name="name_uz"
                required
              />
            </FRow>

            <FRow label="Название RU" required>
              <HFInput
                placeholder="Введите название (RU)"
                control={control}
                name="name_ru"
                required
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setOpen(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            loading={loaderCreate}
            onClick={handleSubmit(onSubmit)}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
