import NoteIcon from '@/components/icons/note-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import { useQuery } from '@tanstack/react-query'
import {
  Breadcrumb,
  Button,
  Flex,
  Pagination,
  Skeleton,
  Typography,
} from 'antd'
import { useState } from 'react'
import getListFaqs from '../api'
import CreateModal from '../components/CreateModal'
import TableRow from '../components/TableRow'

function FaqPage(): JSX.Element {
  const [createModal, setCreateModal] = useState(false)
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['faqs', page],
    queryFn: async () =>
      await getListFaqs({
        filters: {
          page,
        },
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Часто задаваемые вопросы
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-b-[1px]  border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Часто задаваемые вопросы
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setCreateModal(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить новый
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Flex className="w-full h-full p-[24px]">
            <Skeleton active />
          </Flex>
        ) : (
          data?.results?.map((item: any) => (
            <TableRow key={item.id} item={item} refetch={refetch} />
          ))
        )}

        <Flex className="w-max p-[24px]">
          <Pagination
            className="w-full flex justify-center"
            defaultCurrent={1}
            total={data?.count}
            pageSize={10}
            onChange={(page) => {
              setPage(page)
            }}
          />
        </Flex>
      </Flex>

      <CreateModal
        createModal={createModal}
        setCreateModal={setCreateModal}
        refetch={refetch}
      />
    </Flex>
  )
}

export default FaqPage
