import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteGlobalRole } from '../api'
import CreateRoleModal from './CreateRoleModal'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const showMessage = useMessage()
  const [createModal, setCreateModal] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)

  const { mutate: deleteRole, isLoading: loaderDelete } = useMutation({
    mutationFn: deleteGlobalRole,
    onSuccess: () => {
      refetch()
      showMessage('success', 'Роль успешно удалена')
    },
  })

  return (
    <Flex
      key={item?.id}
      className="w-full items-center grid grid-cols-[1fr_1fr_3fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Typography.Text className="text-[#635BFF] font-normal text-base">
        {item?.name}
      </Typography.Text>

      {item?.status ? (
        <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
          • Активный
        </Typography.Text>
      ) : (
        <Typography.Text className="text-[#FF5B5B] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#FED7D7] h-max w-max flex items-center gap-[2px]">
          • Неактивный
        </Typography.Text>
      )}

      <Flex className="w-full items-center flex-wrap" gap={10}>
        {item?.permission_list?.map((permission: any) => (
          <Flex
            className="p-[8px] rounded-[40px] bg-[#F1F2F4] gap-1 items-center"
            key={permission?.id}
          >
            <Typography.Text className="text-[#1E1E1E] font-medium text-base">
              {permission?.category_data?.name}:
            </Typography.Text>
            <Typography.Text className="text-[#1E1E1E] font-normal text-sm">
              {permission?.code}
            </Typography.Text>
          </Flex>
        ))}
      </Flex>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setCreateModal(true)
                setOpenPopover(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            <Button
              type="link"
              loading={loaderDelete}
              onClick={() => {
                deleteRole({ id: item?.id })
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        visible={openPopover}
        onVisibleChange={(visible) => setOpenPopover(visible)}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <CreateRoleModal
        createModal={createModal}
        setCreateModal={setCreateModal}
        refetch={refetch}
        id={item?.id}
      />
    </Flex>
  )
}
