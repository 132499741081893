import HFInput from '@/components/FormElements/HFInput'
import HFInputMask from '@/components/FormElements/HFInputMask'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import getListGlobalRoles from '@/features/roles/api'
import useMessage from '@/hooks/useShowMessage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Spin, Typography } from 'antd'
import { useForm } from 'react-hook-form'
import { createUser, getGlobalUser, updateUserWithPut } from '../api'

function CreateDrawer({
  drawerCreate,
  setDrawerCreate,
  id = 'new',
  refetch,
}: any): JSX.Element {
  const { control, reset, getValues, handleSubmit } = useForm()
  const showMessage = useMessage()

  const { isFetching: getUserByIdLoading } = useQuery({
    queryKey: ['user', id],
    queryFn: async () => await getGlobalUser(id),
    enabled: id !== 'new' && drawerCreate,
    onSuccess: (data) => {
      reset({
        ...data,
        global_role: data?.global_role_id
      })
    },
  })

  const { data: roles, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => await getListGlobalRoles({}),
  })

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      showMessage('success', 'Сотрудник успешно добавлен')
      setDrawerCreate(false)
      refetch()
    },
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateUserWithPut,
    onSuccess: () => {
      showMessage('success', 'Сотрудник успешно обновлен')
      setDrawerCreate(false)
      refetch()
    },
  })

  const onSubmit = () => {
    const values = getValues()

    if (id === 'new') {
      mutateCreate(values)
    } else {
      mutateUpdate({ ...values, id })
    }
  }

  return (
    <Drawer
      onClose={() => {
        setDrawerCreate(false)
      }}
      open={drawerCreate}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between relative">
        {getUserByIdLoading && (
          <Flex className="absolute left-0 top-0 bg-white w-full h-full z-10 items-center justify-center">
            <Spin />
          </Flex>
        )}
        <Flex vertical className="w-full">
          <Flex className="border-b-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {id === 'new'
                ? 'Добавить сотрудника'
                : 'Редактировать сотрудника'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerCreate(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Имя сотрудника">
              <HFInput
                control={control}
                name="first_name"
                placeholder="Напишите имю"
              />
            </FRow>

            <FRow label="Фамилия сотрудника">
              <HFInput
                control={control}
                name="last_name"
                placeholder="Напишите фамилию"
              />
            </FRow>

            <FRow label="Логин">
              <HFInput
                control={control}
                name="username"
                placeholder="Напишите логин"
              />
            </FRow>

            <FRow label="Пароль">
              <HFInput
                control={control}
                name="password"
                placeholder="Напишите пароль"
              />
            </FRow>

            <FRow label="Роль">
              <HFSelect
                control={control}
                loading={isLoading}
                name="global_role"
                options={roles?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Номер телефона">
              <HFInputMask control={control} name="phone" />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setDrawerCreate(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {id === 'new' ? 'Добавить' : 'Сохранить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
