import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width=""
    height=""
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11426_22704)">
      <path
        d="M12.668 8.6665L14.1009 9.09638C15.0164 9.37102 15.4741 9.50834 15.7377 9.86264C16.0013 10.2169 16.0013 10.6948 16.0013 11.6506V16.6665"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M7.33398 8L9.33398 8M7.33398 10.6667L9.33398 10.6667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0013 16.6663V14.6663C10.0013 14.0378 10.0013 13.7235 9.80604 13.5283C9.61078 13.333 9.29651 13.333 8.66797 13.333H8.0013C7.37276 13.333 7.05849 13.333 6.86323 13.5283C6.66797 13.7235 6.66797 14.0378 6.66797 14.6663L6.66797 16.6663"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 16.6665H16.6673"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M4 16.6663L4 6.47784C4 4.804 4 3.96708 4.52746 3.55184C5.05491 3.13659 5.83161 3.36204 7.38501 3.81294L10.7183 4.78049C11.6558 5.05258 12.1245 5.18863 12.3956 5.55944C12.6667 5.93025 12.6667 6.4353 12.6667 7.44539L12.6667 16.6663"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11426_22704">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)

export default function HouseIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
