import requestV3 from '@/utils/axiosV3'

export default async function getListContacts(): Promise<any> {
  const res: any = await requestV3({
    url: '/content/contact',
    method: 'get',
  })

  return res
}

export async function updateContacts(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/content/contact/${data.id}`,
    method: 'put',
    data,
  })

  return res
}

export async function createContacts(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/content/contact',
    method: 'post',
    data,
  })

  return res
}

export async function deleteContacts(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/content/contact/${id}`,
    method: 'delete',
  })

  return res
}
