import requestV3 from "@/utils/axiosV3"

export default async function getListStatistics({ filters }: any): Promise<any> {
    const res: any = await requestV3({
      url: '/home/building-st',
      method: 'get',
      params: filters,
    })
  
    return res
  }