import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49509 8.20451L5.45006 10.2431C4.78498 10.5783 4 10.0949 4 9.35014V7.21838C4 6.8549 4.19724 6.52003 4.51514 6.34379L9.46029 3.60229C9.7619 3.43508 10.1284 3.43508 10.43 3.60229L15.3751 6.34379C15.6931 6.52003 15.8903 6.85489 15.8903 7.21838V9.35014C15.8903 10.0949 15.1053 10.5783 14.4402 10.2431L10.3952 8.20451C10.1121 8.06184 9.77817 8.06184 9.49509 8.20451Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
    <path
      d="M11.9952 13.9701C11.9952 15.1189 11.0725 16.0417 9.94485 16.0417C8.81716 16.0417 7.89453 15.1189 7.89453 13.9701C7.89453 12.8213 8.81716 11.8984 9.94485 11.8984C11.0725 11.8984 11.9952 12.8213 11.9952 13.9701Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </svg>
)

export default function UrboIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
