import CloseIcon from '@/components/icons/close'
import { Flex, Modal, Typography } from 'antd'

function ReasonModal({ reasonModal, setReasonModal, user }: any): JSX.Element {
  return (
    <Modal
      open={reasonModal}
      onOk={() => {
        setReasonModal(false)
      }}
      onCancel={() => {
        setReasonModal(false)
      }}
      footer={null}
      width={500}
      height="max-content"
      closeIcon={
        <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
      }
    >
      {user?.block_users?.map((item: any) => (
        <Flex vertical className="w-full overflow-auto" key={item?.id}>
          <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] border-[1px] w-full p-[24px]">
            <Typography.Title className="!font-medium !text-2xl !m-0">
              Причина блокировки
            </Typography.Title>
          </Flex>

          <Flex vertical className="p-[24px]" gap={24}>
            {item?.block_reasons?.map((reason: any) => (
              <Typography.Text
                className="text-[#1E1E1E] font-medium text-xl"
                key={reason?.id}
              >
                {reason?.name}
              </Typography.Text>
            ))}

            <Typography.Text className="text-[#1E1E1E] font-normal text-sm">
              {item?.description}
            </Typography.Text>
          </Flex>
        </Flex>
      ))}
    </Modal>
  )
}

export default ReasonModal
