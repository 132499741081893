import { Flex, Result } from 'antd'

export default function NoPermission(): JSX.Element {
  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Result
        status="403"
        title="403"
        subTitle="У вас нет прав на просмотр этой страницы"
      />
    </Flex>
  )
}
