/* eslint-disable @typescript-eslint/no-floating-promises */
import HFPassword from '@/components/FormElements/HFPassword'
import FRow from '@/components/FormElements/HFRow'
import LockKeyIcon from '@/components/icons/lock-key'
import LogoDark from '@/components/icons/logo-dark'
import LogoLight from '@/components/icons/logo-light'
import PowerIcon from '@/components/icons/power-icon'
import UserCircleIcon from '@/components/icons/user-circle'
import { useAuthContext } from '@/contexts'
import useMessage from '@/hooks/useShowMessage'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  Popover,
  Switch,
  Typography,
} from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import DefaultAvatar from '../assets/default-avatar.svg'
import updatePassword from './api'

function Header(): JSX.Element {
  const [open, setOpen] = useState(false)
  const [openModalUpdatePassword, setOpenModalUpdatePassword] = useState(false)
  const { control, getValues, watch } = useForm()
  const showMessage = useMessage()
  const { setIsAuth } = useAuthContext()
  const [isDarkMode, setIsDarkMode] = useState(false)
  const navigate = useNavigate()
  const hide = (): void => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const toggleDarkMode = (): void => {
    setIsDarkMode(!document.body.classList.contains('dark'))
    document.body.classList.toggle('dark')
    localStorage.setItem(
      'theme',
      document.body.classList.contains('dark') ? 'dark' : 'light',
    )
  }

  const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdate } =
    useMutation({
      mutationFn: updatePassword,
      onSuccess: () => {
        showMessage('success', 'Пароль успешно обновлен')
        setOpenModalUpdatePassword(false)
      },
    })

  return (
    <>
      <Flex className="p-[16px_24px] z-10 bg-[#FFF] h-auto w-full items-center justify-between border-b border-x-0 border-t-0 border-solid border-[#EEEEEE]">
        <Flex
          className="items-center h-full cursor-pointer"
          onClick={() => {
            navigate('/')
          }}
        >
          <LogoLight />
        </Flex>

        <Flex gap={16} className="items-center">
          <Popover
            content={
              <Flex vertical className="min-w-[290px]">
                <Button
                  type="link"
                  onClick={() => {
                    navigate('/profile')
                    hide()
                  }}
                  className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start"
                >
                  <UserCircleIcon
                    style={{
                      color: '#1E1E1E',
                    }}
                  />
                  Аккаунт
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    setOpenModalUpdatePassword(true)
                    hide()
                  }}
                  className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start"
                >
                  <LockKeyIcon />
                  Изменить пароль
                </Button>
                <Divider className="m-0" />
                {/* <Button
                  type="link"
                  className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] flex items-center justify-between"
                >
                  Темный режим
                  <Switch
                    onChange={() => {
                      toggleDarkMode()
                    }}
                  />
                </Button>
                <Divider className="m-0" /> */}
                <Button
                  type="link"
                  onClick={() => {
                    setIsAuth(false)
                    navigate('/login')
                    hide()
                  }}
                  className="p-[16px] text-start h-max text-[#1E1E1E] hover:!text-[#1E1E1E] hover:!bg-[#F1F2F4] flex items-center justify-start"
                >
                  <PowerIcon />
                  Выйти с аккаунта
                </Button>
              </Flex>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button
              type="link"
              className="p-0 overflow-hidden rounded-full h-[46px] w-[46px] flex items-center justify-center"
            >
              <Image src={DefaultAvatar} preview={false} />
            </Button>
          </Popover>
        </Flex>
      </Flex>

      <Modal
        width={500}
        footer={null}
        centered
        open={openModalUpdatePassword}
        onOk={() => {
          setOpenModalUpdatePassword(false)
        }}
        onCancel={() => {
          setOpenModalUpdatePassword(false)
        }}
        className="custom-modal-rc"
      >
        <Flex
          vertical
          gap={24}
          className="border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex className="w-full items-center justify-center">
            {isDarkMode ? <LogoDark /> : <LogoLight />}
          </Flex>

          <Flex vertical className="items-center">
            <Typography.Title className="!text-2xl !font-medium m-0 mb-[10px] text-[#1E1E1E] dark:!text-[#FFF]">
              Обновить пароль
            </Typography.Title>
            <Typography.Paragraph className="text-center text-base font-normal text-[#878787] dark:!text-[#FFF]">
              Придумайте надежный пароль, состоящий из букв, цифр и других
              символов
            </Typography.Paragraph>
          </Flex>

          <Flex vertical gap={24}>
            <FRow label="Ваш пароль">
              <HFPassword
                control={control}
                name="password1"
                placeholder="Введите ваш пароль"
              />
            </FRow>

            <FRow label="Потвердить пароль">
              <HFPassword
                control={control}
                name="password2"
                placeholder="Потвердите ваш пароль"
              />
            </FRow>
          </Flex>

          <Flex>
            <Button
              loading={isLoadingUpdate}
              disabled={watch('password1') !== watch('password2')}
              onClick={() => {
                mutateUpdatePassword({
                  password1: getValues('password1'),
                  password2: getValues('password2'),
                })
              }}
              className="rounded-[8px] bg-[#635BFF] text-[#FFFFFF] font-medium text-base w-full h-auto py-[10px]"
            >
              Обновить пароль
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default Header
