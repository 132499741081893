import {
  QueryProvider,
  AuthProvider,
  RouteProvider as Routes,
  LangProvider,
  ThemeProvider,
} from "./providers";

export default function App(): React.ReactElement {
  return (
    <LangProvider>
      <QueryProvider>
        <AuthProvider>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </AuthProvider>
      </QueryProvider>
    </LangProvider>
  );
}
