import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99957 10.5L6 6.5M6.00043 10.5L10 6.5"
      stroke="#CE5A67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6673 8.50033C14.6673 4.81843 11.6825 1.83366 8.00065 1.83366C4.31875 1.83366 1.33398 4.81843 1.33398 8.50033C1.33398 12.1822 4.31875 15.167 8.00065 15.167C11.6825 15.167 14.6673 12.1822 14.6673 8.50033Z"
      stroke="#CE5A67"
    />
  </svg>
)

export default function IgnoreIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
