import requestV3 from '@/utils/axiosV3'
import requestV3WithoutLang from '@/utils/axiosV3WithoutLang'

export default async function getListSeoSingleTemplates({
  filters,
  lang,
}: any): Promise<any> {
  const res: any = await requestV3WithoutLang({
    url: '/seo/single-templates',
    method: 'get',
    params: filters,
    headers: {
      'Accept-Language': lang,
    },
  })

  return res
}

export async function createSeoSingleTemplate(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/seo/single-template-create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateSeoSingleTemplate(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/seo/single-template-update/${data?.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function deleteSeoSingleTemplate(id: any): Promise<any> {
  const res: any = await requestV3({
    url: `/seo/single_template/${id}`,
    method: 'delete',
  })

  return res
}

export async function getSeoSingleTemplateById({ item }: any): Promise<any> {
  const res: any = await requestV3WithoutLang({
    url: `/seo/single_template/${item}`,
    method: 'get',
  })

  return res
}
