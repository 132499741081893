import { Empty, Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, refetch, setPage }: any): JSX.Element {
  if (data?.count === 0) {
    return (
      <Flex className="w-full justify-center items-center p-[24px]">
        <Empty description="Нет данных" />
      </Flex>
    )
  }

  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px] overflow-x-scroll">
        <Flex
          gap={10}
          className="w-full flex-nowrap border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
        >
          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Название шаблона
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Город
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Район
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Раздел
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Подраздел
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Title
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Descripption
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Keywords
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og title
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og description
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og URL
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og type
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og site name
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base w-[350px] min-w-[350px]">
            Og image
          </Typography.Text>

          <div className="w-[100px] min-w-[100px]" />
        </Flex>

        {data?.results?.map((item: any) => (
          <TableRow item={item} refetch={refetch} />
        ))}
      </Flex>

      <Flex className="mt-[24px] pb-[24px] pl-[24px]">
        <Pagination
          defaultCurrent={1}
          total={data?.count}
          showSizeChanger={false}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </Flex>
  )
}

export default Table
