import { DatePicker, Flex, Typography } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'

function HFDatePicker({
  control,
  name = '',
  disabledHelperText = false,
  required = false,
  rules = {},
  ...props
}: any): JSX.Element {
  const convertDateStringToDayjsObject = (dateString: string): any =>
    dayjs(dateString)

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: required ? 'Это объязательная поля!' : false,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Flex vertical className='w-full'>
          <DatePicker
            value={value ? convertDateStringToDayjsObject(value) : null}
            onChange={(date: any, dateString: string) => {
              onChange(dateString)
            }}
            status={error ? 'error' : 'success'}
            className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
            {...props}
          />

          {error && !disabledHelperText && (
            <Typography.Text type="danger">{error.message}</Typography.Text>
          )}
        </Flex>
      )}
    />
  )
}

export default HFDatePicker
