/* eslint-disable @tanstack/query/no-deprecated-options */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import useMessage from '@/hooks/useShowMessage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Spin, Typography } from 'antd'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import {
  createSeoGeneralTemplate,
  getChaptersWithSubChapters,
  getRegionsWithDistricts,
  getSeoGeneralTemplateById,
  updateSeoGeneralTemplate,
} from '../api'
import OgImageRu from './OgImageRu'
import OgImageUz from './OgImageUz'

function CreateDrawer({
  createDrawer,
  setCreateDrawer,
  refetch,
  item = 'new',
}: any): JSX.Element {
  const { control, reset, setValue, watch } = useForm()
  const [activeTab, setActiveTab] = useState('ru')
  const showMessage = useMessage()

  const { isFetching } = useQuery({
    queryKey: ['seo-general-id-get', item],
    queryFn: () => getSeoGeneralTemplateById({ item }),
    cacheTime: 0,
    enabled: item !== 'new' && createDrawer,
    onSuccess: (data) => {
      reset({
        ...data,
        name_uz: data?.translations?.uz?.name,
        name_ru: data?.translations?.ru?.name,
        title_uz: data?.translations?.uz?.title,
        title_ru: data?.translations?.ru?.title,
        description_uz: data?.translations?.uz?.description,
        description_ru: data?.translations?.ru?.description,
        keywords_uz: data?.translations?.uz?.keywords,
        keywords_ru: data?.translations?.ru?.keywords,
        og_title_uz: data?.translations?.uz?.og_title,
        og_title_ru: data?.translations?.ru?.og_title,
        og_description_uz: data?.translations?.uz?.og_description,
        og_description_ru: data?.translations?.ru?.og_description,
        og_url_uz: data?.translations?.uz?.og_url,
        og_url_ru: data?.translations?.ru?.og_url,
        og_type_uz: data?.translations?.uz?.og_type,
        og_type_ru: data?.translations?.ru?.og_type,
        og_site_name_uz: data?.translations?.uz?.og_site_name,
        og_site_name_ru: data?.translations?.ru?.og_site_name,
        og_image_uz: data?.translations?.uz?.og_image,
        og_image_ru: data?.translations?.ru?.og_image,
        region: data?.region_id,
        district: data?.district_id,
        chapter: data?.chapter_id,
        sub_chapter: data?.sub_chapter_id,
      })
    },
  })

  const { mutate: mutateCreateSeoSingleTemplate, isLoading: loaderCreate } =
    useMutation({
      mutationFn: createSeoGeneralTemplate,
      onSuccess: () => {
        refetch()
        setCreateDrawer(false)
        showMessage('success', 'Шаблон успешно добавлен')
      },
    })

  const { mutate: mutateUpdateSeoSingleTemplate, isLoading: loaderUpdate } =
    useMutation({
      mutationFn: updateSeoGeneralTemplate,
      onSuccess: () => {
        refetch()
        setCreateDrawer(false)
        showMessage('success', 'Шаблон успешно обновлен')
      },
    })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const watchingImageUz = useWatch({
    control,
    name: 'og_image_uz',
  })

  const watchingImageRu = useWatch({
    control,
    name: 'og_image_ru',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const onSubmit = async (): void => {
    let imageFileRu = watchingImageRu
    let imageFileUz = watchingImageUz

    if (isValidURL(imageFileRu)) {
      imageFileRu = await urlToFile(imageFileRu)
    }

    if (isValidURL(imageFileUz)) {
      imageFileUz = await urlToFile(imageFileUz)
    }

    if (item === 'new') {
      mutateCreateSeoSingleTemplate({
        ...watch(),
      })
    } else {
      mutateUpdateSeoSingleTemplate({
        ...watch(),
        og_image_ru: imageFileRu,
        og_image_uz: imageFileUz,
      })
    }
  }

  const { data: regions, isLoading: regionsLoading } = useQuery({
    queryKey: ['regions', watch('region')],
    queryFn: () => getRegionsWithDistricts(),
  })

  const { data: chapters, isLoading: chaptersLoading } = useQuery({
    queryKey: ['chapters'],
    queryFn: () => getChaptersWithSubChapters(),
  })

  return (
    <Drawer
      onClose={() => {
        setCreateDrawer(false)
      }}
      open={createDrawer}
      headerStyle={{
        display: 'none',
      }}
      width={600}
    >
      <Flex vertical className="w-full">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-[1px] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {item === 'new' ? 'Добавить' : 'Редактировать'} шаблон
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setCreateDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            {isFetching && (
              <Flex className="w-full h-full bg-white absolute items-center justify-center z-10">
                <Spin size="large" />
              </Flex>
            )}
            <FRow label="Выберите язык">
              <Flex className="w-full py-[16px]">
                <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-full">
                  <Button
                    style={{
                      backgroundColor: activeTab === 'ru' ? '#FFF' : '#F1F2F4',
                    }}
                    onClick={() => {
                      setActiveTab('ru')
                    }}
                    className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                  >
                    RU
                  </Button>

                  <Button
                    onClick={() => {
                      setActiveTab('uz')
                    }}
                    style={{
                      backgroundColor: activeTab === 'uz' ? '#FFF' : '#F1F2F4',
                    }}
                    className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto w-full"
                  >
                    UZ
                  </Button>
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Название шаблона">
              <HFInput
                key={activeTab}
                control={control}
                name={`name_${activeTab}`}
              />
            </FRow>

            <FRow label="Город">
              <HFSelect
                key={activeTab}
                control={control}
                loading={regionsLoading}
                name="region"
                options={regions?.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Район">
              <HFSelect
                key={activeTab}
                control={control}
                loading={regionsLoading}
                name="district"
                options={regions
                  ?.find((item: any) => item.id === watch('region'))
                  ?.districts?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
              />
            </FRow>

            <FRow label="Раздел">
              <HFSelect
                key={activeTab}
                control={control}
                loading={chaptersLoading}
                name="chapter"
                options={chapters?.results?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Подраздел">
              <HFSelect
                key={activeTab}
                control={control}
                name="sub_chapter"
                loading={chaptersLoading}
                options={
                  chapters?.results
                    ?.find((item: any) => item.id === watch('chapter'))
                    ?.subseo_chapters?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) || []
                }
              />
            </FRow>

            <FRow label="Title">
              <HFInput
                key={activeTab}
                control={control}
                name={`title_${activeTab}`}
              />
            </FRow>
            <FRow label="Description">
              <HFInput
                key={activeTab}
                control={control}
                name={`description_${activeTab}`}
              />
            </FRow>
            <FRow label="Keywords">
              <HFInput
                key={activeTab}
                control={control}
                name={`keywords_${activeTab}`}
              />
            </FRow>
            <FRow label="Og title">
              <HFInput
                key={activeTab}
                control={control}
                name={`og_title_${activeTab}`}
              />
            </FRow>
            <FRow label="Og description">
              <HFInput
                key={activeTab}
                control={control}
                name={`og_description_${activeTab}`}
              />
            </FRow>
            <FRow label="Og URL">
              <HFInput
                key={activeTab}
                control={control}
                name={`og_url_${activeTab}`}
              />
            </FRow>
            <FRow label="Og type">
              <HFInput
                key={activeTab}
                control={control}
                name={`og_type_${activeTab}`}
              />
            </FRow>
            <FRow label="Og site name">
              <HFInput
                key={activeTab}
                control={control}
                name={`og_site_name_${activeTab}`}
              />
            </FRow>

            {activeTab === 'ru' ? (
              <OgImageRu control={control} setValue={setValue} />
            ) : (
              <OgImageUz control={control} setValue={setValue} />
            )}
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setCreateDrawer(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={onSubmit}
            loading={loaderCreate || loaderUpdate}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {item === 'new' ? 'Добавить' : 'Редактировать'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
