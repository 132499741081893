import { useQuery } from '@tanstack/react-query'
import { Checkbox, Divider, Flex, Typography } from 'antd'
import {
  getListExteriorFeatures,
  getListInteriorFeatures,
  getListSafetySystem,
} from '../../api'

function ConvenienceSection({ setValue, watch }: any): JSX.Element {
  const { data: listInteriorFeatures } = useQuery({
    queryKey: ['listInteriorFeatures'],
    queryFn: getListInteriorFeatures,
  })

  const { data: listSafetySystem } = useQuery({
    queryKey: ['listSafetySystem'],
    queryFn: getListSafetySystem,
  })

  const { data: listExteriorFeatures } = useQuery({
    queryKey: ['listExteriorFeatures'],
    queryFn: getListExteriorFeatures,
  })

  return (
    <section id="characteristics">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#2F3336]"
      >
        <Flex className="items-center justify-between pb-[24px] border-x-0 border-t-0 border-[#E5E7EB] border-solid dark:border-[#2F3336]">
          <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
            Удобство ЖК
          </Typography.Title>
        </Flex>

        <Flex vertical gap={24} className="pt-[24px]">
          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Особенности интерьера
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {listInteriorFeatures?.length > 0 ? (
                <Checkbox.Group
                  options={
                    listInteriorFeatures?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('interior_features')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('interior_features', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          <Divider className="m-0" />

          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Система безопасности
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {listSafetySystem?.length > 0 ? (
                <Checkbox.Group
                  options={
                    listSafetySystem?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('safety_systems')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('safety_systems', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          <Divider className="m-0" />

          <Flex vertical gap={24}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] text-xl font-medium dark:!text-[#FFF]">
                Особенности экстерьера
              </Typography.Text>

              <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                Можно выбрать: минимум 1 и максимум 10
              </Typography.Text>
            </Flex>

            <Flex>
              {listExteriorFeatures?.length > 0 ? (
                <Checkbox.Group
                  options={
                    listExteriorFeatures?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    })) ?? []
                  }
                  value={watch('exterior_features')}
                  className="w-full grid grid-cols-4 gap-[16px]"
                  onChange={(values) => {
                    setValue('exterior_features', values)
                  }}
                />
              ) : (
                <Typography.Text className="text-[#878787] text-sm font-normal dark:!text-[#878787]">
                  Нет данных
                </Typography.Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </section>
  )
}

export default ConvenienceSection
