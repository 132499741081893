/* eslint-disable @typescript-eslint/no-floating-promises */
import HFInput from '@/components/FormElements/HFInput'
import HFPassword from '@/components/FormElements/HFPassword'
import FRow from '@/components/FormElements/HFRow'
import { useAuthContext } from '@/contexts'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Typography } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { login } from '../api'
import LogoImage from '../assets/logo.svg'

function Login(): JSX.Element {
  const { control, getValues, handleSubmit } = useForm()
  const { setIsAuth } = useAuthContext()
  const navigate = useNavigate()
  const showMessage = useMessage()

  const { mutate: mutateLogin, isLoading: isLoadingLogin } = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      localStorage.removeItem('is_superuser')
      localStorage.removeItem('permissions')
      if (res?.is_superuser) {
        localStorage.setItem('is_superuser', res.is_superuser)
        localStorage.setItem('permissions', [])
      } else {
        localStorage.setItem('permissions', res.global_role_permission)
      }
      localStorage.setItem('refresh_token', res.refresh)
      localStorage.setItem('access_token', res.access)
      setIsAuth(true)
      navigate('/')
      queryClient.refetchQueries()
    },
    onError: async (error: Error) => {
      console.error(error)
      await showMessage(
        'error',
        'Ошибка при входе в систему. Пожалуйста, проверьте введенные данные.',
      )
    },
  })

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      mutateLogin({
        username: getValues('username'),
        password: getValues('password'),
      })
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  return (
    <Flex className="bg-[#FCFCFD] h-[100vh] items-center justify-center">
      <Flex
        vertical
        className="bg-[#FFF] w-[500px] h-max border rounded-2xl border-[#E5E7EB] border-solid p-[40px]"
      >
        <Image height={40} preview={false} src={LogoImage} />

        <Flex vertical className="items-center mt-[24px]">
          <Typography.Title className="!text-2xl !font-medium m-0 mb-[10px] text-[#1E1E1E]">
            Добро пожаловать!
          </Typography.Title>
          <Typography.Paragraph className="text-center text-base font-normal mb-[24px] text-[#878787]">
            Войдите в свой аккаунт
          </Typography.Paragraph>
        </Flex>

        <Flex vertical gap={24}>
          <FRow label="Ваш логин">
            <HFInput
              control={control}
              name="username"
              placeholder="Введите ваш логин"
              required
            />
          </FRow>

          <FRow label="Ваш пароль">
            <HFPassword
              control={control}
              name="password"
              placeholder="Введите ваш пароль"
              required
            />
          </FRow>
        </Flex>

        <Flex className="mt-[24px]">
          <Button
            onClick={() => {
              handleSubmit(
                mutateLogin({
                  username: getValues('username'),
                  password: getValues('password'),
                }),
              )()
            }}
            loading={isLoadingLogin}
            className="rounded-[8px] bg-[#635BFF] text-[#FFFFFF] font-medium text-base w-full h-auto py-[10px]"
          >
            Войти
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Login
