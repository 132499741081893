/* eslint-disable no-return-await */
import AnalyticsDownIcon from '@/components/icons/analytics-down'
import AnalyticsUpIcon from '@/components/icons/analytics-up'
import HouseIcon from '@/components/icons/house-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import UserIcon from '@/components/icons/user'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'
import { useQuery } from '@tanstack/react-query'
import { Flex, Pagination, Skeleton, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { getListRealtors } from '../../api'
import TableRow from './TableRow'

export default function RealtorsTable(): JSX.Element {
  const [page, setPage] = useState(1)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['home/realtors', page],
    queryFn: async () => await getListRealtors({ filters: { page } }),
  })

  const statistics = useMemo(
    () => [
      {
        title: 'Все риэлторы',
        value: data?.user_counts?.all_users,
        lastMonthValue: 30,
        percent: 10.7,
        icon: (
          <UserIcon
            style={{
              color: '#FA8F54',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#FCE7DB',
      },
      {
        title: 'Подтвержденные риелторы',
        value: data?.user_counts?.user_active_count,
        lastMonthValue: 30,
        percent: 10.7,
        icon: (
          <PromotionIcon
            style={{
              color: '#635BFF',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#E0DEFF',
      },
      {
        title: 'Неподтвержденные риелторы',
        value: data?.user_counts?.user_inactive_count,
        lastMonthValue: 1000,
        percent: 3.3,
        icon: (
          <HouseIcon
            style={{
              color: '#58C27D',
              width: '24px',
              height: '24px',
            }}
          />
        ),
        backgroundColor: '#DEF3E5',
      },
    ],
    [data],
  )

  return (
    <Flex vertical className="w-full pb-[24px]">
      <Flex className="py-[24px] grid grid-cols-3 w-full" gap={24}>
        {statistics.map((item: any, index: number) => (
          <Flex
            key={item.title}
            vertical
            className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden"
          >
            <Flex
              style={{
                backgroundColor: item.backgroundColor,
              }}
              className="w-[40px] h-[40px] items-center justify-center rounded-full"
            >
              {item.icon}
            </Flex>

            <Flex vertical className="mt-[24px] mb-[16px] overflow-hidden">
              <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
                {item.title}
              </Typography.Text>

              {isLoading ? (
                <Skeleton.Button active size="small" shape="square" block />
              ) : (
                <Typography.Text className="text-[#1E1E1E] font-medium text-[32px]">
                  {formatNumberWithSpaces(item.value)}
                </Typography.Text>
              )}
            </Flex>

            <Flex
              gap={8}
              className="no-wrap items-center statistic-row overflow-hidden"
            >
              <Typography.Text className="text-[#1E1E1E] font-medium text-sm text-nowrap">
                {formatNumberWithSpaces(item.lastMonthValue)}
              </Typography.Text>

              <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
                чем в прошлом месяце
              </Typography.Text>

              {item.value > item.lastMonthValue ? (
                <AnalyticsUpIcon />
              ) : (
                <AnalyticsDownIcon />
              )}

              <Typography.Text
                className={`text-sm font-medium text-nowrap ${
                  item.value > item.lastMonthValue
                    ? 'text-[#58C27D]'
                    : 'text-[#CE5A67]'
                }`}
              >
                {item.percent}%
              </Typography.Text>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Flex vertical className="w-full mt-[16px]">
        <Flex
          gap={12}
          className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
        >
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Регион
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Фотография паспорта
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Фотография сертификата
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>

          <div />
        </Flex>

        {isLoading ? (
          <Skeleton active />
        ) : (
          data?.user_list?.results?.map((user: any) => (
            <TableRow key={user?.id} user={user} refetch={refetch} />
          ))
        )}

        <Flex className="mt-[24px]">
          <Pagination
            defaultCurrent={1}
            total={data?.user_list?.count}
            pageSize={20}
            onChange={(e) => {
              setPage(e)
            }}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
