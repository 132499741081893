import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.33301"
      y="1.66663"
      width="14.5833"
      height="16.6667"
      rx="4"
      stroke="currentColor"
    />
    <path
      d="M8.82503 11.4506C8.30104 11.8016 6.92717 12.5183 7.76395 13.4152C8.17271 13.8533 8.62796 14.1666 9.20032 14.1666H12.4663C13.0387 14.1666 13.494 13.8533 13.9027 13.4152C14.7395 12.5183 13.3656 11.8016 12.8416 11.4506C11.6129 10.6275 10.0538 10.6275 8.82503 11.4506Z"
      stroke="currentColor"
    />
    <path
      d="M12.5 7.49992C12.5 8.42039 11.7538 9.16659 10.8333 9.16659C9.91286 9.16659 9.16667 8.42039 9.16667 7.49992C9.16667 6.57944 9.91286 5.83325 10.8333 5.83325C11.7538 5.83325 12.5 6.57944 12.5 7.49992Z"
      stroke="currentColor"
    />
    <path
      d="M4.16634 5L2.08301 5M4.16634 10L2.08301 10M4.16634 15H2.08301"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function ContactsIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
