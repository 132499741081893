import { Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

function MenuButton({ element }: any): JSX.Element {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  function getLastString(inputString: string): string {
    const parts = inputString.split('/')
    const lastString = parts[parts.length - 1]
    return lastString
  }

  return (
    <Button
      onClick={() => {
        navigate(element.link)
      }}
      className={`${
        getLastString(pathname) === element.id
          ? '!text-[#635BFF] !bg-[#F1F2F4]'
          : ''
      } p-[9px] rounded-[12px] flex items-center h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left`}
    >
      {element.icon}
      {element.name}
    </Button>
  )
}

export default MenuButton
