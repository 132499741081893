import BlurImage from '@/components/BlurImage'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import GalleryIcon from '@/components/icons/gallery-icon'
import useMessage from '@/hooks/useShowMessage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { createService, getService, updateService } from '../api'

function CreateDrawer({
  createDrawer,
  setCreateDrawer,
  refetch,
  id = null,
}: any): JSX.Element {
  const { control, reset, handleSubmit, setValue } = useForm()
  const showMessage = useMessage()

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    createService,
    {
      onSuccess: () => {
        setCreateDrawer(false)
        refetch()
        showMessage('success', 'Услуга успешно добавлена')
      },
    },
  )

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    updateService,
    {
      onSuccess: () => {
        setCreateDrawer(false)
        refetch()
        showMessage('success', 'Услуга успешно обновлена')
      },
    },
  )

  const { isFetching } = useQuery({
    queryKey: ['service-by-id', id],
    queryFn: async () => await getService(id),
    enabled: !!id && createDrawer,
    onSuccess: (data) => {
      reset({
        ...data,
        short_desc_uz: data?.translations?.uz?.short_desc,
        short_desc_ru: data?.translations?.ru?.short_desc,
        title_uz: data?.translations?.uz?.title,
        title_ru: data?.translations?.ru?.title,
      })
    },
  })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const onSubmit = async (data: any) => {
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    if (id) {
      mutateUpdate({ id, ...data, icon: imageFile })
    } else {
      mutateCreate(data)
    }
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('icon', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'icon',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  return (
    <Drawer
      onClose={() => {
        setCreateDrawer(false)
        reset({})
      }}
      width={400}
      open={createDrawer}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full justify-between h-full relative">
        {isFetching && (
          <Flex className="w-full h-full p-[24px] items-center justify-center absolute top-0 left-0 z-10 bg-white">
            <Spin size="large" />
          </Flex>
        )}
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              {id ? 'Редактировать услугу' : 'Добавить услугу'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setCreateDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <input type="file" hidden />

            <FRow label="Иконка услуги">
              <Flex>
                {imageUrlGenerator ? (
                  <Flex className="w-[100px] min-w-[100px] h-[100px] rounded-full overflow-hidden border-dashed border-[#E5E7EB] border-[1.5px] items-center justify-center">
                    <BlurImage src={imageUrlGenerator} />
                  </Flex>
                ) : (
                  <Flex className="w-[100px] min-w-[100px] h-[100px] rounded-full overflow-hidden border-dashed border-[#E5E7EB] border-[1.5px] items-center justify-center">
                    <GalleryIcon />
                  </Flex>
                )}

                <Flex gap={16} className="p-[24px] w-full">
                  <Button
                    onClick={inputClick}
                    className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
                  >
                    {imageUrlGenerator ? 'Изменить' : 'Выбрать'}
                  </Button>

                  {imageUrlGenerator && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('icon', null)
                      }}
                      className="border-[#E5E7EB] text-[#CE5A67] rounded-[8px] w-full h-[48px] flex items-center gap-[10px]"
                    >
                      Удалить
                    </Button>
                  )}
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Название услуги UZ">
              <HFInput control={control} name="title_uz" required />
            </FRow>

            <FRow label="Название услуги RU">
              <HFInput control={control} name="title_ru" required />
            </FRow>

            <FRow label="Подробный текст об услуге UZ">
              <HFTextarea
                control={control}
                required
                name="short_desc_uz"
                placeholder="Напишите подробный текст об услуге"
                rows={6}
              />
            </FRow>

            <FRow label="Подробный текст об услуге RU">
              <HFTextarea
                control={control}
                required
                name="short_desc_ru"
                placeholder="Напишите подробный текст об услуге"
                rows={6}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setCreateDrawer(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            loading={isLoadingCreate || isLoadingUpdate}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            {id ? 'Сохранить' : 'Добавить'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateDrawer
