import requestV3 from '@/utils/axiosV3'
import requestV3WithoutLang from '@/utils/axiosV3WithoutLang'

export default async function getListSeoGeneralTemplates({
  filters,
  lang,
}: any): Promise<any> {
  const res: any = await requestV3WithoutLang({
    url: '/seo/general-seos',
    method: 'get',
    params: filters,
    headers: {
      'Accept-Language': lang,
    },
  })

  return res
}

export async function createSeoGeneralTemplate(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/seo/general-seo-create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function updateSeoGeneralTemplate(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/seo/general-seo-update/${data?.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })

  return res
}

export async function deleteSeoGeneralTemplate(id: any): Promise<any> {
  const res: any = await requestV3({
    url: `/seo/general-seo/${id}`,
    method: 'delete',
  })

  return res
}

export async function getSeoGeneralTemplateById({ item }: any): Promise<any> {
  const res: any = await requestV3WithoutLang({
    url: `/seo/general-seo/${item}`,
    method: 'get',
  })

  return res
}

export async function getRegionsWithDistricts(): Promise<any> {
  const res: any = await requestV3({
    url: '/base/regions',
    method: 'get',
  })

  return res
}

export async function getChaptersWithSubChapters(): Promise<any> {
  const res: any = await requestV3({
    url: '/seo/seo-chapters',
    method: 'get',
  })

  return res
}