import request from '@/utils/axios';
import requestV3 from '@/utils/axiosV3'

export default async function getListOffers({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/offers',
    method: 'get',
    params: filters,
  })

  return res
}

function flattenObject(
  obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any },
  prefix = '',
): any[] {
  let items: any[] = []

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      let newKey = ''

      if (prefix === 'nearby_metro') {
        newKey = prefix ? `${prefix}.${key}` : key
      } else {
        newKey = prefix ? `${prefix}${key}` : key
      }

      if (
        value &&
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value instanceof File) &&
        !(value instanceof Blob)
      ) {
        items = items.concat(flattenObject(value, newKey))
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          // For arrays, use indexed format (tag[0], tag[1], etc.)
          if (item && typeof item === 'object' && !Array.isArray(item)) {
            items = items.concat(flattenObject(item, `${newKey}[${index}]`))
          } else {
            items.push({ key: `${newKey}[${index}]`, value: item })
          }
        })
      } else {
        items.push({ key: newKey, value })
      }
    }
  }

  return items
}


function appendToFormData(formData: FormData, data: any): void {
  const flattened = flattenObject(data)
  flattened.forEach(({ key, value }: any) => {
    if (value instanceof File || value instanceof Blob) {
      formData.append(key, value)
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !(value instanceof Array)
    ) {
      formData.append(key, JSON.stringify(value))
    } else {
      formData.append(key, value)
    }
  })
}

export async function createSpecialOffer(data: any): Promise<any> {
  const formData = new FormData()

  appendToFormData(formData, data)

  const res: any = await requestV3({
    url: '/home/offer-create',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  return res
}

export async function updateSpecialOffer(data: any): Promise<any> {
  const formData = new FormData()

  appendToFormData(formData, data)

  const res: any = await requestV3({
    url: `/home/offer-detail/${data.id}`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  })

  return res
}

export async function getSpecialOffer(id: number): Promise<any> {
  const res: any = await requestV3({
    url: `/home/offer-detail/${id}`,
    method: 'get',
  })

  return res
}

export async function deleteSpecialOffer(id: number): Promise<any> {
  const res: any = await requestV3({
    url: `/home/offer-detail/${id}`,
    method: 'delete',
  })

  return res
}

export async function getRoomCount(): Promise<any> {
  const res: any = await request({
    url: '/building/room-count',
    method: 'get',
  })

  return res
}