/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import BlurImage from '@/components/BlurImage'
import BlockUserIcon from '@/components/icons/block-user'
import DoneIcon from '@/components/icons/done-icon'
import NotVerifiedIcon from '@/components/icons/not-verified'
import ReasonBlockIcon from '@/components/icons/reason-block'
import UnBlockUserIcon from '@/components/icons/unblock-user'
import VerifiedIcon from '@/components/icons/verified-icon'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { unBlockUser } from '../../api'
import BlockModal from '../UsersAll/BlockModal'
import ReasonModal from '../UsersAll/ReasonModal'
import ConfirmVerifyModal from './ConfirmVerifyModal'
import defaultAvatar from '../../assets/default-avatar.svg'
import useMessage from '@/hooks/useShowMessage'

export default function TableRow({ user, refetch }: any): JSX.Element {
  const [reasonModal, setReasonModal] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateUnBlockUser, isLoading: loaderUnBlocking } =
    useMutation({
      mutationFn: unBlockUser,
      onSuccess: () => {
        showMessage('success', 'Пользователь успешно разблокирован')
        refetch()
      },
      onError: () => {
        showMessage('error', 'Ошибка')
      },
    })

  return (
    <Flex
      gap={12}
      className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Flex className="items-center" gap={8}>
        <Flex className="w-[46px] !h-[46px] overflow-hidden rounded-full border-1 border-solid border-[#E5E7EB]">
          <BlurImage
            preview={false}
            src={user?.avatar || defaultAvatar}
            alt="user"
            className="!w-full !h-full object-cover"
          />
        </Flex>
        <Typography.Text className="text-[#1E1E1E] font-normal text-base">
          {`${user?.first_name} ${user?.last_name}`}
        </Typography.Text>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        region
      </Typography.Text>

      <Flex
        className="items-center p-[12px] w-max rounded-[12px] border-solid border border-[#F1F2F4] bg-[#FCFCFD]"
        gap={16}
      >
        <Flex className="w-[38px] !h-[38px] overflow-hidden">
          <BlurImage
            src={user?.realtor_passport}
            alt="user"
            className="!w-full !h-full rounded-[8px] object-cover"
          />
        </Flex>
        <Flex vertical>
          <Typography.Text className="text-[#1F2937] font-medium text-base">
            passport.jpeg
          </Typography.Text>
          <Typography.Text className="text-[#6B7280] font-normal text-sm">
            7 KB
          </Typography.Text>
        </Flex>
      </Flex>

      <Flex
        className="items-center p-[12px] w-max rounded-[12px] border-solid border border-[#F1F2F4] bg-[#FCFCFD]"
        gap={16}
      >
        <Flex className="w-[38px] !h-[38px] overflow-hidden">
          <BlurImage
            src={user?.realtor_sertificate}
            alt="user"
            className="!w-full !h-full rounded-[8px] object-cover"
          />
        </Flex>
        <Flex vertical>
          <Typography.Text className="text-[#1F2937] font-medium text-base">
            passport.jpeg
          </Typography.Text>
          <Typography.Text className="text-[#6B7280] font-normal text-sm">
            7 KB
          </Typography.Text>
        </Flex>
      </Flex>

      <Flex className="items-center" gap={12}>
        <Flex
          gap={5}
          className="font-normal text-xs rounded-[14px] h-max w-max flex items-center p-[6px_10px]"
          style={{
            backgroundColor: user?.is_verified_realtor ? '#DEF3E5' : '#F3DCDF',
            color: user?.is_verified_realtor ? '#58C27D !important' : '#CE5A67',
          }}
        >
          {user?.is_verified_realtor ? <VerifiedIcon /> : <NotVerifiedIcon />}
          <Typography.Text
            style={{
              color: user?.is_verified_realtor ? '#58C27D' : '#CE5A67',
            }}
          >
            {user?.is_verified_realtor ? 'Подтвержден' : 'Не подтвержден'}
          </Typography.Text>
        </Flex>

        <Typography.Text
          style={{
            backgroundColor: user?.is_block ? '#FFD6D6' : '#DEF3E5',
            color: user?.is_block ? '#CE5A67' : '#58C27D',
          }}
          className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
        >
          • {user?.is_block ? 'Заблокирован' : 'Активен'}
        </Typography.Text>
      </Flex>

      <Flex className="items-center" gap={12}>
        {!user?.is_verified_realtor && (
          <Button
            onClick={() => {
              setIsVerifying(true)
            }}
            className="!border-[#58C27D] !bg-[#58C27D] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center"
          >
            <DoneIcon />
          </Button>
        )}

        <Popover
          content={
            <Flex vertical className="min-w-[170px]">
              {user?.is_block ? (
                <>
                  <Button
                    loading={loaderUnBlocking}
                    type="link"
                    onClick={() => {
                      mutateUnBlockUser({
                        user: user?.id,
                      })
                      setIsPopoverVisible(false)
                    }}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    <UnBlockUserIcon />
                    Разблокировать
                  </Button>

                  <Button
                    type="link"
                    onClick={() => {
                      setReasonModal(true)
                      setIsPopoverVisible(false)
                    }}
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    <ReasonBlockIcon />
                    Причина блокировки
                  </Button>
                </>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setBlockModal(true)
                    setIsPopoverVisible(false)
                  }}
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  <BlockUserIcon />
                  Заблокировать
                </Button>
              )}
            </Flex>
          }
          open={isPopoverVisible}
          onVisibleChange={(visible) => {
            setIsPopoverVisible(visible)
          }}
          placement="bottomRight"
          trigger="click"
        >
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
            <MoreOutlined />
          </Button>
        </Popover>
      </Flex>

      <ReasonModal
        reasonModal={reasonModal}
        setReasonModal={setReasonModal}
        user={user}
      />
      <BlockModal
        blockModal={blockModal}
        setBlockModal={setBlockModal}
        user={user}
        refetch={refetch}
      />

      <ConfirmVerifyModal
        user={user}
        confirmVerifyModal={isVerifying}
        setConfirmVerifyModal={setIsVerifying}
        refetch={refetch}
      />
    </Flex>
  )
}
