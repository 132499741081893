import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91699 8.33341C2.91699 5.19072 2.91699 3.61937 3.95432 2.64306C4.99165 1.66675 6.66121 1.66675 10.0003 1.66675H10.6443C13.3619 1.66675 14.7208 1.66675 15.6644 2.33161C15.9348 2.5221 16.1748 2.74802 16.3772 3.00249C17.0837 3.89064 17.0837 5.16955 17.0837 7.72735V9.84857C17.0837 12.3179 17.0837 13.5525 16.6929 14.5386C16.0646 16.1239 14.736 17.3743 13.0517 17.9656C12.004 18.3334 10.6921 18.3334 8.06851 18.3334C6.56928 18.3334 5.81967 18.3334 5.22097 18.1232C4.25849 17.7854 3.49928 17.0708 3.1403 16.165C2.91699 15.6015 2.91699 14.896 2.91699 13.4849V8.33341Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M17.0833 10C17.0833 11.5341 15.8397 12.7778 14.3056 12.7778C13.7507 12.7778 13.0966 12.6806 12.5572 12.8251C12.0779 12.9535 11.7035 13.3279 11.5751 13.8072C11.4306 14.3466 11.5278 15.0007 11.5278 15.5556C11.5278 17.0897 10.2841 18.3333 8.75 18.3333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66699 5.8335H12.5003"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66699 9.16675H9.16699"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function AppliesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
