import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.61288 5.66472L6.112 1.17326C6.38567 0.821986 6.8986 1.04062 6.8986 1.50853V4.98497C6.8986 5.26526 7.09976 5.49249 7.3479 5.49249H9.04982C9.43644 5.49249 9.64253 6.00743 9.38712 6.33528L5.888 10.8267C5.61434 11.178 5.1014 10.9594 5.1014 10.4915V7.01503C5.1014 6.73474 4.90024 6.50752 4.6521 6.50752H2.95018C2.56356 6.50752 2.35747 5.99257 2.61288 5.66472Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 1.00373C6.36416 0.985139 6.21959 1.03517 6.112 1.17332L2.61288 5.66646C2.35747 5.99443 2.56356 6.50957 2.95018 6.50957H4.6521C4.90024 6.50957 5.1014 6.73688 5.1014 7.01727V10.495C5.1014 10.7791 5.2903 10.9713 5.5 11"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function FlashIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
