import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.0874V3.42074M4 2.0874V3.42074"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99571 9.4209H8.00169M7.99571 12.0876H8.00169M10.6594 9.4209H10.6654M5.33203 9.4209H5.33801M5.33203 12.0876H5.33801"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33203 6.0874H13.6654"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66602 8.91607C1.66602 6.0112 1.66602 4.55876 2.50076 3.65633C3.33551 2.75391 4.67901 2.75391 7.36602 2.75391H8.63268C11.3197 2.75391 12.6632 2.75391 13.4979 3.65633C14.3327 4.55876 14.3327 6.0112 14.3327 8.91607V9.25841C14.3327 12.1633 14.3327 13.6157 13.4979 14.5181C12.6632 15.4206 11.3197 15.4206 8.63268 15.4206H7.36602C4.67901 15.4206 3.33551 15.4206 2.50076 14.5181C1.66602 13.6157 1.66602 12.1633 1.66602 9.25841V8.91607Z"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 6.0874H14"
      stroke="#A9A9A9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function CalendarIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
