import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import UrboIcon from '@/components/icons/urbo-icon'
import filterObject from '@/helpers/removeKeysWithoutValues'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import getListGlobalUsers from '../api'
import CreateDrawer from '../components/CreateDrawer'
import FilterDrawer from '../components/FilterDrawer'
import Table from '../components/Table'

function EmployeesPage(): JSX.Element {
  const [drawerFilter, setDrawerFilter] = useState(false)
  const [drawerCreate, setDrawerCreate] = useState(false)
  const { control, reset, watch, setValue } = useForm({
    defaultValues: {
      is_active: 'true',
    },
  })

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['employees', watch()],
    queryFn: async () =>
      await getListGlobalUsers({
        filters: {
          ...watch(),
        },
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <UrboIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Urbo
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Сотрудники
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Сотрудники
          </Typography.Title>

          <Flex gap={16}>
            <Button
              onClick={() => {
                setDrawerCreate(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить сотрудника
            </Button>

            <Button
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px]"
              onClick={() => {
                setDrawerFilter(true)
              }}
            >
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              {Object.keys(filterObject(watch())).length !== 0 && (
                <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                  {Object.keys(filterObject(watch())).length}
                </Typography.Text>
              )}
            </Button>
          </Flex>
        </Flex>

        <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4] w-max mt-[26px] ml-[26px]">
          <Button
            style={{
              backgroundColor:
                watch('is_active') === 'true' ? '#FFF' : '#F1F2F4',
            }}
            onClick={() => {
              setValue('is_active', 'true')
            }}
            className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
          >
            Активные
          </Button>

          <Button
            onClick={() => {
              setValue('is_active', 'false')
            }}
            style={{
              backgroundColor:
                watch('is_active') === 'false' ? '#FFF' : '#F1F2F4',
            }}
            className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
          >
            Архивированные
          </Button>
        </Flex>

        <Flex className="w-full h-full p-[24px]">
          {isLoading ? (
            <Flex className="w-full">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} refetch={refetch} />
          )}
        </Flex>
      </Flex>

      <FilterDrawer
        control={control}
        drawerFilter={drawerFilter}
        setDrawerFilter={setDrawerFilter}
        reset={reset}
      />

      <CreateDrawer
        drawerCreate={drawerCreate}
        setDrawerCreate={setDrawerCreate}
        refetch={refetch}
      />
    </Flex>
  )
}

export default EmployeesPage
