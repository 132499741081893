import BlurImage from '@/components/BlurImage'
import HFInput from '@/components/FormElements/HFInput'
import HFInputMask from '@/components/FormElements/HFInputMask'
import HFPassword from '@/components/FormElements/HFPassword'
import FRow from '@/components/FormElements/HFRow'
import GalleryIcon from '@/components/icons/gallery-icon'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { updateMe } from '../api'
import useMessage from '@/hooks/useShowMessage'

export default function FormDrawer({
  openDrawer,
  setOpenDrawer,
  refetch,
  data,
}: any): JSX.Element {
  const { control, reset, setValue, handleSubmit, watch } = useForm()
  const showMessage = useMessage()

  useEffect(() => {
    reset(data)
  }, [data])

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('avatar', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'avatar',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  const { mutate: mutateUpdate, isLoading } = useMutation(updateMe, {
    onSuccess: () => {
      refetch()
      setOpenDrawer(false)
      reset({})
      showMessage('success', 'Данные успешно изменены')
    },
  })

  const onSubmit = async () => {
    let imageFile = watchingImage
    if (isValidURL(watchingImage)) {
      imageFile = await urlToFile(watchingImage)
    }

    mutateUpdate({ ...watch(), avatar: imageFile })
  }

  return (
    <Drawer
      onClose={() => {
        setOpenDrawer(false)
      }}
      open={openDrawer}
      headerStyle={{
        display: 'none',
      }}
      width={475}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-[1px] border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Изменить данные
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setOpenDrawer(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <input type="file" hidden />
            <FRow label="Изображение профиля">
              <Flex>
                {imageUrlGenerator ? (
                  <Flex className="w-[100px] min-w-[100px] h-[100px] rounded-full overflow-hidden border-dashed border-[#E5E7EB] border-[1.5px] items-center justify-center">
                    <BlurImage src={imageUrlGenerator} />
                  </Flex>
                ) : (
                  <Flex className="w-[100px] min-w-[100px] h-[100px] rounded-full overflow-hidden border-dashed border-[#E5E7EB] border-[1.5px] items-center justify-center">
                    <GalleryIcon />
                  </Flex>
                )}

                <Flex gap={16} className="p-[24px] w-full">
                  <Button
                    onClick={inputClick}
                    className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
                  >
                    {imageUrlGenerator ? 'Изменить' : 'Выбрать'}
                  </Button>

                  {imageUrlGenerator && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('avatar', null)
                      }}
                      className="border-[#E5E7EB] text-[#CE5A67] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
                    >
                      Удалить
                    </Button>
                  )}
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Имя">
              <HFInput
                control={control}
                name="first_name"
                placeholder="Ваше имя"
              />
            </FRow>

            <FRow label="Фамилия">
              <HFInput
                control={control}
                name="last_name"
                placeholder="Ваша фамилия"
              />
            </FRow>

            <FRow label="Логин">
              <HFInput
                control={control}
                name="username"
                placeholder="Напишите логин"
              />
            </FRow>

            <FRow label="Пароль">
              <HFPassword
                control={control}
                name="password"
                placeholder="Напишите пароль"
              />
            </FRow>

            <FRow label="Номер телефона">
              <HFInputMask control={control} name="phone" prefix="+998" />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center justify-center gap-[10px]"
            onClick={() => {
              setOpenDrawer(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            loading={isLoading}
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center justify-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
