import BlurImage from '@/components/BlurImage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import { format } from 'date-fns'
import { useState } from 'react'
import { deleteBanner } from '../api'
import CreateDrawer from './CreateDrawer'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [drawerCreate, setDrawerCreate] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    deleteBanner,
    {
      onSuccess: () => {
        refetch()
        setIsPopoverVisible(false)
        showMessage('success', 'Баннер успешно удален')
      },
    },
  )
  return (
    <Flex className="w-full items-center grid grid-cols-[2fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
      <Flex className="h-[80x] w-auto overflow-hidden rounded-[12px] mr-[100px]">
        <BlurImage
          src={item?.image}
          className="h-full w-full object-cover"
          height="80px"
          width="100%"
        />
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.type_name}
      </Typography.Text>

      <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
        До {format(new Date(item?.to_date), 'dd.MM.yyyy')}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setDrawerCreate(true)
                setIsPopoverVisible(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Редактировать
            </Button>
            <Button
              type="link"
              onClick={() => {
                mutateDelete({ id: item.id })
              }}
              loading={isLoadingDelete}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        open={isPopoverVisible}
        onVisibleChange={(visible) => {
          setIsPopoverVisible(visible)
        }}
        placement="bottomRight"
        trigger="click"
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <CreateDrawer
        drawerCreate={drawerCreate}
        setDrawerCreate={setDrawerCreate}
        refetch={refetch}
        item={item?.id}
      />
    </Flex>
  )
}
