import { Empty, Flex, Pagination, Typography } from 'antd'
import TableRow from './TableRow'

function Table({ data, refetch }: any): JSX.Element {
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Дата регистрации
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Логин
          </Typography.Text>

          {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Пароль
          </Typography.Text> */}

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Роль
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Номер телефона
          </Typography.Text>

          <div />
        </Flex>

        {data?.results?.length > 0 ? (
          data?.results?.map((item: any) => (
            <TableRow key={item.id} item={item} refetch={refetch} />
          ))
        ) : (
          <Flex className="w-full justify-center items-center h-[200px] border border-solid border-[#E5E7EB] border-x-0 border-t-0">
            <Empty description="Нет данных" />
          </Flex>
        )}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            total={data?.count}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
