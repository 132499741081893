import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.334 8.33325L15.1251 8.8706C16.2695 9.2139 16.8416 9.38555 17.1711 9.82842C17.5007 10.2713 17.5007 10.8686 17.5007 12.0634L17.5007 18.3333"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M6.66699 7.5L9.16699 7.5M6.66699 10.8333L9.16699 10.8333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0007 18.3332V15.8332C10.0007 15.0475 10.0007 14.6547 9.75657 14.4106C9.5125 14.1665 9.11966 14.1665 8.33398 14.1665H7.50065C6.71498 14.1665 6.32214 14.1665 6.07806 14.4106C5.83398 14.6547 5.83398 15.0475 5.83398 15.8332L5.83398 18.3332"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M1.66699 18.3333H18.3337"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M2.5 18.3332L2.5 5.59754C2.5 3.50525 2.5 2.4591 3.15932 1.94004C3.81864 1.42098 4.78951 1.70279 6.73127 2.26642L10.8979 3.47585C12.0697 3.81598 12.6556 3.98604 12.9945 4.44955C13.3333 4.91306 13.3333 5.54437 13.3333 6.80698L13.3333 18.3332"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function ResidentialIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
