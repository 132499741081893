/* eslint-disable @typescript-eslint/no-floating-promises */
import axios from 'axios'
import type { AxiosError } from 'axios'
import settings from '@/config/settings'
import { refreshToken } from '@/features/auth/api'
import { message } from 'antd'

const requestV3 = axios.create({
  baseURL: settings.baseURLV3,
  timeout: settings.requestTimeout,
})

function logErrors(errorObj: unknown): void {
  if (Array.isArray(errorObj)) {
    errorObj.forEach((item) => {
      logErrors(item)
    })
  } else if (typeof errorObj === 'object' && errorObj !== null) {
    Object.values(errorObj).forEach((value) => {
      logErrors(value)
    })
  } else if (typeof errorObj === 'string') {
    message.error(errorObj)
  }
}

requestV3.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')

  if (token !== null) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Accept-Language'] = 'ru'
  }

  // config.validateStatus = (status) => status < 500;

  return config
}, errorHandler)

requestV3.interceptors.response.use((response) => response.data, errorHandler)

export async function errorHandler(error: AxiosError): Promise<void> {
  if (error.response !== null) {
    // server responded with a status code that falls out of the range of 2xx
    if (error.response?.status === 403) {
     window.location.href = '/no-permission'
    }
    if (error.response?.status === 401) {
      const rToken = localStorage.getItem('refresh_token')

      if (rToken !== null) {
        try {
          const res = await refreshToken({ refresh: rToken })
          const { refresh, access } = res.data
          localStorage.setItem("refresh_token", refresh);
          localStorage.setItem("access_token", access);
        } catch (err) {
          localStorage.setItem("refresh_token_error", JSON.stringify(err));
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
        } finally {
          window.location.reload();
        }
      }
    } else {
      logErrors(error?.response?.data)
    }

    await Promise.reject(error.response)
  }
  if (error.request !== null) {
    await Promise.reject(error.request)
  }

  await Promise.reject(error)
}

export default requestV3
