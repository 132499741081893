import AnalyticsIcon from '@/components/icons/analytics-icon'
import AppliesIcon from '@/components/icons/applies'
import BuilderIcon from '@/components/icons/builder'
import ContactsIcon from '@/components/icons/contacts-icon'
import CouponIcon from '@/components/icons/coupon'
import EmployeesIcon from '@/components/icons/employees'
import FaqIcon from '@/components/icons/faq-icon'
import FlashIcon2 from '@/components/icons/flash-icon2'
import InfoIcon from '@/components/icons/info-icon'
import LoyaltyCardIcon from '@/components/icons/loyalty-card-icon'
import MenuIcon from '@/components/icons/menu-icon'
import NewsIcon from '@/components/icons/news-icon'
import NoteIcon from '@/components/icons/note-icon'
import NotesIcon from '@/components/icons/notes-icon'
import PaperIcon from '@/components/icons/paper-icon'
import PieChartIcon from '@/components/icons/pie-chart-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import RepliesIcon from '@/components/icons/replies'
import ResidentialIcon from '@/components/icons/residential'
import RocketIcon from '@/components/icons/rocket'
import RolesIcon from '@/components/icons/roles-user'
import SeoIcon from '@/components/icons/seo-icon'
import ServicesIcon from '@/components/icons/services'
import UrboIcon from '@/components/icons/urbo-icon'
import UserGroupIcon from '@/components/icons/user-group-icon'
import VacansiesIcon from '@/components/icons/vacansies'
import { Flex } from 'antd'
import MenuButton from '../components/MenuButton'
import MenuButtonWithChildren from '../components/MenuButtonWithChildren'

function SecondHeader(): JSX.Element {
  const sidebarElements = [
    {
      id: 'main',
      key: [
        'analytics',
        'builders',
        'residential-complex',
        'banks',
        'users',
        'ads',
      ],
      name: 'Главная',
      icon: <MenuIcon />,
      children: [
        {
          id: 'analytics',
          name: 'Аналитика',
          link: '/analytics',
          icon: <AnalyticsIcon />,
        },
        {
          id: 'builders',
          name: 'Застройщики',
          link: '/builders',
          icon: <BuilderIcon />,
        },
        {
          id: 'residential-complex',
          name: 'Жилой комплексы',
          link: '/residential-complex',
          icon: <ResidentialIcon />,
        },
        // {
        //   id: 'banks',
        //   name: 'Банки',
        //   link: '/banks',
        //   icon: <BankIcon />,
        // },
        {
          id: 'users',
          name: 'Пользователи',
          link: '/users',
          icon: <UserGroupIcon />,
        },
        {
          id: 'ads',
          name: 'Объявления',
          link: '/ads',
          icon: <CouponIcon />,
        },
      ],
    },
    {
      id: 'residential-complex',
      key: ['roles', 'employees', 'vacansies', 'applies'],
      name: 'Urbo',
      icon: <UrboIcon />,
      children: [
        {
          id: 'roles',
          name: 'Роли',
          link: '/roles',
          icon: <RolesIcon />,
        },
        {
          id: 'employees',
          name: 'Сотрудники',
          link: '/employees',
          icon: <EmployeesIcon />,
        },
        {
          id: 'vacansies',
          name: 'Ваканция',
          link: '/vacansies',
          icon: <VacansiesIcon />,
        },
        {
          id: 'applies',
          name: 'Заявки',
          link: '/applies',
          icon: <AppliesIcon />,
        },
      ],
    },
    // {
    //   id: 'integrations',
    //   name: 'Интеграции',
    //   icon: <IntegrationIcon />,
    //   children: [
    //     {
    //       id: 'payment-systems',
    //       name: 'Платежные системы',
    //       link: '/payment-systems',
    //       icon: <CashIcon />,
    //     },
    //   ],
    // },
    {
      id: 'content',
      key: ['faq', 'services', 'news', 'instructions', 'contacts'],
      name: 'Контент',
      icon: <NoteIcon />,
      children: [
        {
          id: 'faq',
          name: 'Вопросы ответы',
          link: '/faq',
          icon: <FaqIcon />,
        },
        {
          id: 'services',
          name: 'Услуги',
          link: '/services',
          icon: <ServicesIcon />,
        },
        {
          id: 'news',
          name: 'Новости',
          link: '/news',
          icon: <NewsIcon />,
        },
        {
          id: 'instructions',
          name: 'Инструкции',
          link: '/instructions',
          icon: <InfoIcon />,
        },
        {
          id: 'contacts',
          name: 'Контакты',
          link: '/contacts',
          icon: <ContactsIcon />,
        },
      ],
    },
    {
      id: 'ads',
      key: ['advantages', 'special-offers', 'banners'],
      name: 'Рекламы',
      icon: (
        <PromotionIcon
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
      children: [
        {
          id: 'advantages',
          name: 'Преимущества',
          link: '/advantages',
          icon: <FlashIcon2 />,
        },
        {
          id: 'special-offers',
          name: 'Спец предложения',
          link: '/special-offers',
          icon: <RocketIcon />,
        },
        {
          id: 'banners',
          name: 'Баннеры',
          link: '/banners',
          icon: <LoyaltyCardIcon />,
        },
      ],
    },

    {
      id: 'reports',
      key: ['sale-ads', 'residential-complex-statistic'],
      name: 'Отчеты',
      icon: <RepliesIcon />,
      children: [
        // {
        //   id: 'sale-ads',
        //   name: 'Продажа объявлений',
        //   link: '/sale-ads',
        //   icon: <DiscountIcon />,
        // },
        {
          id: 'residential-complex-statistic',
          name: 'Статистика по ЖК',
          link: '/residential-complex-statistic',
          icon: <PieChartIcon />,
        },
      ],
    },
    {
      id: 'seo',
      key: ['seo-template', 'seo-general-template'],
      name: 'SEO',
      icon: <SeoIcon />,
      children: [
        {
          id: 'seo-template',
          name: 'Шаблон для отдельной страницы',
          link: '/seo-template',
          icon: <NotesIcon />,
        },
        {
          id: 'seo-general-template',
          name: 'Общий шаблон',
          link: '/seo-general-template',
          icon: <PaperIcon />,
        },
      ],
    },
  ]

  return (
    <Flex className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full items-center justify-between sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE]">
      <Flex gap={10} className="items-center">
        {sidebarElements.map((element: any) =>
          element.children?.length > 0 ? (
            <MenuButtonWithChildren key={element.id} element={element} />
          ) : (
            <MenuButton key={element.id} element={element} />
          ),
        )}
      </Flex>
    </Flex>
  )
}

export default SecondHeader
