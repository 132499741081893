import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.334 14.1667L7.50065 14.1667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.334 10.8334L10.834 10.8334"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.084 11.6667C17.084 14.8094 17.084 16.3808 16.0467 17.3571C15.0093 18.3334 13.3398 18.3334 10.0007 18.3334L9.35671 18.3334C6.63904 18.3334 5.2802 18.3334 4.33654 17.6685C4.06617 17.478 3.82613 17.2521 3.62373 16.9976C2.91732 16.1095 2.91732 14.8306 2.91732 12.2728V10.1516C2.91732 7.68225 2.91732 6.44759 3.3081 5.4615C3.93633 3.87623 5.26493 2.62578 6.94928 2.0345C7.99701 1.66671 9.30883 1.66671 11.9325 1.66671C13.4317 1.66671 14.1813 1.66671 14.78 1.87687C15.7425 2.21475 16.5017 2.92929 16.8607 3.83516C17.084 4.39864 17.084 5.10416 17.084 6.51519L17.084 11.6667Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M2.91667 10C2.91667 8.46588 4.16032 7.22222 5.69444 7.22222C6.24926 7.22222 6.90336 7.31944 7.4428 7.1749C7.9221 7.04647 8.29647 6.6721 8.4249 6.1928C8.56944 5.65336 8.47222 4.99926 8.47222 4.44444C8.47222 2.91032 9.71588 1.66667 11.25 1.66667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function NotesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
