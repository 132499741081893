import { authContext } from "@/contexts/auth-context";
import React, { useMemo, useState } from "react";

interface Props {
  children: React.ReactElement;
}

const accessToken = localStorage.getItem("access_token");

export default function AuthProvider(props: Props): React.ReactElement {
  const { children } = props;

  const [isAuth, setIsAuthState] = useState<boolean>(Boolean(accessToken));

  const setIsAuth = (value: boolean): any => {
    if (!value) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
    setIsAuthState(value);
  };

  const value = useMemo(() => ({ isAuth, setIsAuth }), [isAuth]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
