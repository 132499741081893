import { message } from 'antd'
import { useCallback } from 'react'

const useMessage = (): ((
  type: 'error' | 'success' | 'info' | 'warning' | 'loading',
  content: string,
) => Promise<void>) => {
  const showMessage = useCallback(
    async (
      type: 'error' | 'success' | 'info' | 'warning' | 'loading',
      content: string,
    ): Promise<void> => {
      switch (type) {
        case 'success':
          await message.success(content)
          break
        case 'error':
          await message.error(content)
          break
        case 'info':
          await message.info(content)
          break
        case 'warning':
          await message.warning(content)
          break
        case 'loading':
          await message.loading(content)
          break
        default:
          await message.info(content)
      }
    },
    [],
  )

  return showMessage
}

export default useMessage
