import { colors } from "@/config/theme";
import { ConfigProvider } from "antd";
import type { ThemeConfig } from "antd";

interface Props {
  children: React.ReactElement;
}

const theme: ThemeConfig = {
  token: {
    fontFamily: "Onest",
    colorLink: colors.primary.default,
    colorText: colors.secondary.default,
    colorPrimary: colors.primary.default,
    colorSuccess: colors.success.default,
    colorWarning: colors.warning.default,

    // colorPrimaryBg: colors.bg_primary,
    colorTextPlaceholder: colors.secondary.default,
    fontSize: 15,
    colorBorder: colors.border,
    fontWeightStrong: 700,
    borderRadius: 16,

    colorTextHeading: colors.primary.dark,
    fontSizeHeading1: 32,
    fontSizeHeading2: 12,
    fontSizeHeading3: 24,
    fontSizeHeading4: 12,
    fontSizeHeading5: 16,
    // borderRadiusLG: 20,
  },
  components: {
    Button: { fontSize: 16, fontSizeLG: 16 },
  },
};

export default function ThemeProvider(props: Props): React.ReactElement {
  const { children } = props;

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
}
