const colors = {
  primary: { default: "#3276FF", light: "#3276FF", dark: "#232E40" },
  secondary: { default: "#777E90", light: "#B7BFD5", dark: "#3276FF" },
  success: { default: "#4DD282", light: "#B7BFD5", dark: "#3276FF" },
  warning: { default: "#FF4E4E", light: "#B7BFD5", dark: "#3276FF" },
  bg_primary: "#3276FF",
  border: "",
};

const sizes = {};

export { colors, sizes };
