import HFInput from '@/components/FormElements/HFInput'
import RepliesIcon from '@/components/icons/replies'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import getListStatistics from '../api'
import Table from '../components/Table'

function RsStatistics(): JSX.Element {
  const [page, setPage] = useState(1)
  const { watch, control } = useForm()

  const { data, isLoading } = useQuery({
    queryKey: ['list-statistics', page, watch('filters.search')],
    queryFn: () =>
      getListStatistics({
        filters: {
          page,
          search: watch('filters.search'),
        },
      }),
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <RepliesIcon
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
                <Typography.Text className="text-sm font-normal text-center">
                  Отчеты
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Статистика по ЖК
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Статистика по ЖК ({data?.count ?? 0})
          </Typography.Title>

          <Flex>
            <HFInput
              placeholder="Поиск"
              size="large"
              name="filters.search"
              control={control}
              className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#A9A9A9] text-sm font-normal min-w-[400px]"
              // value={watch('filters.search')}
              // onChange={(e) => {
              //   setValue('filters.search', e.target.value)
              // }}
              prefix={
                <Flex className="mr-[10px]">
                  <SearchOutlined
                    style={{
                      color: '#A9A9A9',
                    }}
                  />
                </Flex>
              }
            />
          </Flex>
        </Flex>

        <Flex className="w-full h-full p-[0px_24px]">
          {isLoading ? (
            <Flex className="w-full ">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} setPage={setPage} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RsStatistics
