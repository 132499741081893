import MinusWithBorderIcon from '@/components/icons/minus-border'
import PlusWithBorderIcon from '@/components/icons/plus-border'
import { Checkbox, Flex, Typography } from 'antd'
import { useState } from 'react'

export default function CollapseItem({
  item,
  setValue,
  watch,
}: any): JSX.Element {
  const [open, setOpen] = useState(false)
  return (
    <Flex vertical className="h-max w-full">
      <Flex
        className="cursor-pointer items-center justify-between w-full mb-[16px]"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <Flex gap={12} className="items-center">
          {open ? <MinusWithBorderIcon /> : <PlusWithBorderIcon />}
          <Typography.Text className="text-[#1E1E1E] font-medium text-base">
            {item?.name}
          </Typography.Text>
        </Flex>

        <Flex>
          <Checkbox
            onClick={(e) => {
              e.stopPropagation()
            }}
            checked={
              watch('permissions').filter((id: string) =>
                item?.permissions.map((p: any) => p.id).includes(id),
              ).length === item?.permissions.length
            }
            onChange={(e) => {
              if (e.target.checked) {
                setValue(
                  'permissions',
                  Array.from(
                    new Set([
                      ...watch('permissions'),
                      ...item?.permissions.map((p: any) => p.id),
                    ]),
                  ),
                )
              } else {
                setValue(
                  'permissions',
                  watch('permissions').filter(
                    (id: string) =>
                      !item?.permissions.map((p: any) => p.id).includes(id),
                  ),
                )
              }
            }}
          />
        </Flex>
      </Flex>

      {open && (
        <Flex vertical className="pl-[36px]" gap={16}>
          {item?.permissions?.map((permission: any) => (
            <Flex
              key={permission?.id}
              className="w-full items-center justify-between"
            >
              <Typography.Text className="text-[#1E1E1E] text-base font-normal">
                {permission?.code}
              </Typography.Text>
              <Flex>
                <Checkbox
                  checked={watch('permissions').includes(permission?.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue('permissions', [
                        ...watch('permissions'),
                        permission?.id,
                      ])
                    } else {
                      setValue(
                        'permissions',
                        watch('permissions').filter(
                          (id: string) => id !== permission?.id,
                        ),
                      )
                    }
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  )
}
