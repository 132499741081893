/* eslint-disable @tanstack/query/no-deprecated-options */
import BlurImage from '@/components/BlurImage'
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import NoteIcon from '@/components/icons/note-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import CKEditorPage from '@/views/CKEditorPage'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Breadcrumb,
  Button,
  Flex,
  Image,
  Skeleton,
  Spin,
  Typography,
} from 'antd'
import { useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createNews,
  getNewsById,
  postCategoriesList,
  updateNewsWithPut,
} from '../api'
import GalleryImage from '../assets/Image.svg'
import AddCategory from '../components/AddCategory'

export default function NewsForm(): JSX.Element {
  const { control, watch, setValue, reset } = useForm({
    defaultValues: {},
  })
  const navigate = useNavigate()
  const { id } = useParams()
  const [open, setOpen] = useState(false)

  const { isLoading } = useQuery({
    queryKey: ['news-id-get', id],
    cacheTime: 0,
    queryFn: () => getNewsById({ id }),
    enabled: id !== 'create',
    onSuccess: (data) => {
      reset({
        ...data,
        title_uz: data?.translations?.uz?.title,
        title_ru: data?.translations?.ru?.title,
        description_uz: data?.translations?.uz?.description,
        description_ru: data?.translations?.ru?.description,
        category: data?.category_id,
      })
    },
  })

  const { mutate: mutateCreate, isLoading: loaderCreate } = useMutation({
    mutationFn: createNews,
    onSuccess: async () => {
      await navigate('/news')
    },
  })

  const { mutate: mutateUpdate, isLoading: loaderUpdate } = useMutation({
    mutationFn: updateNewsWithPut,
    onSuccess: async () => {
      await navigate('/news')
    },
  })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url).catch((err) => {
      console.error(err)
    })

    if (response) {
      const blob = await response.blob()
      const urlParts = url.split('/')
      const filename = urlParts[urlParts.length - 1]
      const mimeType =
        response.headers.get('content-type') ?? 'application/octet-stream'
      const file = new File([blob], filename, { type: mimeType })
      return file
    }
    return new File([], '')
  }

  const onSubmit = async (): Promise<void> => {
    let imageFile = watchingImage
    if (isValidURL(watchingImage as any)) {
      imageFile = await urlToFile(watchingImage as any)
    }

    if (id === 'create') {
      mutateCreate(watch())
    } else {
      mutateUpdate({
        ...watch(),
        image: imageFile,
      })
    }
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('image', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  const {
    data: categories,
    isLoading: loaderCategory,
    refetch,
  } = useQuery({
    queryKey: ['categories'],
    queryFn: postCategoriesList,
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <NoteIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Контент
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Новости
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex className="w-full justify-center">
        <Flex
          vertical
          className="border-[1px] border-[#E5E7EB] border-solid rounded-[16px] bg-[#FFF] w-[70%] relative"
        >
          {isLoading && id !== 'create' && (
            <Flex className="absolute w-full h-full bg-white z-50 items-center justify-center">
              <Spin size="large" />
            </Flex>
          )}

          <input type="file" hidden />

          <Flex className="border-[#E5E7EB] border-x-0 border-t-0 border-[1px] border-solid p-[24px] items-center justify-between">
            <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 flex gap-1">
              {id === 'create' ? 'Создать новость' : 'Редактировать новость'}
            </Typography.Title>

            {id === 'create' && (
              <Button
                onClick={() => {
                  mutateCreate({
                    ...watch(),
                    status: false,
                  })
                }}
                className="p-[12px] text-[#635BFF] border border-solid border-[#635BFF] h-auto rounded-[12px]"
              >
                Сохранить черновик
              </Button>
            )}
          </Flex>

          <Flex className="border-[#E5E7EB] border-x-0 border-t-0 border-[1px] border-solid p-[24px]">
            <Flex gap={12}>
              {loaderCategory ? (
                <Flex>
                  <Skeleton.Button active size="large" />
                </Flex>
              ) : (
                categories?.results?.map((item) => (
                  <Button
                    key={item?.id}
                    onClick={() => {
                      setValue('category', item?.id)
                    }}
                    style={{
                      backgroundColor:
                        watch('category') === item?.id ? '#E0DEFF' : '',
                      color: watch('category') === item?.id ? '#635BFF' : '',
                      borderColor:
                        watch('category') === item?.id ? '#635BFF' : '',
                    }}
                    className="p-[12px] text-[#A9A9A9] border border-solid border-[#A9A9A9] h-auto rounded-[12px]"
                  >
                    {item?.name}
                  </Button>
                ))
              )}

              <Button
                onClick={() => {
                  setOpen(true)
                }}
                className="p-[12px] text-[#A9A9A9] border border-solid border-[#A9A9A9] h-auto rounded-[12px] flex items-center"
              >
                <PlusAddIcon />
                Добавить категорию
              </Button>
            </Flex>
          </Flex>

          <Flex vertical className="p-[24px]" gap={16}>
            <FRow label="Добавить изображение">
              <Flex className="border-[#E5E7EB] border-[1px] border-dashed rounded-[12px]">
                {imageUrlGenerator ? (
                  <Flex className="items-center justify-center w-full h-full relative">
                    <BlurImage
                      src={imageUrlGenerator}
                      preview={false}
                      className="object-cover h-full w-full"
                    />

                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('image', null)
                      }}
                      className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
                    >
                      <CloseOutlined />
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    onClick={inputClick}
                    className="items-center justify-center min-h-[200px] w-full"
                  >
                    <Image width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px]">
                      Выберите или перетащите минимум 1 фото
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                      Максимальный размер: 5 МБ.
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            <FRow label="Заголовок UZ" required>
              <HFInput
                placeholder="Введите заголовок"
                required
                control={control}
                name="title_uz"
              />
            </FRow>

            <FRow label="Заголовок RU" required>
              <HFInput
                required
                placeholder="Введите заголовок"
                control={control}
                name="title_ru"
              />
            </FRow>

            {/* <FRow label="Подаголовок UZ">
              <CKEditorPage
                value={watch('short_desc_uz')}
                setValue={(e) => setValue('short_desc_uz', e)}
              />
            </FRow>

            <FRow label="Подаголовок RU">
              <CKEditorPage
                value={watch('short_desc_ru')}
                setValue={(e) => setValue('short_desc_ru', e)}
              />
            </FRow> */}

            <FRow label="Текст UZ">
              <CKEditorPage
                value={watch('description_uz')}
                setValue={(e) => setValue('description_uz', e)}
              />
            </FRow>

            <FRow label="Текст RU">
              <CKEditorPage
                value={watch('description_ru')}
                setValue={(e) => setValue('description_ru', e)}
              />
            </FRow>

            <Flex gap={8} className="justify-end">
              <Button
                onClick={onSubmit}
                loading={loaderCreate || loaderUpdate}
                className="p-[14px_24px] border-[1px] border-solid border-[#635BFF] bg-[#635BFF] text-[#FFF] rounded-[8px] h-auto text-base font-medium"
              >
                {id === 'create' ? 'Опубликовать' : 'Сохранить'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <AddCategory open={open} setOpen={setOpen} refetch={refetch} />
    </Flex>
  )
}
