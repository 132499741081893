import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useState } from 'react'
import { updateUser } from '../api'
import CreateDrawer from './CreateDrawer'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [drawerCreate, setDrawerCreate] = useState(false)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const showMessage = useMessage()

  const { mutate, isLoading } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      showMessage('success', 'Сотрудник успешно обновлен')
      refetch()
    },
  })

  return (
    <Flex
      key={item?.id}
      className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
    >
      <Flex className="items-center" gap={12}>
        <Typography.Text className="text-[#635BFF] font-normal text-base">
          {item?.first_name + ' ' + item?.last_name}
        </Typography.Text>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {format(new Date(item?.date_joined), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.email}
      </Typography.Text>

      {/* <Flex className="min-w-[220px] [w-220px] items-center" gap={10}>
        <Typography.Text className="text-[#1E1E1E] font-normal text-xs">
          {item?.first_password ?? 'Неизвестно'}
        </Typography.Text>
        <Button className="gap-[8px] flex items-center rounded-[32px] !bg-[#F1F2F4] !text-[#1E1E1E] text-xs font-normal">
          <RefreshIcon />
          Cбросить
        </Button>
      </Flex> */}

      <Typography.Text
        style={{
          color: item?.is_active ? '#58C27D' : '#CE5A67',
          backgroundColor: item?.is_active ? '#DEF3E5' : '#F3DCDF',
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
      >
        • {item?.is_active ? 'Активный' : 'Заблокирован'}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.global_role}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base">
        {item?.phone}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setDrawerCreate(true)
                setPopoverOpen(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            {item?.is_active ? (
              <Button
                type="link"
                loading={isLoading}
                onClick={() => {
                  mutate({
                    id: item.id,
                    is_active: false,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Архивировать
              </Button>
            ) : (
              <Button
                type="link"
                loading={isLoading}
                onClick={() => {
                  mutate({
                    id: item.id,
                    is_active: true,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Восстановить
              </Button>
            )}
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        visible={popoverOpen}
        onVisibleChange={setPopoverOpen}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
          <MoreOutlined />
        </Button>
      </Popover>

      <CreateDrawer
        drawerCreate={drawerCreate}
        setDrawerCreate={setDrawerCreate}
        id={item?.id}
        refetch={refetch}
      />
    </Flex>
  )
}
