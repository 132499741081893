import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 6.66663H15.4167M8.75 9.99996H10.8333M15.4167 9.99996H13.3333M8.75 13.3333H10.8333M15.4167 13.3333H13.3333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83366 6.25H5.00033C3.42898 6.25 2.6433 6.25 2.15515 6.73816C1.66699 7.22631 1.66699 8.01198 1.66699 9.58333V15C1.66699 16.1506 2.59973 17.0833 3.75033 17.0833C4.90092 17.0833 5.83366 16.1506 5.83366 15V6.25Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 2.91663H9.16667C8.39167 2.91663 8.0042 2.91663 7.68628 3.00181C6.82356 3.23298 6.14968 3.90685 5.91852 4.76958C5.83333 5.08749 5.83333 5.47498 5.83333 6.24996V15C5.83333 16.1505 4.90059 17.0833 3.75 17.0833H13.3333C15.6903 17.0833 16.8688 17.0833 17.6011 16.351C18.3333 15.6188 18.3333 14.4403 18.3333 12.0833V7.91663C18.3333 5.5596 18.3333 4.38109 17.6011 3.64886C16.8688 2.91663 15.6903 2.91663 13.3333 2.91663Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function NewsIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
