import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3307 3.3335V4.66683M9.9974 3.3335V4.66683M6.66406 3.3335V4.66683"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33203 12.6667V8C4.33203 6.11438 4.33203 5.17157 4.91782 4.58579C5.5036 4 6.44641 4 8.33203 4H11.6654C13.551 4 14.4938 4 15.0796 4.58579C15.6654 5.17157 15.6654 6.11438 15.6654 8V10C15.6654 13.1427 15.6654 14.714 14.6891 15.6904C13.7127 16.6667 12.1414 16.6667 8.9987 16.6667H8.33203C6.44641 16.6667 5.5036 16.6667 4.91782 16.0809C4.33203 15.4951 4.33203 14.5523 4.33203 12.6667Z"
      stroke="currentColor"
    />
    <path
      d="M7.33203 12.0003H9.9987M7.33203 8.66699H12.6654"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M15.6641 11.667C15.6641 12.5875 14.9179 13.3337 13.9974 13.3337C13.6645 13.3337 13.272 13.2753 12.9484 13.3621C12.6608 13.4391 12.4362 13.6637 12.3591 13.9513C12.2724 14.275 12.3307 14.6674 12.3307 15.0003C12.3307 15.9208 11.5845 16.667 10.6641 16.667"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function RepliesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}
