import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Flex, Typography } from 'antd'

function FilterDrawer({
  setDrawerFilter,
  drawerFilter,
  control,
  reset,
}: any): JSX.Element {
  return (
    <Drawer
      onClose={() => {
        setDrawerFilter(false)
      }}
      open={drawerFilter}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
              Фильтр
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setDrawerFilter(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Тип ипотеки">
              <HFSelect
                control={control}
                name="type"
                options={[{ label: 'Uy-joy ipotekasi', value: '1' }]}
              />
            </FRow>

            <FRow label="Сумма кредита">
              <HFInput
                control={control}
                name="creditAmount"
                suffix="UZS"
                prefix="До"
              />
            </FRow>

            <FRow label="Ставка кредита">
              <HFInput
                control={control}
                name="creditAmount"
                suffix="%"
                prefix="До"
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex gap={16} className="p-[24px] w-full">
          <Button
            className="border-[#E5E7EB] rounded-[8px] w-full h-[48px] flex items-center gap-[10px]"
            onClick={() => {
              setDrawerFilter(false)
              reset({})
            }}
          >
            Отменить
          </Button>

          <Button
            onClick={() => {
              setDrawerFilter(false)
            }}
            className="border-none bg-[#635BFF] h-[48px] w-full rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default FilterDrawer
